import React from 'react';

/**
 * @returns {JSX.Element} VoucherCancelIcon svg
 */
export default function VoucherCancelIcon() {
  return (
    <svg
      width='200'
      height='201'
      viewBox='0 0 200 201'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M60.899 67.3212C56.1735 75.8817 59.2917 86.6716 67.8452 91.4058C76.3987 96.14 87.1759 93.0232 91.9013 84.4628C96.6268 75.9024 93.5086 65.1125 84.9551 60.3783C76.3936 55.6043 65.6244 58.7608 60.899 67.3212ZM81.6943 78.6959C82.3603 77.4866 83.8454 77.0641 85.0537 77.7313C86.262 78.3986 86.6847 79.8855 86.0187 81.0947C85.3527 82.304 83.8676 82.7265 82.6593 82.0593C81.451 81.392 81.0203 79.8654 81.6943 78.6959ZM78.9835 77.131C78.4998 78.0141 77.4038 78.317 76.5215 77.8325L68.8264 73.4635C67.9837 72.971 67.6409 71.8817 68.1325 71.0384C68.6162 70.1553 69.7122 69.8523 70.5945 70.3369L78.2896 74.7058C79.1243 75.1586 79.4671 76.2479 78.9835 77.131Z'
        fill='#A1A3A5'
      />
      <path
        d='M68.126 70.999C67.6423 71.8821 67.9454 72.9794 68.8278 73.4639L76.5229 77.8329C77.4052 78.3174 78.5012 78.0145 78.9849 77.1314C79.4685 76.2483 79.1734 75.1907 78.291 74.7062L70.5959 70.3373C69.7056 69.813 68.6096 70.1159 68.126 70.999Z'
        fill='#A1A3A5'
      />
      <path
        d='M85.8906 116.445L88.7135 118.774C88.8566 118.869 89.7857 118.558 89.9606 118.606L91.755 117.666L95.1552 96.1355L85.189 114.187C84.8165 115.007 85.1036 116.024 85.8906 116.445Z'
        fill='#A1A3A5'
      />
      <path
        d='M107.218 83.7847L109.816 69.5712L98.3755 90.3596L107.218 83.7847Z'
        fill='#A1A3A5'
      />
      <path
        d='M82.6529 82.0195C83.8612 82.6867 85.3383 82.2244 86.0123 81.0549C86.6783 79.8456 86.2159 78.3667 85.0473 77.6915C83.839 77.0243 82.3618 77.4865 81.6879 78.656C81.0219 79.8653 81.4526 81.392 82.6529 82.0195Z'
        fill='#A1A3A5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.206 36.7877L53.5116 23.5407C54.9625 20.8914 58.6399 20.0698 61.7084 21.7697L119.903 52.3234C120.46 52.6253 120.764 54.7564 120.312 55.1782L110.844 64.6087L109.944 69.5878L98.4716 90.424L95.9235 92.3423L95.2922 96.1919L85.326 114.243C84.9058 115.031 85.1929 116.049 85.9879 116.51L89.5651 118.479C89.7001 118.535 89.8749 118.583 90.0496 118.63L90.0498 118.63L80.792 123.551C80.3077 123.814 79.7278 123.806 79.2587 123.528L22.684 92.6078C19.6156 90.908 18.3246 87.3619 19.7755 84.7126L28.9636 68.0523C31.9496 69.1174 35.2541 69.411 38.5886 68.741C48.3827 66.7731 54.7272 57.2382 52.7593 47.444C51.8769 43.0521 49.4728 39.3538 46.206 36.7877ZM67.8865 91.3987C76.44 96.1329 87.2172 93.0161 91.9426 84.4557C96.66 75.8556 93.5418 65.0655 84.9963 60.3711C76.4428 55.6369 65.6656 58.7537 60.9402 67.3141C56.2148 75.8745 59.333 86.6645 67.8865 91.3987Z'
        fill='#A1A3A5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.427 36.33L53.7753 21.1925C55.2262 18.5433 58.9036 17.7217 61.972 19.4215L119.782 52.1398C120.339 52.4416 120.443 53.1652 119.99 53.587L110.523 63.0174L109.623 67.9965L98.1501 88.8327L95.602 90.751L94.9707 94.6006L85.0045 112.652C84.5843 113.44 84.8714 114.458 85.6664 114.918L89.2435 116.888C89.3786 116.944 89.5534 116.991 89.7281 117.039L89.7283 117.039L80.4705 121.96C79.9862 122.223 79.4062 122.215 78.9372 121.937L22.9477 90.2597C19.8792 88.5598 18.5883 85.0137 20.0392 82.3644L28.382 67.2369C31.5739 68.609 35.2009 69.0594 38.8648 68.3232C48.4669 66.394 54.6869 57.046 52.7577 47.4439C51.8129 42.7415 49.0888 38.8502 45.427 36.33Z'
        fill='#EDEDEE'
      />
      <path
        d='M58.6365 26.4311C68.1408 28.906 115.574 51.952 119.644 53.9057L119.985 53.5476C120.437 53.1258 120.333 52.4021 119.777 52.1003L62.1561 19.4779C59.0877 17.7781 55.4103 18.5997 53.9594 21.2489L47.6324 32.7371C47.9501 32.6732 54.8459 25.4554 58.6365 26.4311Z'
        fill='#F2F4F7'
      />
      <path
        d='M85.5724 114.856L89.1496 116.826C89.2927 116.921 89.4676 116.969 89.6344 116.976L91.4288 116.037L94.8291 94.5061L84.8628 112.558C84.4983 113.417 84.7855 114.435 85.5724 114.856Z'
        fill='#A1A3A5'
      />
      <path
        d='M106.9 82.1949L109.498 67.9814L98.0572 88.7698L106.9 82.1949Z'
        fill='#A1A3A5'
      />
      <path
        d='M97.6045 134.094L149.697 109.518C150.547 109.113 150.871 108.139 150.507 107.288L148.765 103.597C148.36 102.746 147.388 102.421 146.537 102.786L140.502 105.625L130.051 115.642L119.033 115.763L113.078 118.562L97.6855 134.012L95.6602 133.729C96.1867 134.256 96.9564 134.418 97.6045 134.094Z'
        fill='#A1A3A5'
      />
      <path
        d='M103.315 145.732L155.407 121.157C156.258 120.751 156.582 119.778 156.217 118.926L154.475 115.236C154.07 114.384 153.098 114.06 152.248 114.425L100.155 139C99.3047 139.406 98.9806 140.379 99.3452 141.231L101.087 144.921C101.452 145.773 102.464 146.138 103.315 145.732Z'
        fill='#A1A3A5'
      />
      <path
        d='M104.895 153.113L106.637 156.803C107.042 157.655 108.014 157.979 108.865 157.614L160.957 133.039C161.808 132.633 162.132 131.66 161.768 130.808L160.026 127.118C159.621 126.266 158.648 125.942 157.798 126.307L105.706 150.882C104.895 151.288 104.531 152.261 104.895 153.113Z'
        fill='#A1A3A5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M129.857 177.144L113.887 184.667C111.133 185.965 107.69 184.424 106.15 181.261L84.3984 132.187V130.16L95.6594 133.728C96.1455 134.215 96.9152 134.418 97.6038 134.093L149.696 109.518C150.547 109.112 150.911 108.139 150.506 107.287L148.764 103.597C148.359 102.745 147.387 102.38 146.536 102.786L140.501 105.625L144.187 102.137L158.648 99.9473L179.347 146.831C180.846 149.953 179.833 153.603 177.079 154.901L161.688 162.15C159.387 155.026 152.7 149.872 144.81 149.872C135.016 149.872 127.076 157.812 127.076 167.606C127.076 171.117 128.097 174.39 129.857 177.144Z'
        fill='#A1A3A5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M129.645 175.936L114.817 182.92C112.063 184.218 108.62 182.677 107.08 179.514L84.3984 130.16L95.6594 131.701C96.1455 132.188 96.9152 132.39 97.6038 132.066L149.696 107.491C150.547 107.085 150.911 106.112 150.506 105.26L148.764 101.57C148.359 100.718 147.387 100.353 146.536 100.759L140.501 103.597L144.187 100.11L158.648 97.9199L180.277 145.084C181.776 148.207 180.763 151.856 178.008 153.154L162.873 160.284C160.311 153.178 153.508 148.099 145.519 148.099C135.333 148.099 127.076 156.356 127.076 166.542C127.076 169.972 128.013 173.185 129.645 175.936Z'
        fill='#EDEDEE'
      />
      <path
        d='M115.833 113.776L113.078 116.534L119.033 113.735L115.833 113.776Z'
        fill='#EDEDEE'
      />
      <path
        d='M180.213 144.918L158.649 97.9199L155.246 98.4471C156.259 100.11 164.401 119.008 165.575 121.725C174.218 141.715 179.2 150.069 173.367 155.178L177.904 153.029C180.658 151.731 181.671 148.082 180.213 144.918Z'
        fill='#F2F4F7'
      />
      <path
        d='M97.6045 132.066L149.697 107.491C150.547 107.085 150.871 106.112 150.507 105.26L148.765 101.57C148.36 100.718 147.388 100.394 146.537 100.759L140.502 103.598L130.051 113.614L119.033 113.736L113.078 116.534L97.6855 131.985L95.6602 131.701C96.1867 132.228 96.9564 132.391 97.6045 132.066Z'
        fill='#A1A3A5'
      />
      <path
        d='M103.315 143.705L155.407 119.129C156.258 118.724 156.582 117.751 156.217 116.899L154.475 113.209C154.07 112.357 153.098 112.033 152.248 112.398L100.155 136.973C99.3047 137.379 98.9806 138.352 99.3452 139.203L101.087 142.894C101.452 143.745 102.464 144.11 103.315 143.705Z'
        fill='#A1A3A5'
      />
      <path
        d='M104.895 151.085L106.637 154.776C107.042 155.627 108.014 155.952 108.865 155.587L160.957 131.011C161.808 130.606 162.132 129.632 161.768 128.781L160.026 125.09C159.621 124.239 158.648 123.914 157.798 124.279L105.706 148.855C104.895 149.26 104.531 150.234 104.895 151.085Z'
        fill='#A1A3A5'
      />
      <path
        d='M82.2662 56.4517C73.7057 51.7263 62.9158 54.8445 58.1816 63.398C53.4474 71.9514 56.5642 82.7286 65.1246 87.4541C73.685 92.1795 84.4749 89.0613 89.2091 80.5078C93.9831 71.9463 90.8266 61.1771 82.2662 56.4517Z'
        fill='#FF7F00'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M82.1134 71.2962C83.0025 71.058 83.5302 70.1441 83.2919 69.255C83.0537 68.3659 82.1398 67.8382 81.2507 68.0765L74.8451 69.7928L73.1288 63.3875C72.8906 62.4984 71.9767 61.9707 71.0876 62.209C70.1985 62.4472 69.6709 63.3611 69.9091 64.2502L71.6254 70.6556L65.2211 72.3716C64.332 72.6098 63.8044 73.5237 64.0426 74.4128C64.2809 75.3019 65.1948 75.8296 66.0839 75.5913L72.4881 73.8753L74.2042 80.2798C74.4424 81.1689 75.3563 81.6965 76.2454 81.4583C77.1345 81.22 77.6622 80.3061 77.4239 79.417L75.7079 73.0126L82.1134 71.2962Z'
        fill='#F2F4F7'
      />
      <path
        d='M132.935 69.6959V68.4793L136.419 56.4755C136.5 56.1105 136.824 55.8672 137.229 55.8672H150.759C151.204 55.8672 151.569 56.2322 151.569 56.6783V63.0046C151.569 63.329 151.366 63.6535 151.042 63.7751L134.029 70.6692C133.421 70.9531 132.773 70.3853 132.935 69.6959Z'
        fill='#A1A3A5'
      />
      <path
        d='M132.935 68.4786L136.419 55.2582C136.5 54.8932 136.824 54.6499 137.229 54.6499H150.759C151.204 54.6499 151.569 55.0149 151.569 55.461V61.7873C151.569 62.1117 151.366 62.4362 151.042 62.5578L134.029 69.4519C133.421 69.6953 132.773 69.1275 132.935 68.4786Z'
        fill='#EDEDEE'
      />
      <path
        d='M152.136 76.9145L162.911 69.9799C163.195 69.7365 163.6 69.7365 163.924 69.9799L174.82 76.6306C175.185 76.9145 175.144 78.9422 174.861 79.3072L171.053 84.3358C170.85 84.6197 170.486 84.7008 170.202 84.6197L152.46 79.8749C151.812 79.6721 151.61 77.3606 152.136 76.9145Z'
        fill='#A1A3A5'
      />
      <path
        d='M152.176 76.7524L162.91 68.3578C163.194 68.1145 163.599 68.1145 163.923 68.3578L174.698 76.5496C175.062 76.8335 175.143 77.3607 174.86 77.6851L171.052 82.7137C170.85 82.9976 170.485 83.0787 170.201 82.9976L152.459 78.1717C151.811 78.0095 151.649 77.1579 152.176 76.7524Z'
        fill='#EDEDEE'
      />
      <path
        d='M126.778 85.1058L132.531 87.5795C132.814 87.7012 133.138 87.6606 133.381 87.4579L135.204 85.9168C135.528 85.633 135.569 83.9703 135.366 83.6458L132.976 81.1721C132.733 80.8071 132.287 80.6854 131.923 80.8882L126.697 82.7131C126.049 83.0375 126.13 84.8219 126.778 85.1058Z'
        fill='#A1A3A5'
      />
      <path
        d='M126.778 83.9295L132.53 86.4033C132.814 86.5249 133.138 86.4844 133.381 86.2816L135.204 84.7406C135.528 84.4567 135.568 84.0106 135.366 83.6456L132.976 79.9958C132.733 79.6308 132.287 79.5092 131.923 79.7119L126.738 82.429C126.09 82.7535 126.13 83.6456 126.778 83.9295Z'
        fill='#EDEDEE'
      />
    </svg>

  );
}
