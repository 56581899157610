import React from 'react';
/**
 * @returns {JSX.Element} PhoneCall Component
 */
export default function PhoneCall() {
  return (
    <svg
      width='23'
      height='24'
      viewBox='0 0 23 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Communications &#38; connectivity/phone-call'>
        <path
          id='Vector'
          d='M7.45152 5.5043C7.82183 6.53555 8.19136 7.5668 8.56011 8.59805L8.58824 8.67774C8.61355 8.74799 8.61584 8.82448 8.59477 8.89611C8.5737 8.96775 8.53037 9.03082 8.47105 9.07618C8.11131 9.34818 7.70953 9.55956 7.2816 9.70196C6.76923 9.86067 6.24113 9.96323 5.7066 10.0078C5.65607 10.013 5.60733 10.0293 5.5639 10.0556C5.52047 10.0819 5.48344 10.1176 5.4555 10.16C5.42757 10.2024 5.40942 10.2505 5.40238 10.3008C5.39534 10.3511 5.39958 10.4023 5.4148 10.4508C5.97229 12.2083 6.94366 13.8062 8.24722 15.1102C9.56265 16.4102 11.1698 17.3769 12.9347 17.9297C12.9829 17.9445 13.0338 17.9484 13.0838 17.9413C13.1337 17.9341 13.1814 17.916 13.2235 17.8882C13.2656 17.8604 13.3011 17.8237 13.3273 17.7806C13.3536 17.7376 13.37 17.6892 13.3753 17.6391C13.4215 17.1077 13.526 16.5831 13.6871 16.0746C13.8299 15.6486 14.0421 15.249 14.3152 14.8922C14.3608 14.8321 14.4244 14.7883 14.4968 14.7672C14.5692 14.7461 14.6464 14.7489 14.7171 14.775L14.7968 14.8031L17.907 15.9059C18.6464 16.1684 19.6097 16.4438 20.1253 16.6945C20.1324 16.6945 20.1253 16.7098 20.1253 16.718C20.1324 16.7918 20.1347 16.8656 20.1347 16.9406C20.1348 17.2647 20.0867 17.587 19.9918 17.8969C19.6402 19.0383 18.691 20.2641 17.5707 20.5922C14.7441 21.4348 11.7652 20.5711 9.13316 19.2504C6.95705 18.1603 5.18826 16.4006 4.08707 14.2301C2.75582 11.6121 1.88863 8.64844 2.73472 5.83712C3.06988 4.72383 4.30269 3.77813 5.44996 3.42891L6.33941 3.19454C6.42033 3.17318 6.50633 3.18332 6.58007 3.2229C6.6538 3.26249 6.70977 3.32857 6.73668 3.40782L7.45152 5.5043Z'
          stroke='#F5F6F7'
          strokeLinecap='round'
        />
      </g>
    </svg>
  );
}
