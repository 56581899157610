import React from 'react';
/**
 * @returns {JSX.Element} FallenRocket Component
 */
export default function FallenRocket() {
  return (
    <svg
      width='205'
      height='205'
      viewBox='0 0 205 205'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_17554_24819)'>
        <path
          d='M46.5333 65.5852C46.6965 65.391 46.6811 65.0953 46.5018 64.923C46.3224 64.7507 46.0461 64.7708 45.8829 64.965C45.7197 65.1592 45.735 65.4549 45.9144 65.6272C46.0937 65.7995 46.3701 65.7794 46.5333 65.5852Z'
          fill='#C9CCD4'
        />
        <path
          d='M46.5333 65.5852C46.6965 65.391 46.6811 65.0953 46.5018 64.923C46.3224 64.7507 46.0461 64.7708 45.8829 64.965C45.7197 65.1592 45.735 65.4549 45.9144 65.6272C46.0937 65.7995 46.3701 65.7794 46.5333 65.5852Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.4462 73.904C65.4462 73.904 65.4455 73.8962 65.4453 73.8943L39.7211 48.7739C39.7211 48.7739 46.3686 58.7885 46.4125 61.6732C46.4564 64.558 50.685 73.8081 54.8279 70.3389C55.4947 69.7812 56.3694 69.6328 57.3336 69.7439C57.3977 69.4705 57.5234 69.2078 57.7134 68.9829C58.3308 68.2438 59.382 68.1735 60.0647 68.8254C60.5864 69.3245 60.7405 70.1005 60.5073 70.7697C60.7998 70.9092 61.0877 71.0591 61.3721 71.2114C61.3985 71.1571 61.4325 71.104 61.4742 71.0558C61.7338 70.7441 62.177 70.7145 62.4649 70.9885C62.7167 71.2293 62.7646 71.6217 62.6 71.9244C63.1421 72.2596 63.6379 72.5923 64.0644 72.8917C64.1894 72.826 64.342 72.8438 64.4507 72.9475C64.5253 73.0196 64.5656 73.1194 64.5653 73.2199C64.6423 73.1952 64.7268 73.213 64.7913 73.2745C64.8476 73.3271 64.8746 73.4028 64.8702 73.4781C65.0005 73.5754 65.1132 73.6608 65.2102 73.7363C65.2386 73.7251 65.2707 73.7332 65.2947 73.7541C65.3146 73.7715 65.3225 73.7982 65.323 73.8237C65.3587 73.8314 65.3936 73.851 65.4237 73.879C65.4319 73.8879 65.4401 73.8968 65.4464 73.906L65.4462 73.904Z'
          fill='#C9CCD4'
        />
        <path
          d='M57.3339 69.7436C58.341 69.8597 59.445 70.258 60.5074 70.7675C60.7406 70.0983 60.5866 69.3223 60.0649 68.8232C59.3821 68.1713 58.3293 68.2437 57.7135 68.9808C57.5255 69.2074 57.3996 69.4681 57.3338 69.7417L57.3339 69.7436Z'
          fill='#C9CCD4'
        />
        <path
          d='M61.372 71.2106C61.796 71.4413 62.2082 71.6833 62.5981 71.9238C62.7645 71.6209 62.7148 71.2288 62.463 70.988C62.1751 70.714 61.7319 70.7435 61.4723 71.0552C61.4324 71.1032 61.3985 71.1564 61.3702 71.2109L61.372 71.2106Z'
          fill='#C9CCD4'
        />
        <path
          d='M64.5621 73.2496C64.6724 73.3295 64.7731 73.4045 64.8679 73.4763C64.8722 73.4009 64.8452 73.3252 64.7889 73.2726C64.7245 73.2111 64.64 73.1933 64.563 73.218C64.5614 73.1177 64.523 73.0177 64.4484 72.9456C64.3397 72.842 64.187 72.8241 64.0621 72.8898C64.2311 73.0082 64.3886 73.1219 64.5343 73.2272C64.5442 73.234 64.554 73.2407 64.5619 73.2477L64.5621 73.2496Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.2084 73.7341C65.2498 73.7647 65.2874 73.7939 65.3212 73.8215C65.3208 73.7959 65.311 73.7695 65.2929 73.7519C65.2687 73.7291 65.2368 73.7229 65.2084 73.7341Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.4468 73.9035C65.4405 73.8944 65.4323 73.8855 65.4242 73.8766C65.394 73.8486 65.3593 73.8309 65.3236 73.8232C65.3652 73.8559 65.4008 73.8833 65.4326 73.9091C65.4386 73.9144 65.4454 73.9077 65.4468 73.9035Z'
          fill='#C9CCD4'
        />
        <path
          d='M46.2146 56.8474C46.1245 55.5048 45.0339 54.5082 43.7787 54.6215C42.5235 54.7347 41.5789 55.9149 41.669 57.2575C41.759 58.6001 42.8496 59.5967 44.1048 59.4834C45.3601 59.3702 46.3046 58.19 46.2146 56.8474Z'
          fill='#C9CCD4'
        />
        <path
          d='M44.605 56.2819C44.2103 55.906 43.6005 55.947 43.2444 56.3742C42.8884 56.8015 42.919 57.4539 43.3136 57.8299C43.7083 58.2058 44.3181 58.1648 44.6742 57.7376C45.0303 57.3103 44.9996 56.6579 44.605 56.2819Z'
          fill='#C9CCD4'
        />
        <path
          d='M58.5178 69.6023C58.4277 68.2597 57.3372 67.2631 56.0819 67.3763C54.8267 67.4896 53.8821 68.6698 53.9722 70.0124C54.0622 71.355 55.1528 72.3516 56.4081 72.2383C57.6633 72.1251 58.6079 70.9449 58.5178 69.6023Z'
          fill='#C9CCD4'
        />
        <path
          d='M60.1829 71.3408C60.3276 71.1665 60.4356 70.9728 60.5075 70.7675C60.7407 70.0984 60.5867 69.3223 60.065 68.8232C59.3822 68.1713 58.3294 68.2438 57.7136 68.9808C57.5256 69.2074 57.3997 69.4682 57.3339 69.7417C57.1868 70.3635 57.3581 71.0449 57.8314 71.4965C58.5141 72.1484 59.5669 72.0759 60.1827 71.3389L60.1829 71.3408Z'
          fill='#C9CCD4'
        />
        <path
          d='M62.5136 72.048C62.5451 72.0089 62.5746 71.9681 62.5983 71.9239C62.7647 71.621 62.7149 71.2289 62.4631 70.9881C62.1752 70.7141 61.732 70.7437 61.4725 71.0554C61.4325 71.1033 61.3986 71.1565 61.3703 71.211C61.2256 71.5094 61.2793 71.8834 61.5209 72.1135C61.8088 72.3875 62.252 72.358 62.5116 72.0463L62.5136 72.048Z'
          fill='#C9CCD4'
        />
        <path
          d='M62.5136 72.048C62.5451 72.0089 62.5746 71.9681 62.5983 71.9239C62.7647 71.621 62.7149 71.2289 62.4631 70.9881C62.1752 70.7141 61.732 70.7437 61.4725 71.0554C61.4325 71.1033 61.3986 71.1565 61.3703 71.211C61.2256 71.5094 61.2793 71.8834 61.5209 72.1135C61.8088 72.3875 62.252 72.358 62.5116 72.0463L62.5136 72.048Z'
          fill='#C9CCD4'
        />
        <path
          d='M64.4743 73.4763C64.5293 73.4108 64.5589 73.3306 64.5646 73.2491C64.5637 73.2394 64.5663 73.2273 64.5654 73.2175C64.5638 73.1172 64.5254 73.0173 64.4508 72.9451C64.3421 72.8415 64.1895 72.8237 64.0645 72.8893C64.024 72.9098 63.9879 72.9395 63.9563 72.9767C63.8264 73.1316 63.8383 73.3685 63.9814 73.5057C64.1001 73.618 64.2723 73.6276 64.4008 73.5399C64.4286 73.5209 64.4542 73.4983 64.4758 73.4721L64.4743 73.4763Z'
          fill='#C9CCD4'
        />
        <path
          d='M64.4743 73.4763C64.5293 73.4108 64.5589 73.3306 64.5646 73.2491C64.5637 73.2394 64.5663 73.2273 64.5654 73.2175C64.5638 73.1172 64.5254 73.0173 64.4508 72.9451C64.3421 72.8415 64.1895 72.8237 64.0645 72.8893C64.024 72.9098 63.9879 72.9395 63.9563 72.9767C63.8264 73.1316 63.8383 73.3685 63.9814 73.5057C64.1001 73.618 64.2723 73.6276 64.4008 73.5399C64.4286 73.5209 64.4542 73.4983 64.4758 73.4721L64.4743 73.4763Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.4386 74.2084C65.5118 74.1211 65.5131 73.9909 65.4464 73.9041C65.4401 73.895 65.4319 73.8861 65.4237 73.8772C65.3936 73.8491 65.3589 73.8315 65.3232 73.8238C65.2649 73.8108 65.2053 73.8236 65.1552 73.8609C65.1413 73.8704 65.1277 73.8838 65.1162 73.8989C65.0347 73.997 65.0423 74.1439 65.131 74.2301C65.2198 74.3164 65.3589 74.3062 65.4406 74.2101L65.4386 74.2084Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.4386 74.2084C65.5118 74.1211 65.5131 73.9909 65.4464 73.9041C65.4401 73.895 65.4319 73.8861 65.4237 73.8772C65.3936 73.8491 65.3589 73.8315 65.3232 73.8238C65.2649 73.8108 65.2053 73.8236 65.1552 73.8609C65.1413 73.8704 65.1277 73.8838 65.1162 73.8989C65.0347 73.997 65.0423 74.1439 65.131 74.2301C65.2198 74.3164 65.3589 74.3062 65.4406 74.2101L65.4386 74.2084Z'
          fill='#C9CCD4'
        />
        <path
          d='M64.807 73.6367C64.8453 73.5909 64.8659 73.5333 64.8682 73.4759C64.8725 73.4006 64.8455 73.3249 64.7892 73.2723C64.7248 73.2108 64.6403 73.193 64.5632 73.2177C64.5541 73.2187 64.5435 73.2239 64.5348 73.2289C64.503 73.2443 64.4736 73.2655 64.4485 73.294C64.3902 73.3638 64.3731 73.4603 64.3971 73.5442C64.4084 73.5882 64.4321 73.6268 64.4663 73.6583C64.565 73.7533 64.7169 73.7416 64.8068 73.6347L64.807 73.6367Z'
          fill='#C9CCD4'
        />
        <path
          d='M64.807 73.6367C64.8453 73.5909 64.8659 73.5333 64.8682 73.4759C64.8725 73.4006 64.8455 73.3249 64.7892 73.2723C64.7248 73.2108 64.6403 73.193 64.5632 73.2177C64.5541 73.2187 64.5435 73.2239 64.5348 73.2289C64.503 73.2443 64.4736 73.2655 64.4485 73.294C64.3902 73.3638 64.3731 73.4603 64.3971 73.5442C64.4084 73.5882 64.4321 73.6268 64.4663 73.6583C64.565 73.7533 64.7169 73.7416 64.8068 73.6347L64.807 73.6367Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.2991 73.885C65.3141 73.8675 65.3213 73.845 65.3212 73.8233C65.3208 73.7978 65.311 73.7713 65.2929 73.7537C65.2687 73.7309 65.2369 73.7248 65.2084 73.7359C65.1941 73.7415 65.1801 73.749 65.1702 73.762C65.1469 73.7903 65.1411 73.8284 65.1552 73.8622C65.16 73.8755 65.1684 73.8863 65.1765 73.8952C65.2127 73.9285 65.2677 73.9261 65.3011 73.8867L65.2991 73.885Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.2991 73.885C65.3141 73.8675 65.3213 73.845 65.3212 73.8233C65.3208 73.7978 65.311 73.7713 65.2929 73.7537C65.2687 73.7309 65.2369 73.7248 65.2084 73.7359C65.1941 73.7415 65.1801 73.749 65.1702 73.762C65.1469 73.7903 65.1411 73.8284 65.1552 73.8622C65.16 73.8755 65.1684 73.8863 65.1765 73.8952C65.2127 73.9285 65.2677 73.9261 65.3011 73.8867L65.2991 73.885Z'
          fill='#C9CCD4'
        />
        <path
          d='M46.5558 60.7994C46.9619 60.3132 46.9271 59.5726 46.478 59.1439C46.0289 58.7152 45.3367 58.7617 44.9306 59.2479C44.5245 59.7342 44.5593 60.4748 45.0084 60.9035C45.4575 61.3322 46.1497 61.2857 46.5558 60.7994Z'
          fill='#C9CCD4'
        />
        <path
          d='M46.5558 60.7994C46.9619 60.3132 46.9271 59.5726 46.478 59.1439C46.0289 58.7152 45.3367 58.7617 44.9306 59.2479C44.5245 59.7342 44.5593 60.4748 45.0084 60.9035C45.4575 61.3322 46.1497 61.2857 46.5558 60.7994Z'
          fill='#C9CCD4'
        />
        <path
          d='M47.2718 64.1403C47.6778 63.654 47.643 62.9134 47.1939 62.4847C46.7448 62.056 46.0526 62.1025 45.6465 62.5888C45.2404 63.075 45.2753 63.8156 45.7244 64.2443C46.1735 64.673 46.8657 64.6265 47.2718 64.1403Z'
          fill='#C9CCD4'
        />
        <path
          d='M47.2718 64.1403C47.6778 63.654 47.643 62.9134 47.1939 62.4847C46.7448 62.056 46.0526 62.1025 45.6465 62.5888C45.2404 63.075 45.2753 63.8156 45.7244 64.2443C46.1735 64.673 46.8657 64.6265 47.2718 64.1403Z'
          fill='#C9CCD4'
        />
        <path
          d='M47.7236 61.8502C48.1297 61.364 48.0949 60.6234 47.6458 60.1947C47.1967 59.7659 46.5045 59.8125 46.0984 60.2987C45.6923 60.7849 45.7271 61.5255 46.1762 61.9543C46.6254 62.383 47.3176 62.3365 47.7236 61.8502Z'
          fill='#C9CCD4'
        />
        <path
          d='M47.7236 61.8502C48.1297 61.364 48.0949 60.6234 47.6458 60.1947C47.1967 59.7659 46.5045 59.8125 46.0984 60.2987C45.6923 60.7849 45.7271 61.5255 46.1762 61.9543C46.6254 62.383 47.3176 62.3365 47.7236 61.8502Z'
          fill='#C9CCD4'
        />
        <path
          d='M41.3252 51.8877C41.6498 51.4996 41.6226 50.9058 41.2622 50.5634C40.9037 50.2207 40.3488 50.2573 40.0244 50.6474C39.7 51.0375 39.727 51.6293 40.0874 51.9717C40.4459 52.3144 41.0008 52.2778 41.3252 51.8877Z'
          fill='#C9CCD4'
        />
        <path
          d='M41.3252 51.8877C41.6498 51.4996 41.6226 50.9058 41.2622 50.5634C40.9037 50.2207 40.3488 50.2573 40.0244 50.6474C39.7 51.0375 39.727 51.6293 40.0874 51.9717C40.4459 52.3144 41.0008 52.2778 41.3252 51.8877Z'
          fill='#C9CCD4'
        />
        <path
          d='M43.6667 55.4484C44.2675 54.7289 44.2156 53.6318 43.5511 52.9994C42.8867 52.3671 41.8611 52.4344 41.2621 53.1537C40.6631 53.873 40.7132 54.9704 41.3776 55.6027C42.0422 56.237 43.0677 56.1677 43.6667 55.4484Z'
          fill='#C9CCD4'
        />
        <path
          d='M42.6689 54.0549C42.544 53.9354 42.3499 53.948 42.2349 54.0835C42.1217 54.2187 42.131 54.4264 42.2561 54.5479C42.3811 54.6693 42.5751 54.6548 42.6901 54.5194C42.8033 54.3841 42.794 54.1764 42.6689 54.0549Z'
          fill='#C9CCD4'
        />
        <path
          d='M42.2298 52.6221C42.4578 52.3495 42.4373 51.9342 42.1857 51.6954C41.9339 51.4547 41.5458 51.4818 41.3196 51.7542C41.0915 52.0268 41.112 52.442 41.3636 52.6809C41.6154 52.9216 42.0035 52.8945 42.2298 52.6221Z'
          fill='#C9CCD4'
        />
        <path
          d='M42.2298 52.6221C42.4578 52.3495 42.4373 51.9342 42.1857 51.6954C41.9339 51.4547 41.5458 51.4818 41.3196 51.7542C41.0915 52.0268 41.112 52.442 41.3636 52.6809C41.6154 52.9216 42.0035 52.8945 42.2298 52.6221Z'
          fill='#C9CCD4'
        />
        <path
          d='M39.8946 49.8251C40.1226 49.5525 40.1021 49.1372 39.8505 48.8984C39.5989 48.6596 39.2106 48.6848 38.9844 48.9572C38.7582 49.2296 38.7768 49.645 39.0284 49.8838C39.2801 50.1227 39.6684 50.0975 39.8946 49.8251Z'
          fill='#C9CCD4'
        />
        <path
          d='M39.8946 49.8251C40.1226 49.5525 40.1021 49.1372 39.8505 48.8984C39.5989 48.6596 39.2106 48.6848 38.9844 48.9572C38.7582 49.2296 38.7768 49.645 39.0284 49.8838C39.2801 50.1227 39.6684 50.0975 39.8946 49.8251Z'
          fill='#C9CCD4'
        />
        <path
          d='M40.5443 50.7938C40.8605 50.4145 40.8328 49.8366 40.4843 49.5026C40.134 49.1689 39.5938 49.2057 39.2775 49.5831C38.9613 49.9624 38.989 50.5403 39.3375 50.8743C39.6878 51.208 40.228 51.1712 40.5443 50.7938Z'
          fill='#C9CCD4'
        />
        <path
          d='M40.5443 50.7938C40.8605 50.4145 40.8328 49.8366 40.4843 49.5026C40.134 49.1689 39.5938 49.2057 39.2775 49.5831C38.9613 49.9624 38.989 50.5403 39.3375 50.8743C39.6878 51.208 40.228 51.1712 40.5443 50.7938Z'
          fill='#C9CCD4'
        />
        <path
          d='M41.0202 52.3227C41.3363 51.9433 41.3087 51.3654 40.9601 51.0314C40.6116 50.6975 40.0696 50.7345 39.7533 51.1119C39.437 51.4892 39.4648 52.0691 39.8133 52.4031C40.1618 52.7371 40.7039 52.7 41.0202 52.3227Z'
          fill='#C9CCD4'
        />
        <path
          d='M43.1269 54.2293C43.8688 53.3392 43.8039 51.9831 42.986 51.1994C42.1682 50.4157 40.8963 50.5026 40.1541 51.3881C39.4118 52.2736 39.477 53.6343 40.2949 54.418C41.1127 55.2017 42.3846 55.1148 43.1269 54.2293Z'
          fill='#C9CCD4'
        />
        <path
          d='M50.6444 68.886C50.8076 68.6918 50.7922 68.3961 50.6129 68.2238C50.4335 68.0515 50.1571 68.0716 49.9939 68.2658C49.8307 68.46 49.8461 68.7557 50.0255 68.928C50.2048 69.1003 50.4812 69.0801 50.6444 68.886Z'
          fill='white'
        />
        <path
          d='M50.6444 68.886C50.8076 68.6918 50.7922 68.3961 50.6129 68.2238C50.4335 68.0515 50.1571 68.0716 49.9939 68.2658C49.8307 68.46 49.8461 68.7557 50.0255 68.928C50.2048 69.1003 50.4812 69.0801 50.6444 68.886Z'
          fill='white'
        />
        <path
          d='M69.5573 77.2053C69.5573 77.2053 69.5566 77.1975 69.5564 77.1955L43.8322 52.0752C43.8322 52.0752 50.4797 62.0898 50.5236 64.9745C50.5658 67.8614 54.796 77.1094 58.939 73.6402C59.6058 73.0824 60.4805 72.934 61.4447 73.0451C61.5088 72.7718 61.6345 72.5091 61.8244 72.2842C62.4419 71.545 63.4931 71.4747 64.1758 72.1266C64.6975 72.6258 64.8516 73.4018 64.6184 74.0709C64.9109 74.2105 65.1988 74.3604 65.4832 74.5127C65.5096 74.4584 65.5436 74.4052 65.5853 74.3571C65.8449 74.0454 66.2881 74.0158 66.576 74.2898C66.8278 74.5306 66.8757 74.923 66.7111 75.2256C67.2532 75.5609 67.749 75.8936 68.1755 76.193C68.3004 76.1273 68.4531 76.1451 68.5618 76.2488C68.6364 76.3209 68.6766 76.4207 68.6764 76.5211C68.7534 76.4965 68.8379 76.5143 68.9024 76.5758C68.9586 76.6284 68.9857 76.704 68.9813 76.7794C69.1116 76.8766 69.2242 76.9621 69.3212 77.0376C69.3497 77.0264 69.3818 77.0345 69.4058 77.0554C69.4257 77.0728 69.4336 77.0994 69.4341 77.125C69.4698 77.1327 69.5047 77.1523 69.5348 77.1803C69.543 77.1892 69.5511 77.1981 69.5575 77.2072L69.5573 77.2053Z'
          fill='white'
        />
        <path
          d='M61.443 73.0449C62.45 73.1609 63.554 73.5592 64.6164 74.0687C64.8496 73.3996 64.6956 72.6236 64.1739 72.1244C63.4911 71.4725 62.4383 71.545 61.8225 72.282C61.6345 72.5086 61.5086 72.7694 61.4428 73.043L61.443 73.0449Z'
          fill='white'
        />
        <path
          d='M65.4811 74.5124C65.9052 74.7431 66.3173 74.985 66.7073 75.2256C66.8737 74.9227 66.8239 74.5305 66.5721 74.2897C66.2842 74.0157 65.841 74.0453 65.5815 74.357C65.5415 74.405 65.5076 74.4581 65.4793 74.5126L65.4811 74.5124Z'
          fill='white'
        />
        <path
          d='M68.6738 76.5504C68.7841 76.6302 68.8848 76.7053 68.9796 76.777C68.9839 76.7017 68.9569 76.626 68.9006 76.5734C68.8362 76.5119 68.7517 76.4941 68.6747 76.5188C68.6731 76.4185 68.6347 76.3185 68.5601 76.2464C68.4514 76.1427 68.2987 76.1249 68.1738 76.1906C68.3428 76.3089 68.5003 76.4227 68.646 76.528C68.6558 76.5348 68.6657 76.5415 68.6736 76.5485L68.6738 76.5504Z'
          fill='white'
        />
        <path
          d='M69.3201 77.0353C69.3615 77.066 69.3991 77.0951 69.4329 77.1228C69.4324 77.0972 69.4227 77.0707 69.4046 77.0531C69.3804 77.0303 69.3485 77.0242 69.3201 77.0353Z'
          fill='white'
        />
        <path
          d='M69.5572 77.2048C69.5509 77.1957 69.5427 77.1868 69.5345 77.1779C69.5044 77.1499 69.4696 77.1322 69.434 77.1245C69.4756 77.1571 69.5112 77.1845 69.5429 77.2104C69.5489 77.2156 69.5557 77.2089 69.5572 77.2048Z'
          fill='white'
        />
        <path
          d='M50.3329 60.2002C50.2696 58.8555 49.1993 57.8339 47.9425 57.9186C46.6856 58.0032 45.718 59.1619 45.7814 60.5066C45.8447 61.8513 46.9149 62.8728 48.1718 62.7882C49.4286 62.7036 50.3962 61.5449 50.3329 60.2002Z'
          fill='white'
        />
        <path
          d='M48.7161 59.5827C48.3214 59.2068 47.7116 59.2477 47.3555 59.675C46.9994 60.1023 47.0301 60.7547 47.4247 61.1306C47.8193 61.5066 48.4292 61.4656 48.7853 61.0383C49.1413 60.6111 49.1107 59.9587 48.7161 59.5827Z'
          fill='white'
        />
        <path
          d='M62.6373 72.9565C62.574 71.6118 61.5038 70.5903 60.2469 70.6749C58.99 70.7595 58.0225 71.9182 58.0858 73.2629C58.1491 74.6076 59.2193 75.6292 60.4762 75.5445C61.7331 75.4599 62.7006 74.3012 62.6373 72.9565Z'
          fill='white'
        />
        <path
          d='M64.294 74.6421C64.4387 74.4678 64.5467 74.2741 64.6186 74.0688C64.8518 73.3996 64.6978 72.6236 64.1761 72.1244C63.4933 71.4725 62.4405 71.545 61.8247 72.282C61.6367 72.5086 61.5108 72.7694 61.445 73.043C61.2978 73.6647 61.4692 74.3462 61.9425 74.7977C62.6252 75.4496 63.678 75.3772 64.2938 74.6401L64.294 74.6421Z'
          fill='white'
        />
        <path
          d='M66.6233 75.3497C66.6548 75.3106 66.6843 75.2698 66.708 75.2257C66.8744 74.9228 66.8247 74.5306 66.5729 74.2899C66.285 74.0159 65.8418 74.0454 65.5822 74.3571C65.5423 74.4051 65.5083 74.4583 65.48 74.5127C65.3354 74.8112 65.389 75.1851 65.6306 75.4153C65.9186 75.6893 66.3617 75.6597 66.6213 75.348L66.6233 75.3497Z'
          fill='white'
        />
        <path
          d='M66.6233 75.3497C66.6548 75.3106 66.6843 75.2698 66.708 75.2257C66.8744 74.9228 66.8247 74.5306 66.5729 74.2899C66.285 74.0159 65.8418 74.0454 65.5822 74.3571C65.5423 74.4051 65.5083 74.4583 65.48 74.5127C65.3354 74.8112 65.389 75.1851 65.6306 75.4153C65.9186 75.6893 66.3617 75.6597 66.6213 75.348L66.6233 75.3497Z'
          fill='white'
        />
        <path
          d='M68.5841 76.7775C68.639 76.7121 68.6687 76.6318 68.6743 76.5504C68.6734 76.5407 68.676 76.5285 68.6752 76.5188C68.6736 76.4185 68.6352 76.3185 68.5606 76.2464C68.4518 76.1427 68.2992 76.1249 68.1743 76.1906C68.1337 76.211 68.0977 76.2408 68.066 76.278C67.9361 76.4328 67.9481 76.6698 68.0911 76.8069C68.2099 76.9193 68.382 76.9289 68.5107 76.8431C68.5385 76.8242 68.5641 76.8015 68.5857 76.7754L68.5841 76.7775Z'
          fill='white'
        />
        <path
          d='M68.5841 76.7775C68.639 76.7121 68.6687 76.6318 68.6743 76.5504C68.6734 76.5407 68.676 76.5285 68.6752 76.5188C68.6736 76.4185 68.6352 76.3185 68.5606 76.2464C68.4518 76.1427 68.2992 76.1249 68.1743 76.1906C68.1337 76.211 68.0977 76.2408 68.066 76.278C67.9361 76.4328 67.9481 76.6698 68.0911 76.8069C68.2099 76.9193 68.382 76.9289 68.5107 76.8431C68.5385 76.8242 68.5641 76.8015 68.5857 76.7754L68.5841 76.7775Z'
          fill='white'
        />
        <path
          d='M69.5495 77.5091C69.6228 77.4219 69.6241 77.2917 69.5574 77.2049C69.551 77.1958 69.5429 77.1869 69.5347 77.178C69.5046 77.1499 69.4698 77.1323 69.4342 77.1246C69.3759 77.1116 69.3162 77.1244 69.2661 77.1617C69.2522 77.1712 69.2387 77.1846 69.2272 77.1997C69.1456 77.2978 69.1532 77.4447 69.242 77.5309C69.3307 77.6172 69.4699 77.607 69.5515 77.5109L69.5495 77.5091Z'
          fill='white'
        />
        <path
          d='M69.5495 77.5091C69.6228 77.4219 69.6241 77.2917 69.5574 77.2049C69.551 77.1958 69.5429 77.1869 69.5347 77.178C69.5046 77.1499 69.4698 77.1323 69.4342 77.1246C69.3759 77.1116 69.3162 77.1244 69.2661 77.1617C69.2522 77.1712 69.2387 77.1846 69.2272 77.1997C69.1456 77.2978 69.1532 77.4447 69.242 77.5309C69.3307 77.6172 69.4699 77.607 69.5515 77.5109L69.5495 77.5091Z'
          fill='white'
        />
        <path
          d='M68.9179 76.9384C68.9562 76.8926 68.9769 76.8351 68.9791 76.7777C68.9835 76.7023 68.9564 76.6267 68.9002 76.5741C68.8357 76.5126 68.7512 76.4948 68.6742 76.5194C68.6651 76.5205 68.6545 76.5257 68.6457 76.5306C68.6139 76.5461 68.5845 76.5672 68.5594 76.5958C68.5012 76.6655 68.484 76.7621 68.5081 76.846C68.5194 76.89 68.5431 76.9286 68.5772 76.9601C68.676 77.055 68.8278 77.0434 68.9178 76.9365L68.9179 76.9384Z'
          fill='white'
        />
        <path
          d='M68.9179 76.9384C68.9562 76.8926 68.9769 76.8351 68.9791 76.7777C68.9835 76.7023 68.9564 76.6267 68.9002 76.5741C68.8357 76.5126 68.7512 76.4948 68.6742 76.5194C68.6651 76.5205 68.6545 76.5257 68.6457 76.5306C68.6139 76.5461 68.5845 76.5672 68.5594 76.5958C68.5012 76.6655 68.484 76.7621 68.5081 76.846C68.5194 76.89 68.5431 76.9286 68.5772 76.9601C68.676 77.055 68.8278 77.0434 68.9178 76.9365L68.9179 76.9384Z'
          fill='white'
        />
        <path
          d='M69.4101 77.1863C69.4252 77.1688 69.4324 77.1463 69.4323 77.1246C69.4318 77.0991 69.4221 77.0726 69.404 77.055C69.3798 77.0322 69.3479 77.026 69.3195 77.0372C69.3052 77.0428 69.2912 77.0503 69.2813 77.0633C69.258 77.0916 69.2522 77.1297 69.2663 77.1635C69.2711 77.1767 69.2795 77.1876 69.2876 77.1965C69.3238 77.2297 69.3788 77.2273 69.4121 77.188L69.4101 77.1863Z'
          fill='white'
        />
        <path
          d='M69.4101 77.1863C69.4252 77.1688 69.4324 77.1463 69.4323 77.1246C69.4318 77.0991 69.4221 77.0726 69.404 77.055C69.3798 77.0322 69.3479 77.026 69.3195 77.0372C69.3052 77.0428 69.2912 77.0503 69.2813 77.0633C69.258 77.0916 69.2522 77.1297 69.2663 77.1635C69.2711 77.1767 69.2795 77.1876 69.2876 77.1965C69.3238 77.2297 69.3788 77.2273 69.4121 77.188L69.4101 77.1863Z'
          fill='white'
        />
        <path
          d='M50.6675 64.1027C51.0736 63.6164 51.0388 62.8758 50.5897 62.4471C50.1406 62.0184 49.4484 62.0649 49.0423 62.5512C48.6362 63.0374 48.671 63.778 49.1201 64.2067C49.5692 64.6354 50.2614 64.5889 50.6675 64.1027Z'
          fill='white'
        />
        <path
          d='M50.6675 64.1027C51.0736 63.6164 51.0388 62.8758 50.5897 62.4471C50.1406 62.0184 49.4484 62.0649 49.0423 62.5512C48.6362 63.0374 48.671 63.778 49.1201 64.2067C49.5692 64.6354 50.2614 64.5889 50.6675 64.1027Z'
          fill='white'
        />
        <path
          d='M51.3828 67.4415C51.7889 66.9553 51.7541 66.2147 51.305 65.786C50.8559 65.3573 50.1637 65.4038 49.7576 65.89C49.3515 66.3763 49.3863 67.1168 49.8354 67.5456C50.2845 67.9743 50.9768 67.9278 51.3828 67.4415Z'
          fill='white'
        />
        <path
          d='M51.3828 67.4415C51.7889 66.9553 51.7541 66.2147 51.305 65.786C50.8559 65.3573 50.1637 65.4038 49.7576 65.89C49.3515 66.3763 49.3863 67.1168 49.8354 67.5456C50.2845 67.9743 50.9768 67.9278 51.3828 67.4415Z'
          fill='white'
        />
        <path
          d='M51.8353 65.1515C52.2414 64.6653 52.2066 63.9247 51.7575 63.4959C51.3084 63.0672 50.6162 63.1138 50.2101 63.6C49.804 64.0862 49.8388 64.8268 50.2879 65.2555C50.737 65.6843 51.4292 65.6377 51.8353 65.1515Z'
          fill='white'
        />
        <path
          d='M51.8353 65.1515C52.2414 64.6653 52.2066 63.9247 51.7575 63.4959C51.3084 63.0672 50.6162 63.1138 50.2101 63.6C49.804 64.0862 49.8388 64.8268 50.2879 65.2555C50.737 65.6843 51.4292 65.6377 51.8353 65.1515Z'
          fill='white'
        />
        <path
          d='M45.4368 55.189C45.7614 54.8008 45.7341 54.2071 45.3738 53.8646C45.0153 53.522 44.4603 53.5585 44.1359 53.9487C43.8115 54.3388 43.8386 54.9305 44.1989 55.273C44.5574 55.6157 45.1124 55.5791 45.4368 55.189Z'
          fill='white'
        />
        <path
          d='M45.4368 55.189C45.7614 54.8008 45.7341 54.2071 45.3738 53.8646C45.0153 53.522 44.4603 53.5585 44.1359 53.9487C43.8115 54.3388 43.8386 54.9305 44.1989 55.273C44.5574 55.6157 45.1124 55.5791 45.4368 55.189Z'
          fill='white'
        />
        <path
          d='M47.7771 58.7498C48.3779 58.0303 48.326 56.9331 47.6616 56.3008C46.997 55.6665 45.9715 55.7358 45.3726 56.4551C44.7718 57.1747 44.8237 58.2718 45.4881 58.9041C46.1527 59.5384 47.1781 59.4691 47.7771 58.7498Z'
          fill='white'
        />
        <path
          d='M46.7799 57.3562C46.655 57.2367 46.4609 57.2493 46.3458 57.3847C46.2326 57.52 46.242 57.7277 46.367 57.8491C46.4921 57.9706 46.6861 57.9561 46.8011 57.8206C46.9143 57.6854 46.905 57.4777 46.7799 57.3562Z'
          fill='white'
        />
        <path
          d='M46.3408 55.9238C46.5689 55.6512 46.5484 55.236 46.2968 54.9972C46.045 54.7564 45.6569 54.7836 45.4306 55.056C45.2026 55.3286 45.2231 55.7438 45.4747 55.9826C45.7263 56.2214 46.1146 56.1962 46.3408 55.9238Z'
          fill='white'
        />
        <path
          d='M46.3408 55.9238C46.5689 55.6512 46.5484 55.236 46.2968 54.9972C46.045 54.7564 45.6569 54.7836 45.4306 55.056C45.2026 55.3286 45.2231 55.7438 45.4747 55.9826C45.7263 56.2214 46.1146 56.1962 46.3408 55.9238Z'
          fill='white'
        />
        <path
          d='M44.0036 53.1265C44.2316 52.8539 44.2111 52.4386 43.9595 52.1998C43.7077 51.9591 43.3196 51.9862 43.0934 52.2586C42.8653 52.5312 42.8858 52.9464 43.1374 53.1852C43.3892 53.426 43.7773 53.3989 44.0036 53.1265Z'
          fill='white'
        />
        <path
          d='M44.0036 53.1265C44.2316 52.8539 44.2111 52.4386 43.9595 52.1998C43.7077 51.9591 43.3196 51.9862 43.0934 52.2586C42.8653 52.5312 42.8858 52.9464 43.1374 53.1852C43.3892 53.426 43.7773 53.3989 44.0036 53.1265Z'
          fill='white'
        />
        <path
          d='M44.6554 54.0951C44.9716 53.7158 44.9439 53.1379 44.5954 52.8039C44.2469 52.4699 43.7049 52.507 43.3886 52.8843C43.0722 53.2617 43.1 53.8416 43.4486 54.1755C43.7971 54.5095 44.3391 54.4725 44.6554 54.0951Z'
          fill='white'
        />
        <path
          d='M44.6554 54.0951C44.9716 53.7158 44.9439 53.1379 44.5954 52.8039C44.2469 52.4699 43.7049 52.507 43.3886 52.8843C43.0722 53.2617 43.1 53.8416 43.4486 54.1755C43.7971 54.5095 44.3391 54.4725 44.6554 54.0951Z'
          fill='white'
        />
        <path
          d='M45.1305 55.6244C45.4467 55.2451 45.419 54.6672 45.0705 54.3332C44.722 53.9992 44.18 54.0363 43.8637 54.4136C43.5475 54.7929 43.5751 55.3709 43.9237 55.7048C44.2722 56.0388 44.8142 56.0018 45.1305 55.6244Z'
          fill='white'
        />
        <path
          d='M45.2439 55.3954C45.5601 55.0161 45.5324 54.4382 45.1839 54.1042C44.8354 53.7702 44.2934 53.8073 43.9771 54.1846C43.6609 54.5639 43.6885 55.1418 44.0371 55.4758C44.3856 55.8098 44.9276 55.7728 45.2439 55.3954Z'
          fill='white'
        />
        <path
          d='M55.8195 54.8217C55.6516 55.0112 55.3748 55.0185 55.1998 54.8411C55.0249 54.6637 55.0215 54.3675 55.1894 54.1779C55.3573 53.9884 55.6341 53.9811 55.809 54.1585C55.984 54.3359 55.9874 54.6321 55.8195 54.8217Z'
          fill='#C9CCD4'
        />
        <path
          d='M55.8195 54.8217C55.6516 55.0112 55.3748 55.0185 55.1998 54.8411C55.0249 54.6637 55.0215 54.3675 55.1894 54.1779C55.3573 53.9884 55.6341 53.9811 55.809 54.1585C55.984 54.3359 55.9874 54.6321 55.8195 54.8217Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.0875 74.3192C65.0875 74.3192 65.0801 74.319 65.0783 74.319L39.6063 48.9062C39.6063 48.9062 49.472 55.191 52.164 55.0067C54.8559 54.8223 63.8154 58.5894 60.9142 63.2843C60.4478 64.0399 60.3796 64.9843 60.5604 66.0034C60.3108 66.0936 60.076 66.2487 59.8817 66.4692C59.2423 67.1867 59.261 68.3131 59.9232 68.9887C60.4302 69.5049 61.1658 69.607 61.7707 69.3048C61.9242 69.6054 62.0869 69.9003 62.2517 70.1913C62.2032 70.2239 62.1564 70.2643 62.1148 70.3127C61.8451 70.6144 61.8531 71.0893 62.1315 71.3743C62.3761 71.6234 62.7456 71.643 63.0145 71.4433C63.3704 71.9944 63.7201 72.4964 64.0333 72.9271C63.9821 73.0655 64.011 73.2268 64.1163 73.3345C64.1895 73.4082 64.2857 73.4431 64.3793 73.4348C64.3625 73.5189 64.3858 73.6076 64.4483 73.6713C64.5018 73.7271 64.5745 73.7499 64.6444 73.7392C64.7455 73.8703 64.8341 73.9835 64.9122 74.0809C64.9041 74.1122 64.9142 74.1457 64.9356 74.1696C64.9534 74.1895 64.9789 74.1958 65.0027 74.1942C65.0128 74.2316 65.0338 74.2673 65.0624 74.2971C65.0713 74.3051 65.0803 74.3132 65.0893 74.3192L65.0875 74.3192Z'
          fill='#C9CCD4'
        />
        <path
          d='M60.5611 66.0036C60.7499 67.0679 61.2096 68.213 61.7695 69.3049C61.1646 69.6071 60.429 69.505 59.9221 68.9888C59.2598 68.3132 59.243 67.1849 59.8806 66.4693C60.0767 66.2508 60.3096 66.0956 60.5593 66.0035L60.5611 66.0036Z'
          fill='#C9CCD4'
        />
        <path
          d='M62.2516 70.1911C62.5006 70.6248 62.7591 71.0448 63.0145 71.4412C62.7455 71.6429 62.3761 71.6213 62.1315 71.3721C61.8531 71.0871 61.8451 70.6123 62.1148 70.3106C62.1563 70.2642 62.2031 70.2237 62.2517 70.1892L62.2516 70.1911Z'
          fill='#C9CCD4'
        />
        <path
          d='M64.407 73.4289C64.4902 73.5401 64.5682 73.6414 64.6427 73.7368C64.5728 73.7474 64.5002 73.7247 64.4466 73.6689C64.3842 73.6051 64.3608 73.5165 64.3776 73.4324C64.284 73.4387 64.1878 73.4058 64.1146 73.3321C64.0093 73.2244 63.9804 73.0631 64.0316 72.9247C64.1555 73.0954 64.2741 73.2542 64.3839 73.4011C64.391 73.411 64.3981 73.4209 64.4052 73.4289L64.407 73.4289Z'
          fill='#C9CCD4'
        />
        <path
          d='M64.9107 74.0791C64.9426 74.1208 64.9727 74.1586 65.0012 74.1924C64.9773 74.194 64.9519 74.1857 64.934 74.1678C64.9108 74.1439 64.9025 74.1104 64.9107 74.0791Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.0878 74.3188C65.0788 74.3128 65.0698 74.3048 65.0609 74.2968C65.0323 74.2669 65.0131 74.2313 65.0031 74.1938C65.0368 74.2356 65.0652 74.2713 65.0918 74.3032C65.0972 74.3091 65.0915 74.3169 65.0878 74.3188Z'
          fill='#C9CCD4'
        />
        <path
          d='M47.6314 55.817C46.3729 55.8285 45.3568 54.7457 45.3617 53.3983C45.3667 52.051 46.3909 50.9495 47.6494 50.9379C48.9078 50.9264 49.924 52.0092 49.919 53.3565C49.914 54.7038 48.8898 55.8054 47.6314 55.817Z'
          fill='#C9CCD4'
        />
        <path
          d='M46.9948 53.5112C46.6129 53.1206 46.6022 52.4672 46.9718 52.0533C47.3415 51.6394 47.952 51.6198 48.3339 52.0104C48.7159 52.401 48.7266 53.0545 48.3569 53.4683C47.9873 53.8822 47.3768 53.9018 46.9948 53.5112Z'
          fill='#C9CCD4'
        />
        <path
          d='M60.524 67.2764C59.2655 67.288 58.2493 66.2051 58.2543 64.8578C58.2593 63.5105 59.2835 62.4089 60.5419 62.3974C61.8004 62.3858 62.8165 63.4687 62.8116 64.816C62.8066 66.1633 61.7824 67.2649 60.524 67.2764Z'
          fill='#C9CCD4'
        />
        <path
          d='M62.2777 68.9122C62.1269 69.0805 61.955 69.2111 61.7694 69.3042C61.1645 69.6064 60.4289 69.5044 59.9219 68.9882C59.2597 68.3125 59.2429 67.1843 59.8804 66.4687C60.0766 66.2502 60.3095 66.095 60.5592 66.0029C61.1268 65.7962 61.7756 65.9243 62.2344 66.3927C62.8966 67.0683 62.9134 68.1966 62.2759 68.9122L62.2777 68.9122Z'
          fill='#C9CCD4'
        />
        <path
          d='M63.1235 71.3413C63.0896 71.378 63.0539 71.4128 63.0147 71.4416C62.7457 71.6432 62.3762 71.6216 62.1317 71.3725C61.8533 71.0875 61.8453 70.6126 62.115 70.3109C62.1565 70.2645 62.2033 70.2241 62.2518 70.1895C62.5184 70.0114 62.8712 70.0386 63.105 70.2778C63.3834 70.5628 63.3914 71.0376 63.1217 71.3393L63.1235 71.3413Z'
          fill='#C9CCD4'
        />
        <path
          d='M63.1235 71.3413C63.0896 71.378 63.0539 71.4128 63.0147 71.4416C62.7457 71.6432 62.3762 71.6216 62.1317 71.3725C61.8533 71.0875 61.8453 70.6126 62.115 70.3109C62.1565 70.2645 62.2033 70.2241 62.2518 70.1895C62.5184 70.0114 62.8712 70.0386 63.105 70.2778C63.3834 70.5628 63.3914 71.0376 63.1217 71.3393L63.1235 71.3413Z'
          fill='#C9CCD4'
        />
        <path
          d='M64.6111 73.3166C64.5545 73.3804 64.4821 73.4185 64.4067 73.431C64.3975 73.4309 64.3864 73.4346 64.3773 73.4345C64.2837 73.4408 64.1875 73.4079 64.1143 73.3341C64.0089 73.2265 63.9801 73.0652 64.0313 72.9267C64.0471 72.8819 64.072 72.8411 64.1041 72.8043C64.238 72.6534 64.4598 72.6472 64.599 72.7887C64.7132 72.9063 64.736 73.0891 64.6646 73.2331C64.6491 73.2642 64.63 73.2933 64.6074 73.3185L64.6111 73.3166Z'
          fill='#C9CCD4'
        />
        <path
          d='M64.6111 73.3166C64.5545 73.3804 64.4821 73.4185 64.4067 73.431C64.3975 73.4309 64.3864 73.4346 64.3773 73.4345C64.2837 73.4408 64.1875 73.4079 64.1143 73.3341C64.0089 73.2265 63.9801 73.0652 64.0313 72.9267C64.0471 72.8819 64.072 72.8411 64.1041 72.8043C64.238 72.6534 64.4598 72.6472 64.599 72.7887C64.7132 72.9063 64.736 73.0891 64.6646 73.2331C64.6491 73.2642 64.63 73.2933 64.6074 73.3185L64.6111 73.3166Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.3709 74.286C65.2954 74.3711 65.1742 74.3829 65.088 74.3187C65.079 74.3127 65.07 74.3047 65.0611 74.2967C65.0325 74.2668 65.0133 74.2312 65.0032 74.1938C64.9865 74.1327 64.9936 74.068 65.0244 74.0116C65.0321 73.9961 65.0435 73.9806 65.0567 73.967C65.1416 73.8723 65.279 73.8686 65.3665 73.9563C65.454 74.0441 65.4557 74.1932 65.3727 74.2879L65.3709 74.286Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.3709 74.286C65.2954 74.3711 65.1742 74.3829 65.088 74.3187C65.079 74.3127 65.07 74.3047 65.0611 74.2967C65.0325 74.2668 65.0133 74.2312 65.0032 74.1938C64.9865 74.1327 64.9936 74.068 65.0244 74.0116C65.0321 73.9961 65.0435 73.9806 65.0567 73.967C65.1416 73.8723 65.279 73.8686 65.3665 73.9563C65.454 74.0441 65.4557 74.1932 65.3727 74.2879L65.3709 74.286Z'
          fill='#C9CCD4'
        />
        <path
          d='M64.7872 73.6591C64.7476 73.7036 64.6956 73.7303 64.6423 73.7372C64.5724 73.7479 64.4997 73.7252 64.4462 73.6694C64.3838 73.6056 64.3604 73.5169 64.3772 73.4328C64.3774 73.423 64.3814 73.4113 64.3853 73.4016C64.3972 73.3665 64.4146 73.3334 64.4391 73.3044C64.4995 73.2367 64.5881 73.2106 64.6682 73.2296C64.7101 73.2381 64.748 73.2603 64.7801 73.2941C64.8765 73.3918 64.8778 73.5547 64.7854 73.6591L64.7872 73.6591Z'
          fill='#C9CCD4'
        />
        <path
          d='M64.7872 73.6591C64.7476 73.7036 64.6956 73.7303 64.6423 73.7372C64.5724 73.7479 64.4997 73.7252 64.4462 73.6694C64.3838 73.6056 64.3604 73.5169 64.3772 73.4328C64.3774 73.423 64.3814 73.4113 64.3853 73.4016C64.3972 73.3665 64.4146 73.3334 64.4391 73.3044C64.4995 73.2367 64.5881 73.2106 64.6682 73.2296C64.7101 73.2381 64.748 73.2603 64.7801 73.2941C64.8765 73.3918 64.8778 73.5547 64.7854 73.6591L64.7872 73.6591Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.059 74.1637C65.0439 74.1811 65.0235 74.1906 65.0033 74.1922C64.9795 74.1938 64.954 74.1855 64.9362 74.1676C64.913 74.1437 64.9047 74.1102 64.9128 74.0789C64.9169 74.0633 64.9228 74.0477 64.9341 74.0361C64.9586 74.0091 64.9936 73.9998 65.0263 74.0121C65.039 74.0162 65.0498 74.0242 65.0587 74.0322C65.0926 74.0681 65.0947 74.127 65.0608 74.1657L65.059 74.1637Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.059 74.1637C65.0439 74.1811 65.0235 74.1906 65.0033 74.1922C64.9795 74.1938 64.954 74.1855 64.9362 74.1676C64.913 74.1437 64.9047 74.1102 64.9128 74.0789C64.9169 74.0633 64.9228 74.0477 64.9341 74.0361C64.9586 74.0091 64.9936 73.9998 65.0263 74.0121C65.039 74.0162 65.0498 74.0242 65.0587 74.0322C65.0926 74.0681 65.0947 74.127 65.0608 74.1657L65.059 74.1637Z'
          fill='#C9CCD4'
        />
        <path
          d='M51.3619 55.2287C50.9413 55.7006 50.2483 55.7229 49.8127 55.2784C49.3772 54.834 49.3651 54.0922 49.7857 53.6203C50.2063 53.1484 50.8993 53.1262 51.3348 53.5707C51.7704 54.0151 51.7825 54.7568 51.3619 55.2287Z'
          fill='#C9CCD4'
        />
        <path
          d='M51.3619 55.2287C50.9413 55.7006 50.2483 55.7229 49.8127 55.2784C49.3772 54.834 49.3651 54.0922 49.7857 53.6203C50.2063 53.1484 50.8993 53.1262 51.3348 53.5707C51.7704 54.0151 51.7825 54.7568 51.3619 55.2287Z'
          fill='#C9CCD4'
        />
        <path
          d='M54.5326 55.7248C54.112 56.1967 53.419 56.219 52.9835 55.7745C52.548 55.3301 52.5359 54.5883 52.9565 54.1164C53.3771 53.6445 54.0701 53.6223 54.5056 54.0668C54.9412 54.5112 54.9532 55.2529 54.5326 55.7248Z'
          fill='#C9CCD4'
        />
        <path
          d='M55.3111 56.3766C54.7646 56.6629 54.1113 56.4238 53.8507 55.8422C53.5901 55.2606 53.8216 54.5581 54.3681 54.2718C54.9147 53.9855 55.568 54.2246 55.8285 54.8062C56.0891 55.3878 55.8576 56.0903 55.3111 56.3766Z'
          fill='#C9CCD4'
        />
        <path
          d='M52.4342 56.3899C52.0136 56.8618 51.3207 56.884 50.8851 56.4395C50.4496 55.9951 50.4375 55.2534 50.8581 54.7815C51.2787 54.3096 51.9717 54.2873 52.4072 54.7318C52.8428 55.1762 52.8549 55.918 52.4342 56.3899Z'
          fill='#C9CCD4'
        />
        <path
          d='M52.4342 56.3899C52.0136 56.8618 51.3207 56.884 50.8851 56.4395C50.4496 55.9951 50.4375 55.2534 50.8581 54.7815C51.2787 54.3096 51.9717 54.2873 52.4072 54.7318C52.8428 55.1762 52.8549 55.918 52.4342 56.3899Z'
          fill='#C9CCD4'
        />
        <path
          d='M42.6371 50.3671C42.3013 50.7442 41.7458 50.7628 41.3978 50.406C41.0497 50.0513 41.0393 49.4567 41.3769 49.0796C41.7145 48.7025 42.2682 48.6839 42.6162 49.0406C42.9643 49.3954 42.9747 49.99 42.6371 50.3671Z'
          fill='#C9CCD4'
        />
        <path
          d='M42.6371 50.3671C42.3013 50.7442 41.7458 50.7628 41.3978 50.406C41.0497 50.0513 41.0393 49.4567 41.3769 49.0796C41.7145 48.7025 42.2682 48.6839 42.6162 49.0406C42.9643 49.3954 42.9747 49.99 42.6371 50.3671Z'
          fill='#C9CCD4'
        />
        <path
          d='M46.143 52.578C45.5205 53.2762 44.4939 53.3088 43.8514 52.6511C43.2088 51.9934 43.1894 50.8945 43.8118 50.1983C44.4342 49.5021 45.4608 49.4676 46.1034 50.1253C46.7478 50.783 46.7653 51.8818 46.143 52.578Z'
          fill='#C9CCD4'
        />
        <path
          d='M44.7646 51.6258C44.6432 51.5022 44.6394 51.2942 44.7564 51.1607C44.8733 51.0292 45.0676 51.0225 45.1909 51.1461C45.3141 51.2698 45.3161 51.4777 45.1991 51.6112C45.0821 51.7427 44.8878 51.7494 44.7646 51.6258Z'
          fill='#C9CCD4'
        />
        <path
          d='M43.3945 51.2721C43.1587 51.5371 42.7701 51.5485 42.5273 51.2994C42.2828 51.0502 42.277 50.6343 42.5127 50.3712C42.7485 50.1063 43.1371 50.0949 43.3798 50.344C43.6244 50.5931 43.6302 51.0091 43.3945 51.2721Z'
          fill='#C9CCD4'
        />
        <path
          d='M43.3945 51.2721C43.1587 51.5371 42.7701 51.5485 42.5273 51.2994C42.2828 51.0502 42.277 50.6343 42.5127 50.3712C42.7485 50.1063 43.1371 50.0949 43.3798 50.344C43.6244 50.5931 43.6302 51.0091 43.3945 51.2721Z'
          fill='#C9CCD4'
        />
        <path
          d='M40.6 49.0063C40.3642 49.2712 39.9756 49.2826 39.7329 49.0335C39.4902 48.7844 39.4825 48.3684 39.7183 48.1054C39.954 47.8424 40.3427 47.829 40.5854 48.0781C40.8281 48.3273 40.8357 48.7433 40.6 49.0063Z'
          fill='#C9CCD4'
        />
        <path
          d='M40.4802 48.839C40.0618 49.3092 39.3721 49.3295 38.9414 48.8874C38.5107 48.4453 38.4971 47.7071 38.9155 47.2403C39.3338 46.7736 40.0235 46.7499 40.4543 47.1919C40.885 47.634 40.8986 48.3722 40.4802 48.839Z'
          fill='#C9CCD4'
        />
        <path
          d='M41.5555 49.6224C41.2273 49.9898 40.6865 50.0066 40.3474 49.6618C40.0082 49.315 39.9993 48.7362 40.3256 48.3687C40.6538 48.0013 41.1946 47.9844 41.5338 48.3293C41.8729 48.676 41.8818 49.2549 41.5555 49.6224Z'
          fill='#C9CCD4'
        />
        <path
          d='M41.5555 49.6224C41.2273 49.9898 40.6865 50.0066 40.3474 49.6618C40.0082 49.315 39.9993 48.7362 40.3256 48.3687C40.6538 48.0013 41.1946 47.9844 41.5338 48.3293C41.8729 48.676 41.8818 49.2549 41.5555 49.6224Z'
          fill='#C9CCD4'
        />
        <path
          d='M43.0188 50.0062C42.6906 50.3736 42.1498 50.3904 41.8106 50.0456C41.4714 49.7008 41.4625 49.12 41.7889 48.7525C42.1152 48.385 42.6578 48.3682 42.997 48.713C43.3362 49.0579 43.3451 49.6387 43.0188 50.0062Z'
          fill='#C9CCD4'
        />
        <path
          d='M45.06 51.0477C44.2899 51.9099 43.0209 51.9494 42.225 51.1402C41.4291 50.3311 41.4082 48.9682 42.174 48.1059C42.9398 47.2436 44.2131 47.2041 45.009 48.0133C45.8049 48.8224 45.8258 50.1854 45.06 51.0477Z'
          fill='#C9CCD4'
        />
        <path
          d='M59.2257 58.9403C59.0578 59.1299 58.781 59.1372 58.6061 58.9598C58.4311 58.7824 58.4278 58.4861 58.5957 58.2966C58.7635 58.107 59.0403 58.0997 59.2153 58.2771C59.3902 58.4545 59.3936 58.7508 59.2257 58.9403Z'
          fill='white'
        />
        <path
          d='M59.2257 58.9403C59.0578 59.1299 58.781 59.1372 58.6061 58.9598C58.4311 58.7824 58.4278 58.4861 58.5957 58.2966C58.7635 58.107 59.0403 58.0997 59.2153 58.2771C59.3902 58.4545 59.3936 58.7508 59.2257 58.9403Z'
          fill='white'
        />
        <path
          d='M68.4931 78.4378C68.4931 78.4378 68.4858 78.4377 68.4839 78.4377L43.012 53.0249C43.012 53.0249 52.8777 59.3097 55.5696 59.1253C58.2634 58.9391 67.2211 62.7081 64.3198 67.4029C63.8535 68.1586 63.7853 69.103 63.9661 70.1221C63.7165 70.2122 63.4817 70.3673 63.2873 70.5878C62.6479 71.3054 62.6666 72.4317 63.3289 73.1074C63.8358 73.6236 64.5714 73.7256 65.1763 73.4234C65.3298 73.7241 65.4926 74.019 65.6573 74.31C65.6088 74.3425 65.562 74.383 65.5205 74.4314C65.2508 74.7331 65.2587 75.2079 65.5372 75.4929C65.7817 75.7421 66.1512 75.7617 66.4201 75.562C66.776 76.1131 67.1258 76.615 67.4389 77.0457C67.3878 77.1842 67.4166 77.3455 67.5219 77.4531C67.5951 77.5269 67.6913 77.5618 67.7849 77.5535C67.7681 77.6375 67.7915 77.7262 67.854 77.79C67.9075 77.8458 67.9802 77.8685 68.05 77.8579C68.1511 77.989 68.2398 78.1022 68.3179 78.1996C68.3097 78.2308 68.3199 78.2644 68.3412 78.2882C68.359 78.3081 68.3845 78.3144 68.4084 78.3129C68.4184 78.3503 68.4395 78.3859 68.468 78.4158C68.477 78.4238 68.4859 78.4318 68.4949 78.4378L68.4931 78.4378Z'
          fill='white'
        />
        <path
          d='M63.966 70.1198C64.1548 71.1841 64.6145 72.3292 65.1744 73.4211C64.5695 73.7233 63.8339 73.6212 63.327 73.105C62.6647 72.4294 62.6479 71.3011 63.2855 70.5855C63.4816 70.367 63.7145 70.2119 63.9642 70.1197L63.966 70.1198Z'
          fill='white'
        />
        <path
          d='M65.6579 74.3074C65.9068 74.741 66.1653 75.161 66.4207 75.5574C66.1518 75.7591 65.7823 75.7375 65.5378 75.4883C65.2593 75.2033 65.2514 74.7285 65.5211 74.4268C65.5626 74.3804 65.6094 74.3399 65.6579 74.3054L65.6579 74.3074Z'
          fill='white'
        />
        <path
          d='M67.8127 77.5476C67.8959 77.6588 67.9739 77.7601 68.0484 77.8554C67.9785 77.8661 67.9058 77.8434 67.8523 77.7876C67.7898 77.7238 67.7664 77.6351 67.7832 77.551C67.6897 77.5574 67.5934 77.5245 67.5202 77.4507C67.4149 77.3431 67.3861 77.1818 67.4373 77.0433C67.5611 77.214 67.6798 77.3728 67.7896 77.5198C67.7966 77.5297 67.8037 77.5396 67.8108 77.5476L67.8127 77.5476Z'
          fill='white'
        />
        <path
          d='M68.3163 78.1983C68.3482 78.24 68.3784 78.2778 68.4068 78.3116C68.383 78.3131 68.3575 78.3049 68.3397 78.2869C68.3165 78.263 68.3082 78.2295 68.3163 78.1983Z'
          fill='white'
        />
        <path
          d='M68.4934 78.4379C68.4844 78.4319 68.4755 78.4239 68.4665 78.4159C68.438 78.386 68.4187 78.3504 68.4087 78.313C68.4424 78.3547 68.4708 78.3905 68.4975 78.4223C68.5028 78.4283 68.4971 78.436 68.4934 78.4379Z'
          fill='white'
        />
        <path
          d='M51.1058 59.3049C49.8476 59.3451 48.8098 58.286 48.788 56.9392C48.7661 55.5925 49.7685 54.4681 51.0268 54.4278C52.285 54.3876 53.3228 55.4468 53.3446 56.7935C53.3664 58.1403 52.3641 59.2647 51.1058 59.3049Z'
          fill='white'
        />
        <path
          d='M50.4003 57.6303C50.0184 57.2397 50.0077 56.5863 50.3773 56.1724C50.747 55.7586 51.3575 55.7389 51.7395 56.1295C52.1214 56.5202 52.1321 57.1736 51.7624 57.5875C51.3928 58.0013 50.7823 58.021 50.4003 57.6303Z'
          fill='white'
        />
        <path
          d='M63.9797 71.4006C62.7214 71.4408 61.6837 70.3817 61.6619 69.0349C61.64 67.6882 62.6424 66.5638 63.9006 66.5235C65.1589 66.4833 66.1967 67.5425 66.2185 68.8892C66.2403 70.236 65.238 71.3604 63.9797 71.4006Z'
          fill='white'
        />
        <path
          d='M65.6828 73.0314C65.5319 73.1996 65.36 73.3302 65.1744 73.4234C64.5695 73.7256 63.8339 73.6235 63.327 73.1073C62.6647 72.4317 62.6479 71.3034 63.2855 70.5878C63.4816 70.3693 63.7145 70.2142 63.9642 70.122C64.5319 69.9154 65.1807 70.0435 65.6394 70.5118C66.3016 71.1875 66.3185 72.3157 65.6809 73.0313L65.6828 73.0314Z'
          fill='white'
        />
        <path
          d='M66.5291 75.458C66.4952 75.4947 66.4595 75.5295 66.4203 75.5583C66.1513 75.7599 65.7819 75.7383 65.5373 75.4892C65.2589 75.2042 65.2509 74.7293 65.5206 74.4276C65.5621 74.3812 65.609 74.3408 65.6575 74.3062C65.924 74.1281 66.2768 74.1553 66.5106 74.3945C66.7891 74.6795 66.797 75.1543 66.5273 75.456L66.5291 75.458Z'
          fill='white'
        />
        <path
          d='M66.5291 75.458C66.4952 75.4947 66.4595 75.5295 66.4203 75.5583C66.1513 75.7599 65.7819 75.7383 65.5373 75.4892C65.2589 75.2042 65.2509 74.7293 65.5206 74.4276C65.5621 74.3812 65.609 74.3408 65.6575 74.3062C65.924 74.1281 66.2768 74.1553 66.5106 74.3945C66.7891 74.6795 66.797 75.1543 66.5273 75.456L66.5291 75.458Z'
          fill='white'
        />
        <path
          d='M68.0168 77.4343C67.9602 77.4981 67.8878 77.5362 67.8123 77.5487C67.8032 77.5485 67.7921 77.5523 67.7829 77.5521C67.6893 77.5585 67.5931 77.5255 67.5199 77.4518C67.4146 77.3442 67.3858 77.1829 67.4369 77.0444C67.4527 76.9995 67.4776 76.9587 67.5097 76.922C67.6436 76.7711 67.8654 76.7649 68.0047 76.9064C68.1189 77.024 68.1417 77.2068 68.072 77.3508C68.0566 77.3819 68.0375 77.4111 68.0149 77.4362L68.0168 77.4343Z'
          fill='white'
        />
        <path
          d='M68.0168 77.4343C67.9602 77.4981 67.8878 77.5362 67.8123 77.5487C67.8032 77.5485 67.7921 77.5523 67.7829 77.5521C67.6893 77.5585 67.5931 77.5255 67.5199 77.4518C67.4146 77.3442 67.3858 77.1829 67.4369 77.0444C67.4527 76.9995 67.4776 76.9587 67.5097 76.922C67.6436 76.7711 67.8654 76.7649 68.0047 76.9064C68.1189 77.024 68.1417 77.2068 68.072 77.3508C68.0566 77.3819 68.0375 77.4111 68.0149 77.4362L68.0168 77.4343Z'
          fill='white'
        />
        <path
          d='M68.7764 78.4051C68.7009 78.4902 68.5798 78.502 68.4935 78.4379C68.4845 78.4318 68.4755 78.4238 68.4666 78.4158C68.438 78.3859 68.4188 78.3503 68.4088 78.3129C68.392 78.2518 68.3992 78.1872 68.4299 78.1308C68.4376 78.1152 68.449 78.0997 68.4622 78.0862C68.5471 77.9914 68.6846 77.9877 68.7721 78.0755C68.8596 78.1632 68.8612 78.3123 68.7782 78.4071L68.7764 78.4051Z'
          fill='white'
        />
        <path
          d='M68.7764 78.4051C68.7009 78.4902 68.5798 78.502 68.4935 78.4379C68.4845 78.4318 68.4755 78.4238 68.4666 78.4158C68.438 78.3859 68.4188 78.3503 68.4088 78.3129C68.392 78.2518 68.3992 78.1872 68.4299 78.1308C68.4376 78.1152 68.449 78.0997 68.4622 78.0862C68.5471 77.9914 68.6846 77.9877 68.7721 78.0755C68.8596 78.1632 68.8612 78.3123 68.7782 78.4071L68.7764 78.4051Z'
          fill='white'
        />
        <path
          d='M68.1934 77.7778C68.1538 77.8223 68.1019 77.8489 68.0486 77.8559C67.9787 77.8665 67.906 77.8438 67.8525 77.788C67.79 77.7242 67.7666 77.6356 67.7834 77.5515C67.7837 77.5417 67.7877 77.53 67.7916 77.5202C67.8035 77.4851 67.8208 77.452 67.8454 77.423C67.9057 77.3553 67.9943 77.3293 68.0744 77.3482C68.1163 77.3568 68.1542 77.3789 68.1863 77.4128C68.2827 77.5105 68.2841 77.6733 68.1916 77.7778L68.1934 77.7778Z'
          fill='white'
        />
        <path
          d='M68.1934 77.7778C68.1538 77.8223 68.1019 77.8489 68.0486 77.8559C67.9787 77.8665 67.906 77.8438 67.8525 77.788C67.79 77.7242 67.7666 77.6356 67.7834 77.5515C67.7837 77.5417 67.7877 77.53 67.7916 77.5202C67.8035 77.4851 67.8208 77.452 67.8454 77.423C67.9057 77.3553 67.9943 77.3293 68.0744 77.3482C68.1163 77.3568 68.1542 77.3789 68.1863 77.4128C68.2827 77.5105 68.2841 77.6733 68.1916 77.7778L68.1934 77.7778Z'
          fill='white'
        />
        <path
          d='M68.4645 78.2823C68.4494 78.2997 68.429 78.3092 68.4088 78.3109C68.385 78.3124 68.3595 78.3042 68.3417 78.2863C68.3185 78.2623 68.3102 78.2288 68.3183 78.1976C68.3224 78.182 68.3283 78.1664 68.3396 78.1548C68.3641 78.1277 68.3991 78.1185 68.4318 78.1308C68.4445 78.1349 68.4553 78.1429 68.4642 78.1509C68.4981 78.1867 68.5003 78.2456 68.4663 78.2843L68.4645 78.2823Z'
          fill='white'
        />
        <path
          d='M68.4645 78.2823C68.4494 78.2997 68.429 78.3092 68.4088 78.3109C68.385 78.3124 68.3595 78.3042 68.3417 78.2863C68.3185 78.2623 68.3102 78.2288 68.3183 78.1976C68.3224 78.182 68.3283 78.1664 68.3396 78.1548C68.3641 78.1277 68.3991 78.1185 68.4318 78.1308C68.4445 78.1349 68.4553 78.1429 68.4642 78.1509C68.4981 78.1867 68.5003 78.2456 68.4663 78.2843L68.4645 78.2823Z'
          fill='white'
        />
        <path
          d='M54.7688 59.3479C54.3482 59.8198 53.6552 59.842 53.2197 59.3975C52.7842 58.9531 52.7721 58.2114 53.1927 57.7395C53.6133 57.2676 54.3063 57.2454 54.7418 57.6898C55.1774 58.1343 55.1895 58.876 54.7688 59.3479Z'
          fill='white'
        />
        <path
          d='M54.7688 59.3479C54.3482 59.8198 53.6552 59.842 53.2197 59.3975C52.7842 58.9531 52.7721 58.2114 53.1927 57.7395C53.6133 57.2676 54.3063 57.2454 54.7418 57.6898C55.1774 58.1343 55.1895 58.876 54.7688 59.3479Z'
          fill='white'
        />
        <path
          d='M57.9383 59.844C57.5177 60.3159 56.8247 60.3381 56.3891 59.8937C55.9536 59.4492 55.9415 58.7075 56.3621 58.2356C56.7827 57.7637 57.4757 57.7414 57.9113 58.1859C58.3468 58.6304 58.3589 59.3721 57.9383 59.844Z'
          fill='white'
        />
        <path
          d='M57.9383 59.844C57.5177 60.3159 56.8247 60.3381 56.3891 59.8937C55.9536 59.4492 55.9415 58.7075 56.3621 58.2356C56.7827 57.7637 57.4757 57.7414 57.9113 58.1859C58.3468 58.6304 58.3589 59.3721 57.9383 59.844Z'
          fill='white'
        />
        <path
          d='M55.8406 60.509C55.42 60.9809 54.727 61.0031 54.2915 60.5587C53.856 60.1142 53.8439 59.3725 54.2645 58.9006C54.6851 58.4287 55.3781 58.4065 55.8136 58.8509C56.2491 59.2954 56.2612 60.0371 55.8406 60.509Z'
          fill='white'
        />
        <path
          d='M55.8406 60.509C55.42 60.9809 54.727 61.0031 54.2915 60.5587C53.856 60.1142 53.8439 59.3725 54.2645 58.9006C54.6851 58.4287 55.3781 58.4065 55.8136 58.8509C56.2491 59.2954 56.2612 60.0371 55.8406 60.509Z'
          fill='white'
        />
        <path
          d='M46.0426 54.4862C45.7069 54.8634 45.1513 54.8819 44.8033 54.5252C44.4552 54.1704 44.4449 53.5758 44.7824 53.1987C45.12 52.8216 45.6737 52.803 46.0217 53.1598C46.3698 53.5145 46.3802 54.1091 46.0426 54.4862Z'
          fill='white'
        />
        <path
          d='M46.0426 54.4862C45.7069 54.8634 45.1513 54.8819 44.8033 54.5252C44.4552 54.1704 44.4449 53.5758 44.7824 53.1987C45.12 52.8216 45.6737 52.803 46.0217 53.1598C46.3698 53.5145 46.3802 54.1091 46.0426 54.4862Z'
          fill='white'
        />
        <path
          d='M49.5492 56.6959C48.9268 57.3941 47.9002 57.4266 47.2576 56.769C46.6133 56.1113 46.5957 55.0124 47.2181 54.3162C47.8405 53.618 48.8671 53.5855 49.5097 54.2432C50.154 54.9009 50.1716 55.9997 49.5492 56.6959Z'
          fill='white'
        />
        <path
          d='M48.1702 55.744C48.0488 55.6204 48.045 55.4124 48.162 55.2789C48.2789 55.1474 48.4733 55.1407 48.5965 55.2643C48.7197 55.3879 48.7217 55.5959 48.6047 55.7294C48.4878 55.8609 48.2934 55.8676 48.1702 55.744Z'
          fill='white'
        />
        <path
          d='M46.8001 55.3903C46.5643 55.6552 46.1757 55.6667 45.933 55.4176C45.6884 55.1684 45.6826 54.7524 45.9183 54.4894C46.1541 54.2244 46.5427 54.213 46.7855 54.4621C47.0282 54.7113 47.0358 55.1273 46.8001 55.3903Z'
          fill='white'
        />
        <path
          d='M46.8001 55.3903C46.5643 55.6552 46.1757 55.6667 45.933 55.4176C45.6884 55.1684 45.6826 54.7524 45.9183 54.4894C46.1541 54.2244 46.5427 54.213 46.7855 54.4621C47.0282 54.7113 47.0358 55.1273 46.8001 55.3903Z'
          fill='white'
        />
        <path
          d='M44.0057 53.1232C43.7699 53.3881 43.3813 53.3995 43.1385 53.1504C42.894 52.9013 42.8882 52.4853 43.1239 52.2223C43.3597 51.9574 43.7483 51.9459 43.991 52.1951C44.2356 52.4442 44.2414 52.8602 44.0057 53.1232Z'
          fill='white'
        />
        <path
          d='M44.0057 53.1232C43.7699 53.3881 43.3813 53.3995 43.1385 53.1504C42.894 52.9013 42.8882 52.4853 43.1239 52.2223C43.3597 51.9574 43.7483 51.9459 43.991 52.1951C44.2356 52.4442 44.2414 52.8602 44.0057 53.1232Z'
          fill='white'
        />
        <path
          d='M44.9611 53.741C44.633 54.1085 44.0922 54.1253 43.753 53.7805C43.4138 53.4357 43.4049 52.8549 43.7313 52.4874C44.0576 52.1199 44.6002 52.1031 44.9394 52.4479C45.2786 52.7927 45.2875 53.3735 44.9611 53.741Z'
          fill='white'
        />
        <path
          d='M44.9611 53.741C44.633 54.1085 44.0922 54.1253 43.753 53.7805C43.4138 53.4357 43.4049 52.8549 43.7313 52.4874C44.0576 52.1199 44.6002 52.1031 44.9394 52.4479C45.2786 52.7927 45.2875 53.3735 44.9611 53.741Z'
          fill='white'
        />
        <path
          d='M46.4244 54.1248C46.0962 54.4923 45.5554 54.5091 45.2162 54.1643C44.8771 53.8195 44.8682 53.2387 45.1945 52.8712C45.5227 52.5037 46.0635 52.4869 46.4027 52.8317C46.7418 53.1765 46.7507 53.7573 46.4244 54.1248Z'
          fill='white'
        />
        <path
          d='M45.7683 53.246C45.2987 53.7718 44.5248 53.7959 44.0395 53.3024C43.5541 52.809 43.5414 51.9779 44.0084 51.452C44.478 50.9262 45.2519 50.9021 45.7372 51.3955C46.2226 51.889 46.2353 52.7201 45.7683 53.246Z'
          fill='white'
        />
        <path
          d='M188.46 187.011H15.1757V185.522C28.8628 151.037 62.3684 126.671 101.53 126.671C140.692 126.671 175.157 151.74 188.46 187.011Z'
          fill='#2800D1'
        />
        <path
          d='M103.09 149.671C109.085 149.671 113.944 148.365 113.944 146.754C113.944 145.144 109.085 143.838 103.09 143.838C97.0956 143.838 92.2361 145.144 92.2361 146.754C92.2361 148.365 97.0956 149.671 103.09 149.671Z'
          fill='#80A7FF'
        />
        <path
          d='M66.1359 158.851C69.6731 156.482 71.7349 153.358 70.7411 151.875C69.7472 150.391 66.074 151.109 62.5368 153.479C58.9996 155.849 56.9378 158.973 57.9316 160.456C58.9255 161.94 62.5987 161.221 66.1359 158.851Z'
          fill='#80A7FF'
        />
        <path
          d='M34.0982 164.484C38.3345 159.808 40.696 155.046 39.3728 153.847C38.0495 152.648 33.5427 155.466 29.3064 160.142C25.0701 164.817 22.7086 169.579 24.0318 170.778C25.355 171.977 29.8619 169.159 34.0982 164.484Z'
          fill='#80A7FF'
        />
        <path
          d='M77.4724 172.284C79.258 172.284 80.7056 170.836 80.7056 169.051C80.7056 167.265 79.258 165.817 77.4724 165.817C75.6867 165.817 74.2391 167.265 74.2391 169.051C74.2391 170.836 75.6867 172.284 77.4724 172.284Z'
          fill='#80A7FF'
        />
        <path
          d='M162.667 158.328C164.022 156.26 161.675 152.326 157.425 149.541C153.175 146.755 148.631 146.173 147.276 148.241C145.921 150.308 148.268 154.242 152.518 157.028C156.768 159.813 161.312 160.395 162.667 158.328Z'
          fill='#80A7FF'
        />
        <path
          d='M138.511 161.84C139.403 161.84 140.127 161.116 140.127 160.224C140.127 159.331 139.403 158.607 138.511 158.607C137.618 158.607 136.894 159.331 136.894 160.224C136.894 161.116 137.618 161.84 138.511 161.84Z'
          fill='#3E5FFF'
        />
        <path
          d='M155.109 188.349C157.325 187.616 158.213 184.274 157.092 180.884C155.971 177.495 153.265 175.341 151.049 176.074C148.833 176.807 147.945 180.149 149.066 183.539C150.187 186.928 152.892 189.082 155.109 188.349Z'
          fill='#80A7FF'
        />
        <path
          d='M180.987 173.061C182.352 172.045 181.731 168.899 179.599 166.035C177.466 163.171 174.631 161.674 173.265 162.691C171.9 163.708 172.521 166.853 174.654 169.717C176.786 172.581 179.621 174.078 180.987 173.061Z'
          fill='#80A7FF'
        />
        <path
          d='M181.465 181.931C182.267 181.539 182.281 179.921 181.496 178.317C180.712 176.713 179.426 175.73 178.624 176.122C177.822 176.515 177.807 178.133 178.592 179.737C179.376 181.341 180.662 182.324 181.465 181.931Z'
          fill='#80A7FF'
        />
        <path
          d='M109.117 181.732C109.633 181.732 110.05 181.343 110.05 180.862C110.05 180.381 109.633 179.991 109.117 179.991C108.602 179.991 108.184 180.381 108.184 180.862C108.184 181.343 108.602 181.732 109.117 181.732Z'
          fill='#3E5FFF'
        />
        <path
          d='M123.996 141.903C124.419 141.903 124.763 141.559 124.763 141.136C124.763 140.712 124.419 140.369 123.996 140.369C123.572 140.369 123.229 140.712 123.229 141.136C123.229 141.559 123.572 141.903 123.996 141.903Z'
          fill='#3E5FFF'
        />
        <path
          d='M64.591 138.304C65.6287 137.824 66.3573 137.191 66.2185 136.891C66.0797 136.591 65.1259 136.737 64.0882 137.216C63.0505 137.696 62.3219 138.329 62.4607 138.629C62.5996 138.929 63.5533 138.784 64.591 138.304Z'
          fill='#80A7FF'
        />
        <path
          d='M124.35 159.08C124.685 158.253 124.449 157.376 123.824 157.123C123.198 156.87 122.419 157.336 122.085 158.164C121.75 158.992 121.986 159.868 122.611 160.121C123.237 160.374 124.016 159.908 124.35 159.08Z'
          fill='#80A7FF'
        />
        <path
          d='M35.1488 183.277C35.5606 182.259 35.5848 181.308 35.2029 181.153C34.8209 180.999 34.1775 181.699 33.7657 182.717C33.3539 183.736 33.3296 184.686 33.7116 184.841C34.0935 184.995 34.737 184.295 35.1488 183.277Z'
          fill='#80A7FF'
        />
        <path
          d='M100.669 161.031C102.009 161.031 103.094 159.946 103.094 158.607C103.094 157.267 102.009 156.182 100.669 156.182C99.3301 156.182 98.2444 157.267 98.2444 158.607C98.2444 159.946 99.3301 161.031 100.669 161.031Z'
          fill='#3E5FFF'
        />
        <path
          d='M126.379 177.257C127.272 177.257 127.996 176.533 127.996 175.641C127.996 174.748 127.272 174.024 126.379 174.024C125.486 174.024 124.763 174.748 124.763 175.641C124.763 176.533 125.486 177.257 126.379 177.257Z'
          fill='#3E5FFF'
        />
        <path
          d='M64.4231 181.61C65.3159 181.61 66.0397 180.886 66.0397 179.993C66.0397 179.1 65.3159 178.376 64.4231 178.376C63.5303 178.376 62.8065 179.1 62.8065 179.993C62.8065 180.886 63.5303 181.61 64.4231 181.61Z'
          fill='#3E5FFF'
        />
        <path
          d='M46.8296 164.625C47.7225 164.625 48.4462 163.901 48.4462 163.008C48.4462 162.115 47.7225 161.392 46.8296 161.392C45.9368 161.392 45.213 162.115 45.213 163.008C45.213 163.901 45.9368 164.625 46.8296 164.625Z'
          fill='#3E5FFF'
        />
        <path
          d='M80.7054 142.752C81.5982 142.752 82.322 142.028 82.322 141.136C82.322 140.243 81.5982 139.519 80.7054 139.519C79.8125 139.519 79.0887 140.243 79.0887 141.136C79.0887 142.028 79.8125 142.752 80.7054 142.752Z'
          fill='#3E5FFF'
        />
        <path
          d='M161.799 170.212C162.692 170.212 163.416 169.488 163.416 168.596C163.416 167.703 162.692 166.979 161.799 166.979C160.906 166.979 160.182 167.703 160.182 168.596C160.182 169.488 160.906 170.212 161.799 170.212Z'
          fill='#3E5FFF'
        />
        <path
          d='M167.135 180.615C168.028 180.615 168.752 179.891 168.752 178.998C168.752 178.105 168.028 177.381 167.135 177.381C166.242 177.381 165.519 178.105 165.519 178.998C165.519 179.891 166.242 180.615 167.135 180.615Z'
          fill='#3E5FFF'
        />
        <path
          d='M92.2382 132.682C93.131 132.682 93.8548 131.959 93.8548 131.066C93.8548 130.173 93.131 129.449 92.2382 129.449C91.3454 129.449 90.6216 130.173 90.6216 131.066C90.6216 131.959 91.3454 132.682 92.2382 132.682Z'
          fill='#3E5FFF'
        />
        <path
          d='M170.552 62.6525C170.647 62.2057 171.28 62.2057 171.375 62.6525L172.189 66.5119C172.221 66.6657 172.337 66.7905 172.49 66.8311L175.583 67.6872C175.993 67.8003 175.993 68.3865 175.583 68.4997L172.49 69.3557C172.34 69.3963 172.224 69.5211 172.189 69.6749L171.375 73.5343C171.28 73.9811 170.647 73.9811 170.552 73.5343L169.738 69.6749C169.706 69.5211 169.59 69.3963 169.437 69.3557L166.344 68.4997C165.934 68.3865 165.934 67.8003 166.344 67.6872L169.437 66.8311C169.587 66.7905 169.703 66.6657 169.738 66.5119L170.552 62.6525Z'
          fill='#FFA300'
        />
        <path
          d='M82.0763 17.6623C82.1334 17.3958 82.5125 17.3958 82.5696 17.6623L83.0577 19.9643C83.0767 20.0561 83.146 20.1305 83.2377 20.1547L85.0914 20.6653C85.3372 20.7328 85.3372 21.0825 85.0914 21.15L83.2377 21.6606C83.1477 21.6848 83.0784 21.7592 83.0577 21.851L82.5696 24.153C82.5125 24.4195 82.1334 24.4195 82.0763 24.153L81.5882 21.851C81.5692 21.7592 81.4999 21.6848 81.4082 21.6606L79.5545 21.15C79.3087 21.0825 79.3087 20.7328 79.5545 20.6653L81.4082 20.1547C81.4982 20.1305 81.5674 20.0561 81.5882 19.9643L82.0763 17.6623Z'
          fill='#FFA300'
        />
        <path
          d='M125.933 57.7609C125.99 57.4944 126.369 57.4944 126.426 57.7609L126.914 60.063C126.933 60.1547 127.002 60.2291 127.094 60.2534L128.948 60.764C129.194 60.8315 129.194 61.1811 128.948 61.2486L127.094 61.7592C127.004 61.7834 126.935 61.8579 126.914 61.9496L126.426 64.2516C126.369 64.5182 125.99 64.5182 125.933 64.2516L125.445 61.9496C125.425 61.8579 125.356 61.7834 125.265 61.7592L123.411 61.2486C123.165 61.1811 123.165 60.8315 123.411 60.764L125.265 60.2534C125.355 60.2291 125.424 60.1547 125.445 60.063L125.933 57.7609Z'
          fill='#FFA300'
        />
        <path
          d='M183.79 118.585C183.849 118.31 184.24 118.31 184.299 118.585L184.806 120.967C184.826 121.063 184.899 121.139 184.991 121.166L186.911 121.695C187.166 121.764 187.166 122.128 186.911 122.198L184.991 122.727C184.899 122.754 184.826 122.83 184.806 122.926L184.299 125.308C184.24 125.583 183.849 125.583 183.79 125.308L183.283 122.926C183.263 122.83 183.19 122.754 183.098 122.727L181.178 122.198C180.923 122.128 180.923 121.764 181.178 121.695L183.098 121.166C183.19 121.139 183.263 121.063 183.283 120.967L183.79 118.585Z'
          fill='#3E5FFF'
        />
        <path
          d='M19.6262 108.451C19.699 108.098 20.1765 108.098 20.2493 108.451L20.8683 111.514C20.8926 111.637 20.9816 111.735 21.0949 111.769L23.4415 112.45C23.753 112.539 23.753 113.007 23.4415 113.096L21.0949 113.777C20.9816 113.811 20.8926 113.909 20.8683 114.032L20.2493 117.095C20.1765 117.448 19.699 117.448 19.6262 117.095L19.0072 114.032C18.9829 113.909 18.8939 113.811 18.7806 113.777L16.434 113.096C16.1224 113.007 16.1224 112.539 16.434 112.45L18.7806 111.769C18.8939 111.735 18.9829 111.637 19.0072 111.514L19.6262 108.451Z'
          fill='#FFA300'
        />
        <path
          d='M140.427 96.5391C140.496 96.2122 140.952 96.2122 141.022 96.5391L141.613 99.3751C141.636 99.4893 141.721 99.58 141.829 99.6115L144.069 100.242C144.366 100.324 144.366 100.758 144.069 100.84L141.829 101.471C141.721 101.502 141.636 101.593 141.613 101.707L141.022 104.543C140.952 104.87 140.496 104.87 140.427 104.543L139.836 101.707C139.813 101.593 139.728 101.502 139.62 101.471L137.38 100.84C137.082 100.758 137.082 100.324 137.38 100.242L139.62 99.6115C139.728 99.58 139.813 99.4893 139.836 99.3751L140.427 96.5391Z'
          fill='#FFA300'
        />
        <path
          d='M148.086 32.9834C148.152 32.6826 148.586 32.6826 148.652 32.9834L149.215 35.5925C149.237 35.6976 149.318 35.7809 149.421 35.8099L151.554 36.3897C151.838 36.4658 151.838 36.8644 151.554 36.9405L149.421 37.5203C149.318 37.5493 149.237 37.6327 149.215 37.7377L148.652 40.3468C148.586 40.6476 148.152 40.6476 148.086 40.3468L147.523 37.7377C147.501 37.6327 147.42 37.5493 147.317 37.5203L145.184 36.9405C144.901 36.8644 144.901 36.4658 145.184 36.3897L147.317 35.8099C147.42 35.7809 147.501 35.6976 147.523 35.5925L148.086 32.9834Z'
          fill='#3E5FFF'
        />
        <path
          d='M23.6704 169.594C23.6704 169.594 37.9274 162.034 38.9503 153.619C38.9503 153.619 43.8521 154.003 34.8413 164.416C25.8288 174.828 23.3849 170.931 23.6704 169.594Z'
          fill='#3E5FFF'
        />
        <path
          d='M57.753 158.991C57.753 158.991 61.2043 159.791 64.6435 157.364C68.0827 154.938 69.9347 153.302 70.1978 151.414C70.1978 151.414 74.2116 153.215 66.132 158.868C58.2809 164.36 57.2736 159.902 57.7547 158.991H57.753Z'
          fill='#3E5FFF'
        />
        <path
          d='M62.437 138.56C62.437 138.56 65.2617 138.129 66.1306 137.021C66.16 136.985 66.186 136.947 66.2102 136.909C66.2102 136.909 66.636 137.379 64.4499 138.38C62.2639 139.38 62.437 138.56 62.437 138.56Z'
          fill='#3E5FFF'
        />
        <path
          d='M33.442 183.998C33.442 183.998 34.8007 183.676 35.3926 182.385C35.4082 182.35 35.4169 182.314 35.4272 182.276C35.4272 182.276 35.0897 184.343 33.982 184.806C33.982 184.806 33.3225 185.092 33.442 183.998Z'
          fill='#3E5FFF'
        />
        <path
          d='M121.978 159.333C121.978 159.333 122.542 159.6 123.208 159.333C123.875 159.067 124.376 157.691 124.376 157.691C124.376 157.691 125.069 159.057 123.57 159.993C123.57 159.993 122.41 160.708 121.978 159.333Z'
          fill='#3E5FFF'
        />
        <path
          d='M74.3175 169.75C74.3175 169.75 78.1582 170.759 80.301 167.484C80.301 167.484 82.2153 170.728 78.449 172.135C74.9614 173.438 74.3175 169.752 74.3175 169.752V169.75Z'
          fill='#3E5FFF'
        />
        <path
          d='M109.046 144.317C109.046 144.317 114.076 149.39 97.1069 149.189C97.1069 149.189 100.987 149.93 106.014 149.565C110.164 149.264 113.765 148.644 113.943 146.756C114.121 144.867 109.045 144.317 109.045 144.317H109.046Z'
          fill='#3E5FFF'
        />
        <path
          d='M148.413 153.281C148.413 153.281 155.426 158.51 158.678 156.182C161.931 153.854 156.768 149.127 156.768 149.127C156.768 149.127 166.447 154.593 162.447 158.607C158.815 162.25 151.245 156.968 148.413 153.281Z'
          fill='#3E5FFF'
        />
        <path
          d='M151.568 175.974C151.568 175.974 156.596 177.386 153.071 188.308C153.071 188.308 158.487 190.255 157.735 182.44C157.521 180.216 156.605 175.181 151.57 175.974H151.568Z'
          fill='#3E5FFF'
        />
        <path
          d='M175.142 162.518C175.142 162.518 179.153 167.014 177.127 172.243C177.127 172.243 179.424 174.189 180.989 173.083C182.554 171.977 181.82 169.479 180.11 166.789C178.4 164.1 176.407 162.597 175.144 162.518H175.142Z'
          fill='#3E5FFF'
        />
        <path
          d='M180.278 176.611C180.278 176.611 180.501 179.043 178.306 178.998C178.306 178.998 179.667 183.175 181.648 181.785C183.407 180.552 181.271 177.504 180.279 176.611H180.278Z'
          fill='#3E5FFF'
        />
        <path
          d='M56.9919 116.616L57.3016 115.754C57.3842 115.782 59.4355 116.444 61.4522 113.777C62.5958 112.264 62.6579 110.894 62.7182 109.568C62.7793 108.23 62.8421 106.846 64.1375 105.642C67.7475 102.287 70.3779 104.406 70.4031 104.427L69.8293 105.139C69.7468 105.071 67.7469 103.515 64.7353 106.313C63.7196 107.257 63.6729 108.288 63.6148 109.596C63.5506 110.995 63.4783 112.582 62.1608 114.327C59.7245 117.548 57.1027 116.656 56.9911 116.618L56.9919 116.616Z'
          fill='white'
        />
        <path
          d='M64.1005 122.266L63.3622 121.765C63.4112 121.689 64.5859 119.9 66.0377 118.669C67.4433 117.478 67.8062 115.246 67.0597 112.383C66.7576 111.225 66.8738 110.316 67.4042 109.684C68.2579 108.665 69.8349 108.839 69.9026 108.847L69.7779 109.766C69.4545 109.729 68.5144 109.752 68.0746 110.281C67.7582 110.661 67.7075 111.28 67.9268 112.12C68.771 115.36 68.2987 117.938 66.5953 119.381C65.2471 120.521 64.1144 122.253 64.1017 122.269L64.1005 122.266Z'
          fill='white'
        />
        <path
          d='M45.9268 59.6039C45.7313 59.7556 45.4502 59.7208 45.2973 59.5264C45.1445 59.3321 45.1804 59.0499 45.3748 58.897C45.5692 58.7441 45.8514 58.7801 46.0042 58.9744C46.1559 59.17 46.1212 59.451 45.9268 59.6039Z'
          fill='#C9CCD4'
        />
        <path
          d='M36.9883 60.4847C36.305 60.964 35.0848 60.5542 34.2627 59.5699C33.4419 58.5844 33.3291 57.3955 34.0124 56.9163C34.6958 56.437 35.916 56.8468 36.7381 57.8311C37.5589 58.8166 37.6717 60.0055 36.9883 60.4847Z'
          fill='#C9CCD4'
        />
        <path
          d='M41.3691 59.2967C40.8821 59.6765 40.1782 59.5908 39.7973 59.1026C39.4175 58.6155 39.5032 57.9116 39.9915 57.5307C40.4785 57.1509 41.1824 57.2366 41.5633 57.7249C41.9431 58.2119 41.8574 58.9158 41.3691 59.2967Z'
          fill='#C9CCD4'
        />
        <path
          d='M41.8901 60.8671C41.4031 61.2468 40.6992 61.1612 40.3183 60.6729C39.9385 60.1858 40.0242 59.4819 40.5124 59.101C40.9995 58.7213 41.7034 58.8069 42.0843 59.2952C42.4641 59.7822 42.3784 60.4861 41.8901 60.8671Z'
          fill='#C9CCD4'
        />
        <path
          d='M42.2976 60.5604C41.8105 60.9402 41.1067 60.8545 40.7257 60.3662C40.346 59.8792 40.4316 59.1753 40.9199 58.7944C41.407 58.4146 42.1109 58.5003 42.4918 58.9886C42.8715 59.4756 42.7859 60.1795 42.2976 60.5604Z'
          fill='#C9CCD4'
        />
        <path
          d='M34.2021 58.0911C33.7162 58.433 32.848 58.1407 32.2626 57.4399C31.6772 56.7391 31.5977 55.8938 32.0836 55.5519C32.5694 55.21 33.4377 55.5023 34.0231 56.2031C34.6085 56.9039 34.688 57.7492 34.2021 58.0911Z'
          fill='#C9CCD4'
        />
        <path
          d='M32.9758 56.939C32.8649 56.8064 32.8496 56.6459 32.9417 56.5823C33.0337 56.5188 33.198 56.5736 33.3089 56.7062C33.4198 56.8389 33.435 56.9994 33.343 57.0629C33.2521 57.1277 33.0867 57.0716 32.9758 56.939Z'
          fill='#C9CCD4'
        />
        <path
          d='M31.7939 56.2449C31.6098 56.3744 31.2812 56.2647 31.0606 55.9983C30.8388 55.7331 30.8095 55.4133 30.9937 55.2838C31.1779 55.1543 31.5064 55.264 31.727 55.5304C31.9488 55.7957 31.9781 56.1155 31.7939 56.2449Z'
          fill='#C9CCD4'
        />
        <path
          d='M30.1377 54.4169C29.882 54.5975 29.4244 54.4435 29.1157 54.0737C28.8071 53.7038 28.7651 53.2579 29.0219 53.0785C29.2776 52.8979 29.7352 53.0519 30.0439 53.4218C30.3526 53.7916 30.3945 54.2375 30.1377 54.4169Z'
          fill='#C9CCD4'
        />
        <path
          d='M31.3998 55.162C31.1441 55.3426 30.6865 55.1886 30.3778 54.8188C30.0692 54.4489 30.0271 54.0031 30.284 53.8237C30.5397 53.643 30.9972 53.797 31.3059 54.1669C31.6146 54.5367 31.6566 54.9826 31.3998 55.162Z'
          fill='#C9CCD4'
        />
        <path
          d='M51.4831 77.8935C51.4831 77.8935 51.4821 77.8876 51.4798 77.8851L27.587 51.2256C27.587 51.2256 34.0625 60.8396 34.3443 63.084C34.6262 65.3284 38.9904 73.6707 42.1998 72.1525C42.7159 71.9088 43.4433 72.039 44.268 72.3975C44.3 72.2041 44.3846 72.0372 44.5235 71.9158C44.9826 71.5186 45.8667 71.7591 46.4997 72.4543C46.9836 72.9861 47.1795 73.6299 47.0387 74.0819C47.2984 74.2725 47.5544 74.4689 47.8079 74.6675C47.8255 74.6325 47.849 74.6012 47.8795 74.5749C48.0719 74.4077 48.4446 74.5081 48.7118 74.802C48.9457 75.0581 49.0189 75.3757 48.9053 75.5631C49.3917 75.9752 49.8406 76.3719 50.2269 76.724C50.327 76.7081 50.458 76.7643 50.5577 76.8752C50.6277 76.9521 50.6688 77.0399 50.6783 77.1184C50.741 77.1215 50.8146 77.1582 50.8742 77.224C50.927 77.2802 50.9557 77.3474 50.9576 77.4032C51.0751 77.5135 51.1787 77.6139 51.2674 77.6984C51.2899 77.6979 51.3191 77.7119 51.3409 77.7374C51.3592 77.757 51.3692 77.7798 51.371 77.7988C51.4025 77.8152 51.4338 77.8387 51.4601 77.8691C51.4681 77.8777 51.4762 77.8862 51.4818 77.8971L51.4831 77.8935Z'
          fill='#C9CCD4'
        />
        <path
          d='M33.4441 60.5455C34.0713 60.0017 33.8786 58.7889 33.0141 57.8377C32.1495 56.8865 30.9387 56.5552 30.3114 57.1014C29.6842 57.6452 29.8768 58.8579 30.7414 59.8091C31.6059 60.7604 32.8168 61.0916 33.4441 60.5455Z'
          fill='#C9CCD4'
        />
        <path
          d='M34.3923 62.4474C34.6943 62.1864 34.6009 61.6026 34.1846 61.1453C33.7684 60.6879 33.1865 60.529 32.8856 60.7912C32.5836 61.0522 32.677 61.6359 33.0933 62.0933C33.5095 62.5506 34.0914 62.7096 34.3923 62.4474Z'
          fill='#C9CCD4'
        />
        <path
          d='M35.8905 64.6619C36.3525 64.2505 36.392 63.5417 35.9795 63.0809C35.5681 62.619 34.8592 62.5794 34.3985 62.9919C33.9377 63.4045 33.8969 64.1122 34.3095 64.573C34.7209 65.0349 35.4297 65.0745 35.8905 64.6619Z'
          fill='#C9CCD4'
        />
        <path
          d='M35.4687 63.589C35.7707 63.328 35.6773 62.7442 35.261 62.2869C34.8448 61.8295 34.2629 61.6706 33.962 61.9328C33.66 62.1938 33.7535 62.7775 34.1697 63.2349C34.586 63.6922 35.1678 63.8512 35.4687 63.589Z'
          fill='#C9CCD4'
        />
        <path
          d='M31.4929 57.4965C31.9387 57.1095 31.8029 56.2459 31.1871 55.5691C30.5714 54.8922 29.7109 54.6571 29.2639 55.0452C28.8169 55.4333 28.9539 56.2958 29.5697 56.9727C30.1855 57.6495 31.0459 57.8846 31.4929 57.4965Z'
          fill='#C9CCD4'
        />
        <path
          d='M30.0375 54.9065C30.2056 54.7599 30.154 54.4335 29.9224 54.1775C29.6885 53.9213 29.364 53.8331 29.1947 53.9785C29.0266 54.125 29.0782 54.4514 29.3098 54.7075C29.5438 54.9636 29.8682 55.0518 30.0375 54.9065Z'
          fill='#C9CCD4'
        />
        <path
          d='M43.544 64.2164C42.5299 65.0067 41.0666 64.8277 40.2751 63.8123C39.4848 62.7982 39.665 61.3338 40.6792 60.5434C41.6933 59.7531 43.1577 59.9333 43.9481 60.9475C44.7384 61.9617 44.5582 63.4261 43.544 64.2164Z'
          fill='#C9CCD4'
        />
        <path
          d='M42.5735 62.7137C43.1136 62.7805 43.6055 62.3967 43.6722 61.8567C43.7389 61.3166 43.3552 60.8247 42.8151 60.758C42.2751 60.6913 41.7832 61.075 41.7165 61.6151C41.6498 62.1551 42.0335 62.647 42.5735 62.7137Z'
          fill='#C9CCD4'
        />
        <path
          d='M41.1855 61.3229C40.464 61.8863 39.4225 61.757 38.859 61.0355C38.2956 60.314 38.4249 59.2725 39.1464 58.7091C39.8679 58.1456 40.9094 58.2749 41.4729 58.9964C42.0363 59.7179 41.907 60.7595 41.1855 61.3229Z'
          fill='#C9CCD4'
        />
        <path
          d='M38.5822 59.6602C38.3089 59.8735 37.9157 59.8247 37.7024 59.5514C37.4891 59.2781 37.538 58.8849 37.8113 58.6716C38.0846 58.4583 38.4778 58.5072 38.6911 58.7804C38.9043 59.0537 38.8555 59.447 38.5822 59.6602Z'
          fill='#C9CCD4'
        />
        <path
          d='M32.6143 57.3806C32.7824 57.2341 32.7308 56.9077 32.4992 56.6516C32.2652 56.3955 31.9408 56.3072 31.7715 56.4526C31.6034 56.5991 31.655 56.9255 31.8866 57.1816C32.1205 57.4377 32.445 57.526 32.6143 57.3806Z'
          fill='#C9CCD4'
        />
        <path
          d='M51.6367 77.3274C51.6367 77.3274 51.6315 77.3266 51.6294 77.3246L33.8679 58.8588C33.4975 58.474 34.0075 57.8866 34.4407 58.2C36.6616 59.8111 39.0859 61.4464 40.0725 61.6775C42.0885 62.1498 49.2047 66.6289 47.453 69.0963C47.1714 69.4933 47.208 70.1041 47.4403 70.8181C47.2596 70.823 47.0979 70.8738 46.971 70.9751C46.555 71.3083 46.6748 72.059 47.2381 72.6525C47.6687 73.1077 48.2345 73.3372 48.6624 73.2714C48.8065 73.5044 48.9557 73.7363 49.1101 73.9648C49.0763 73.9753 49.0448 73.9918 49.0176 74.0144C48.8424 74.1546 48.8931 74.4703 49.1297 74.7199C49.3375 74.9384 49.6187 75.0336 49.8034 74.9603C50.123 75.4037 50.4353 75.8142 50.7116 76.169C50.6863 76.2494 50.7221 76.363 50.812 76.4572C50.8742 76.5234 50.95 76.5664 51.0198 76.5816C51.0153 76.6335 51.0412 76.6978 51.0937 76.7529C51.1397 76.802 51.1966 76.8333 51.2475 76.8409C51.3358 76.9496 51.4144 77.0453 51.4823 77.1268C51.4798 77.1465 51.4889 77.171 51.5082 77.191C51.5252 77.207 51.5432 77.2178 51.5609 77.2224C51.5711 77.25 51.5896 77.2773 51.6142 77.3044C51.6217 77.3114 51.6292 77.3184 51.6366 77.3254L51.6367 77.3274Z'
          fill='white'
        />
        <path
          d='M37.8573 60.6647C37.2892 61.1197 36.2045 60.8307 35.4345 60.0197C34.6655 59.2076 34.5014 58.1792 35.0695 57.7242C35.6377 57.2693 36.7223 57.5582 37.4923 58.3692C38.2613 59.1813 38.4254 60.2098 37.8573 60.6647Z'
          fill='white'
        />
        <path
          d='M36.0291 59.5431C35.7035 59.2003 35.6344 58.7652 35.8751 58.5723C36.1159 58.3793 36.5748 58.5027 36.9004 58.8456C37.2259 59.1884 37.294 59.6245 37.0543 59.8164C36.8145 60.0083 36.3546 59.886 36.0291 59.5431Z'
          fill='white'
        />
        <path
          d='M35.2994 58.7358C34.8956 59.0603 34.1238 58.8541 33.5754 58.2767C33.0271 57.6992 32.9111 56.968 33.315 56.6435C33.7188 56.3191 34.4907 56.5253 35.039 57.1027C35.5873 57.6801 35.7033 58.4113 35.2994 58.7358Z'
          fill='white'
        />
        <path
          d='M34.1688 57.8012C34.0649 57.6919 34.0428 57.5531 34.1194 57.4927C34.196 57.4323 34.3419 57.4709 34.4458 57.5801C34.5497 57.6894 34.5718 57.8282 34.4953 57.8887C34.4198 57.9501 34.2727 57.9104 34.1688 57.8012Z'
          fill='white'
        />
        <path
          d='M33.102 57.2628C32.9489 57.3857 32.6569 57.3084 32.4502 57.0888C32.2424 56.8703 32.1992 56.5936 32.3523 56.4707C32.5054 56.3478 32.7974 56.4251 33.0041 56.6447C33.2119 56.8632 33.2551 57.1399 33.102 57.2628Z'
          fill='white'
        />
        <path
          d='M33.102 57.2628C32.9489 57.3857 32.6569 57.3084 32.4502 57.0888C32.2424 56.8703 32.1992 56.5936 32.3523 56.4707C32.5054 56.3478 32.7974 56.4251 33.0041 56.6447C33.2119 56.8632 33.2551 57.1399 33.102 57.2628Z'
          fill='white'
        />
        <path
          d='M51.4372 75.0105C51.4372 75.0105 51.436 75.0054 51.4339 75.0034L34.8309 58.6953C34.2487 58.1241 33.3399 58.8746 33.7893 59.5556C34.7826 61.0602 35.5747 62.4163 35.7007 63.0728C36.0704 65.0104 40.3311 72.0283 43.04 70.5291C43.4755 70.2884 44.1158 70.3614 44.8534 70.6276C44.8706 70.4575 44.9349 70.3076 45.049 70.1942C45.4266 69.8231 46.2093 69.9834 46.7987 70.5533C47.2493 70.9892 47.4555 71.5386 47.3581 71.9399C47.5946 72.0913 47.8283 72.248 48.0599 72.4068C48.0733 72.3753 48.092 72.3468 48.1171 72.3222C48.2753 72.1661 48.6052 72.2328 48.854 72.4737C49.0718 72.6836 49.1531 72.956 49.0646 73.1253C49.5108 73.457 49.9235 73.7773 50.2792 74.0623C50.3654 74.0429 50.4825 74.0846 50.5755 74.1756C50.6406 74.2386 50.6813 74.3127 50.6938 74.3805C50.7486 74.3798 50.8146 74.4076 50.8702 74.4616C50.9192 74.5075 50.948 74.5645 50.9527 74.6129C51.0611 74.7024 51.1568 74.784 51.2387 74.8526C51.2583 74.851 51.2845 74.8615 51.3048 74.8825C51.3219 74.8985 51.3318 74.9178 51.3344 74.9343C51.3627 74.9468 51.3913 74.9655 51.4159 74.9905C51.4233 74.9975 51.4308 75.0045 51.4363 75.0136L51.4372 75.0105Z'
          fill='white'
        />
        <path
          d='M34.7759 60.9138C35.2916 60.4058 35.0567 59.3611 34.2515 58.5812C33.4464 57.8013 32.3743 57.5802 31.8586 58.0903C31.3429 58.5983 31.5779 59.643 32.383 60.4229C33.1882 61.2028 34.2603 61.4239 34.7759 60.9138Z'
          fill='white'
        />
        <path
          d='M33.7124 59.1113C33.3718 58.7824 32.9179 58.6877 32.6997 58.9038C32.4824 59.1187 32.5807 59.561 32.9223 59.8909C33.263 60.2199 33.7168 60.3145 33.9351 60.0985C34.1523 59.8836 34.054 59.4412 33.7124 59.1113Z'
          fill='white'
        />
        <path
          d='M35.7065 62.5166C35.9549 62.2727 35.8412 61.7699 35.4536 61.395C35.066 61.02 34.5507 60.914 34.3034 61.1588C34.0551 61.4027 34.1688 61.9056 34.5564 62.2805C34.944 62.6554 35.4592 62.7615 35.7065 62.5166Z'
          fill='white'
        />
        <path
          d='M35.7065 62.5166C35.9549 62.2727 35.8412 61.7699 35.4536 61.395C35.066 61.02 34.5507 60.914 34.3034 61.1588C34.0551 61.4027 34.1688 61.9056 34.5564 62.2805C34.944 62.6554 35.4592 62.7615 35.7065 62.5166Z'
          fill='white'
        />
        <path
          d='M36.7066 63.4507C36.955 63.2068 36.8413 62.704 36.4537 62.3291C36.0661 61.9541 35.5509 61.848 35.3036 62.0929C35.0552 62.3368 35.1689 62.8396 35.5565 63.2146C35.9441 63.5895 36.4594 63.6956 36.7066 63.4507Z'
          fill='white'
        />
        <path
          d='M36.7066 63.4507C36.955 63.2068 36.8413 62.704 36.4537 62.3291C36.0661 61.9541 35.5509 61.848 35.3036 62.0929C35.0552 62.3368 35.1689 62.8396 35.5565 63.2146C35.9441 63.5895 36.4594 63.6956 36.7066 63.4507Z'
          fill='white'
        />
        <path
          d='M32.9086 58.369C33.2751 58.0075 33.109 57.2635 32.5356 56.7086C31.9622 56.1537 31.2003 55.9968 30.8328 56.3593C30.4653 56.7219 30.6324 57.4649 31.2058 58.0198C31.7792 58.5747 32.5411 58.7316 32.9086 58.369Z'
          fill='white'
        />
        <path
          d='M31.9966 57.2393C31.8887 57.1343 31.7441 57.1049 31.6739 57.1734C31.6038 57.2418 31.6364 57.3824 31.7442 57.4874C31.8521 57.5924 31.9968 57.6218 32.0669 57.5533C32.137 57.4848 32.1045 57.3443 31.9966 57.2393Z'
          fill='white'
        />
        <path
          d='M54.6266 80.1412L51.3726 76.7581L50.3278 75.6719L49.8654 75.1908L49.6663 74.9844L49.3912 74.6988L48.9139 74.2038L47.8745 73.1225L46.8297 72.0363L40.0925 65.0316L36.3353 61.1254L34.4824 59.1983C34.4824 59.1983 34.8836 59.5159 35.5306 60.0136C36.2678 60.5817 37.3228 61.3834 38.4627 62.2184C39.3239 62.8486 40.2333 63.4979 41.0898 64.0776C42.5553 65.0694 43.8704 65.8605 44.5331 66.0165C45.1801 66.1678 46.3492 66.7321 47.5822 67.5062L47.5833 67.5072C50.2003 69.1548 53.1041 71.7596 51.9135 73.4353C51.6319 73.8322 51.6686 74.443 51.9009 75.157C51.8203 75.1596 51.7432 75.1715 51.6705 75.1935C51.6296 75.2052 51.5897 75.2199 51.5532 75.2397C51.5094 75.2608 51.4688 75.2849 51.4326 75.315C51.2644 75.4498 51.1842 75.653 51.1857 75.8866C51.1859 76.143 51.2844 76.4354 51.4735 76.7145C51.5386 76.8095 51.6131 76.9032 51.6977 76.9925C52.1282 77.4476 52.694 77.6772 53.1209 77.6104C53.2661 77.8444 53.4152 78.0742 53.5676 78.3049C53.5328 78.3164 53.5012 78.3308 53.4741 78.3534C53.2988 78.4936 53.3495 78.8093 53.5872 79.06C53.794 79.2796 54.0741 79.3737 54.2599 79.3015C54.377 79.4651 54.4949 79.6235 54.6095 79.7769L54.6224 80.1393L54.6266 80.1412Z'
          fill='white'
        />
        <path
          d='M36.9077 60.8375C36.6891 61.0132 36.2719 60.9008 35.9764 60.5901C35.6807 60.2772 35.6181 59.8823 35.8357 59.7077C36.0542 59.532 36.4714 59.6444 36.767 59.9551C37.0626 60.268 37.1252 60.6629 36.9077 60.8375Z'
          fill='white'
        />
        <path
          d='M36.9077 60.8375C36.6891 61.0132 36.2719 60.9008 35.9764 60.5901C35.6807 60.2772 35.6181 59.8823 35.8357 59.7077C36.0542 59.532 36.4714 59.6444 36.767 59.9551C37.0626 60.268 37.1252 60.6629 36.9077 60.8375Z'
          fill='white'
        />
        <path
          d='M39.199 59.8583C38.973 60.059 38.628 60.0383 38.4273 59.8123C38.2265 59.5863 38.2472 59.2414 38.4732 59.0406C38.6992 58.8399 39.0441 58.8606 39.2449 59.0866C39.4457 59.3126 39.425 59.6575 39.199 59.8583Z'
          fill='white'
        />
        <path
          d='M39.199 59.8583C38.973 60.059 38.628 60.0383 38.4273 59.8123C38.2265 59.5863 38.2472 59.2414 38.4732 59.0406C38.6992 58.8399 39.0441 58.8606 39.2449 59.0866C39.4457 59.3126 39.425 59.6575 39.199 59.8583Z'
          fill='white'
        />
        <path
          d='M35.2414 59.4864C35.0883 59.6093 34.7963 59.5321 34.5896 59.3125C34.3818 59.0939 34.3386 58.8173 34.4917 58.6944C34.6448 58.5715 34.9368 58.6487 35.1436 58.8683C35.3513 59.0868 35.3945 59.3635 35.2414 59.4864Z'
          fill='white'
        />
        <path
          d='M35.2414 59.4864C35.0883 59.6093 34.7963 59.5321 34.5896 59.3125C34.3818 59.0939 34.3386 58.8173 34.4917 58.6944C34.6448 58.5715 34.9368 58.6487 35.1436 58.8683C35.3513 59.0868 35.3945 59.3635 35.2414 59.4864Z'
          fill='white'
        />
        <path
          d='M36.0203 60.1057C35.8078 60.2771 35.401 60.1685 35.1119 59.8637C34.8227 59.5589 34.7615 59.1733 34.975 59.0029C35.1875 58.8316 35.5943 58.9402 35.8834 59.2449C36.1726 59.5497 36.2339 59.9354 36.0203 60.1057Z'
          fill='white'
        />
        <path
          d='M36.0203 60.1057C35.8078 60.2771 35.401 60.1685 35.1119 59.8637C34.8227 59.5589 34.7615 59.1733 34.975 59.0029C35.1875 58.8316 35.5943 58.9402 35.8834 59.2449C36.1726 59.5497 36.2339 59.9354 36.0203 60.1057Z'
          fill='white'
        />
        <path
          d='M37.1611 60.6834C36.9486 60.8547 36.5418 60.7461 36.2526 60.4414C35.9635 60.1366 35.9022 59.7509 36.1158 59.5806C36.3293 59.4102 36.735 59.5178 37.0242 59.8226C37.3133 60.1273 37.3746 60.513 37.1611 60.6834Z'
          fill='white'
        />
        <path
          d='M37.1611 60.6834C36.9486 60.8547 36.5418 60.7461 36.2526 60.4414C35.9635 60.1366 35.9022 59.7509 36.1158 59.5806C36.3293 59.4102 36.735 59.5178 37.0242 59.8226C37.3133 60.1273 37.3746 60.513 37.1611 60.6834Z'
          fill='white'
        />
        <path
          d='M54.5541 78.2537C54.2442 78.0075 53.8964 77.7419 53.5275 77.4666C53.615 77.2963 53.5347 77.0249 53.3168 76.8129C53.0681 76.574 52.7403 76.5073 52.581 76.6624C52.5559 76.687 52.5362 76.7146 52.5238 76.747C52.2922 76.5882 52.0575 76.4305 51.822 76.2801C51.8948 75.9799 51.7975 75.5986 51.5552 75.2416C51.4737 75.1213 51.3737 75.0036 51.2605 74.8936C50.6732 74.3236 49.8905 74.1633 49.5119 74.5335C49.4628 74.5816 49.4223 74.6377 49.3912 74.6987C49.362 74.7555 49.3413 74.8161 49.3289 74.8806C49.3252 74.8932 49.3226 74.9087 49.321 74.9233C49.3184 74.9368 49.3158 74.9524 49.3152 74.9659C48.6645 74.7329 48.0921 74.6474 47.6669 74.7957C47.6097 74.8162 47.5536 74.8398 47.5028 74.8683C44.9554 76.2785 41.0363 70.1549 40.2696 67.8096C40.2213 67.6603 40.1849 67.5281 40.1635 67.412C40.0961 67.0607 39.8392 66.5098 39.4617 65.8441C38.8821 64.819 38.0206 63.5199 37.1353 62.2526C36.8695 61.8716 36.5995 61.4908 36.3352 61.1231C36.0589 60.7363 35.7892 60.3637 35.5316 60.0124C34.801 59.0161 34.1827 58.1969 33.8618 57.7743C33.8618 57.7743 33.8618 57.7743 33.8607 57.7733C33.7105 57.5767 33.6252 57.4668 33.6252 57.4668L34.1637 57.997L38.4626 62.2183L40.6986 64.4139L47.2422 70.843L51.6725 75.1933L54.5463 78.0162L54.5561 78.2515L54.5541 78.2537Z'
          fill='white'
        />
        <path
          d='M35.1965 59.8665C35.3958 59.6707 35.305 59.2695 34.9943 58.9685C34.6846 58.6686 34.2714 58.5839 34.0742 58.7796C33.8749 58.9754 33.9657 59.3766 34.2764 59.6776C34.5861 59.9775 34.9993 60.0621 35.1965 59.8665Z'
          fill='white'
        />
        <path
          d='M35.1965 59.8665C35.3958 59.6707 35.305 59.2695 34.9943 58.9685C34.6846 58.6686 34.2714 58.5839 34.0742 58.7796C33.8749 58.9754 33.9657 59.3766 34.2764 59.6776C34.5861 59.9775 34.9993 60.0621 35.1965 59.8665Z'
          fill='white'
        />
        <path
          d='M37.3714 62.7103C37.7379 62.3488 37.5718 61.6048 36.9984 61.0499C36.425 60.495 35.663 60.3381 35.2956 60.7006C34.9281 61.0632 35.0952 61.8062 35.6686 62.3611C36.242 62.916 37.0039 63.0729 37.3714 62.7103Z'
          fill='white'
        />
        <path
          d='M37.7685 60.5445C37.898 60.4669 37.94 60.2991 37.8624 60.1697C37.7848 60.0402 37.617 59.9982 37.4875 60.0758C37.3581 60.1534 37.3161 60.3212 37.3937 60.4507C37.4713 60.5801 37.6391 60.6221 37.7685 60.5445Z'
          fill='white'
        />
        <path
          d='M35.9606 60.5377C36.0987 60.4008 36.0357 60.1196 35.82 59.9096C35.6022 59.6997 35.3149 59.6409 35.1757 59.7768C35.0365 59.9126 35.1005 60.1948 35.3163 60.4048C35.5341 60.6148 35.8213 60.6736 35.9606 60.5377Z'
          fill='white'
        />
        <path
          d='M35.9606 60.5377C36.0987 60.4008 36.0357 60.1196 35.82 59.9096C35.6022 59.6997 35.3149 59.6409 35.1757 59.7768C35.0365 59.9126 35.1005 60.1948 35.3163 60.4048C35.5341 60.6148 35.8213 60.6736 35.9606 60.5377Z'
          fill='white'
        />
        <path
          d='M33.8781 58.2061C34.0163 58.0692 33.9533 57.788 33.7375 57.578C33.5197 57.3681 33.2325 57.3093 33.0932 57.4452C32.9551 57.5821 33.0181 57.8632 33.2338 58.0732C33.4516 58.2832 33.7389 58.342 33.8781 58.2061Z'
          fill='white'
        />
        <path
          d='M33.8781 58.2061C34.0163 58.0692 33.9533 57.788 33.7375 57.578C33.5197 57.3681 33.2325 57.3093 33.0932 57.4452C32.9551 57.5821 33.0181 57.8632 33.2338 58.0732C33.4516 58.2832 33.7389 58.342 33.8781 58.2061Z'
          fill='white'
        />
        <path
          d='M34.4808 58.9822C34.674 58.7908 34.5857 58.3998 34.2835 58.1069C33.9813 57.8139 33.5795 57.732 33.3852 57.9224C33.192 58.1138 33.2803 58.5048 33.5825 58.7978C33.8847 59.0907 34.2865 59.1726 34.4808 58.9822Z'
          fill='white'
        />
        <path
          d='M34.4808 58.9822C34.674 58.7908 34.5857 58.3998 34.2835 58.1069C33.9813 57.8139 33.5795 57.732 33.3852 57.9224C33.192 58.1138 33.2803 58.5048 33.5825 58.7978C33.8847 59.0907 34.2865 59.1726 34.4808 58.9822Z'
          fill='white'
        />
        <path
          d='M35.0176 60.0974C35.2109 59.906 35.1225 59.5151 34.8203 59.2221C34.5181 58.9291 34.1164 58.8472 33.9221 59.0377C33.7289 59.2291 33.8172 59.62 34.1194 59.913C34.4216 60.206 34.8234 60.2879 35.0176 60.0974Z'
          fill='white'
        />
        <path
          d='M35.0176 60.0974C35.2109 59.906 35.1225 59.5151 34.8203 59.2221C34.5181 58.9291 34.1164 58.8472 33.9221 59.0377C33.7289 59.2291 33.8172 59.62 34.1194 59.913C34.4216 60.206 34.8234 60.2879 35.0176 60.0974Z'
          fill='white'
        />
        <path
          d='M58.1464 82.3389C53.0954 80.3676 49.2381 76.8005 46.1596 73.197C46.838 73.4968 48.7889 73.9076 49.5147 74.1799C37.1381 60.562 51.8097 75.3669 51.3644 74.1799C51.92 74.5249 41.2154 61.0485 42.9608 62.8531C44.2193 64.1542 48.125 68.1157 49.9259 69.9585C49.0986 67.7072 49.071 67.2007 48.6812 66.0519C52.4147 68.7645 56.9528 73.4563 60.1741 79.3298L58.1464 82.3389Z'
          fill='#FFA300'
        />
        <path
          d='M66.097 80.385C65.2978 79.5588 63.0765 77.1667 63.4239 76.1873C63.672 75.4877 66.922 72.767 67.2719 72.6124C68.5012 72.0668 69.8078 73.781 70.4325 74.4268L66.097 80.385Z'
          fill='#AA0000'
        />
        <path
          d='M67.5312 77.9868L70.423 74.4177C69.7983 73.7719 68.4916 72.0577 67.2623 72.6033C66.9786 72.7307 64.682 74.6395 63.7613 75.6955C64.8641 76.3385 67.3732 77.9191 67.5312 77.9868Z'
          fill='#E60000'
        />
        <path
          d='M64.9314 81.5127C64.1322 80.6864 61.8345 78.3683 60.844 78.6828C60.1366 78.9075 57.3092 82.0651 57.1431 82.4097C56.5568 83.6201 58.2266 84.9831 58.8512 85.6289L64.9314 81.5127Z'
          fill='#AA0000'
        />
        <path
          d='M80.6354 76.1028C80.6354 76.1028 78.179 59.9486 80.3662 61.2155C87.8267 65.544 96.106 75.882 97.8756 79.3177C99.6451 82.7534 99.21 90.5642 99.21 90.5642C99.21 90.5642 80.5773 80.479 80.6354 76.1028Z'
          fill='#FFFCD9'
        />
        <path
          d='M70.122 74.0287C72.5925 71.8598 75.2482 73.133 80.0897 75.7482C85.2879 78.5638 95.3194 85.7318 102.789 94.5787C103.424 95.3309 101.986 96.8128 102.6 97.6097C103.347 98.5775 104.806 97.4602 105.513 98.4944C116.756 114.937 121.162 132.017 117.97 135.104L117.904 135.169C115.572 137.424 97.7876 133.283 81.7286 121.499C70.1049 112.962 62.9795 101.618 59.8413 95.3334C57.3888 90.4076 56.2141 87.7017 58.4546 85.314C58.4546 85.314 62.1308 83.3208 65.1695 80.3816C68.2081 77.4424 70.122 74.0287 70.122 74.0287Z'
          fill='#FF4436'
        />
        <path
          d='M81.7378 121.489C97.6028 133.13 115.152 137.314 117.818 135.232C117.781 135.175 117.754 135.109 117.718 135.053C107.955 113.907 87.1775 88.0276 67.411 77.9176C67.2868 78.0378 65.3029 80.2509 65.1786 80.371C62.14 83.3102 58.4638 85.3034 58.4638 85.3034C56.2138 87.7003 57.3979 90.397 59.8505 95.3228C62.9795 101.598 70.1045 112.961 81.7378 121.489Z'
          fill='#E60000'
        />
        <path
          d='M75.6585 76.0781L76.5076 76.4181L76.8477 76.7577L75.6585 76.4181L73.9597 77.777L75.6585 76.0781Z'
          fill='#141827'
        />
        <path
          d='M66.0334 105.465C67.2196 107.112 68.5545 108.818 70.0203 110.543C70.0203 110.543 76.9927 106.814 83.3949 100.621C89.7972 94.4289 95.2364 86.6861 95.2364 86.6861C93.4689 85.05 91.7076 83.5733 89.9901 82.2565C89.9901 82.2565 84.6483 89.8132 78.3699 95.9043C72.7222 101.385 66.0706 105.485 66.0334 105.465Z'
          fill='#FFA300'
        />
        <path
          d='M87.4922 103.441C83.1595 107.631 83.0238 114.52 87.1891 118.826C91.3545 123.133 98.2436 123.226 102.576 119.035C106.909 114.845 107.045 107.956 102.879 103.65C98.7141 99.3434 91.825 99.2498 87.4922 103.441Z'
          fill='#AA0000'
        />
        <path
          d='M90.5058 106.557C87.904 109.073 87.8225 113.21 90.3238 115.796C92.825 118.382 96.9619 118.438 99.5636 115.921C102.165 113.405 102.247 109.268 99.7456 106.682C97.2444 104.097 93.1076 104.04 90.5058 106.557Z'
          fill='#FFFCD9'
        />
        <path
          d='M100.929 108.014C100.792 108.17 100.657 108.328 100.519 108.483L100.107 108.948C99.8319 109.26 99.555 109.568 99.2751 109.874L99.2596 109.892L99.2336 109.898L98.2717 110.132L97.3085 110.362C96.6665 110.513 96.0232 110.66 95.3794 110.805L95.4433 110.769C95.0861 111.137 94.7238 111.5 94.3631 111.863C94.0026 112.228 93.6397 112.589 93.276 112.95L93.2519 112.974L93.2224 112.982C92.4966 113.16 91.7699 113.335 91.0413 113.502C90.6782 113.588 90.3125 113.669 89.9479 113.749C89.5836 113.83 89.2181 113.912 88.8523 113.987C89.209 113.876 89.5684 113.771 89.9266 113.666C90.2845 113.56 90.6439 113.455 91.003 113.354C91.7218 113.148 92.4424 112.95 93.164 112.755L93.1104 112.787C93.4661 112.418 93.8215 112.048 94.182 111.683C94.5407 111.317 94.8982 110.95 95.2604 110.587L95.2874 110.56L95.324 110.551C95.9688 110.41 96.6127 110.27 97.259 110.135L98.2283 109.935L99.1988 109.739L99.1569 109.762C99.4487 109.468 99.7432 109.174 100.04 108.885L100.484 108.448C100.631 108.302 100.782 108.16 100.931 108.016L100.929 108.014Z'
          fill='#141827'
        />
        <path
          d='M95.3644 110.565L95.0246 110.905L94.515 110.395L91.627 109.885L94.8547 110.055L95.3644 110.565Z'
          fill='#141827'
        />
        <path
          d='M94.3314 112.526L95.791 112.563L95.9786 111.686C95.9786 111.686 96.6106 111.73 96.5606 111.536C96.5106 111.342 97.088 110.363 97.088 110.363L95.9223 110.664L94.3291 112.524L94.3314 112.526Z'
          fill='#141827'
        />
        <path
          d='M94.1748 113.113L89.078 113.962L93.1555 112.773L95.1941 110.564L98.7616 109.545L100.8 108.186L99.2716 109.885L96.2137 110.564L94.1748 113.113Z'
          fill='#141827'
        />
        <path
          d='M92.3067 114.472L92.6468 113.283L92.9866 113.113L92.4769 114.812L91.7974 114.472L90.4384 115.661L91.7974 114.132L92.3067 114.472Z'
          fill='#141827'
        />
        <path
          d='M76.1597 82.492C75.9038 82.7855 75.649 83.0833 75.3904 83.3751L74.6143 84.2504C74.0975 84.8362 73.5768 85.4159 73.0504 85.9923L73.0212 86.0259L72.9723 86.0385L71.1631 86.4786L69.3515 86.9098C68.1439 87.1949 66.9341 87.4711 65.7231 87.7429L65.8433 87.6763C65.1716 88.3684 64.4903 89.051 63.8118 89.7354C63.1339 90.4219 62.4515 91.1001 61.7675 91.7811L61.7222 91.8259L61.6667 91.8402C60.3016 92.1754 58.9347 92.5039 57.5644 92.8191C56.8815 92.9808 56.1936 93.132 55.5078 93.2826C54.8227 93.4355 54.1353 93.5889 53.4472 93.7306C54.1181 93.5222 54.794 93.3244 55.4677 93.1271C56.1409 92.9276 56.8168 92.7297 57.4923 92.5391C58.8442 92.1528 60.1996 91.7799 61.5568 91.4136L61.456 91.4727C62.1249 90.779 62.7933 90.0831 63.4712 89.3966C64.1457 88.7062 64.818 88.0163 65.4993 87.3337L65.5501 87.2827L65.6189 87.265C66.8316 86.9999 68.0427 86.7376 69.2582 86.4836L71.0814 86.1063L72.9067 85.7378L72.8281 85.7818C73.3768 85.2281 73.9306 84.6754 74.4889 84.1311L75.3237 83.3094C75.6009 83.0341 75.8847 82.7667 76.1653 82.4953L76.1597 82.492Z'
          fill='#141827'
        />
        <path
          d='M62.2365 91.1978L61.5567 91.5372L61.0474 92.7267L61.7269 98.3328L61.3871 92.7267L62.2365 91.1978Z'
          fill='#141827'
        />
        <path
          d='M78.205 74.7191L77.6954 77.0975L77.1857 77.4372L76.5357 76.7209L75.594 76.281L75.6296 76.2749L74.6379 77.097L75.5838 76.2293L75.5986 76.2147L75.6194 76.2233L76.5744 76.6365L76.5105 76.6653L75.4869 73.3601L78.205 74.7191Z'
          fill='#141827'
        />
        <path
          d='M63.4035 90.1468L65.2616 90.2126L65.4905 89.0618C65.4905 89.0618 66.2953 89.1268 66.2295 88.8714C66.1637 88.616 66.8876 87.3376 66.8876 87.3376L65.4073 87.7191L63.4006 90.145L63.4035 90.1468Z'
          fill='#141827'
        />
        <path
          d='M76.8458 81.6836L74.1277 84.0619L74.4678 84.7416L76.8458 81.6836Z'
          fill='#141827'
        />
        <path
          d='M107.941 124.583C106.327 126.145 106.276 128.712 107.828 130.317C109.381 131.922 111.949 131.957 113.564 130.395C115.178 128.833 115.229 126.266 113.677 124.661C112.124 123.056 109.556 123.021 107.941 124.583Z'
          fill='#AA0000'
        />
        <path
          d='M68.7241 84.0378C67.0881 85.6202 67.0369 88.2212 68.6097 89.8473C70.1825 91.4733 72.7837 91.5087 74.4197 89.9263C76.0557 88.3438 76.107 85.7429 74.5342 84.1168C72.9614 82.4908 70.3601 82.4554 68.7241 84.0378Z'
          fill='#AA0000'
        />
        <path
          d='M102.43 120.504C103.023 120.504 103.504 120.024 103.504 119.43C103.504 118.837 103.023 118.356 102.43 118.356C101.837 118.356 101.356 118.837 101.356 119.43C101.356 120.024 101.837 120.504 102.43 120.504Z'
          fill='#C9CCD4'
        />
        <path
          d='M102.43 120.504C103.023 120.504 103.504 120.024 103.504 119.43C103.504 118.837 103.023 118.356 102.43 118.356C101.837 118.356 101.356 118.837 101.356 119.43C101.356 120.024 101.837 120.504 102.43 120.504Z'
          fill='#C9CCD4'
        />
        <path
          d='M146.826 135.876C146.826 135.876 144.273 136.387 146.805 135.889L65.1558 136.94C65.1558 136.94 92.7629 130.71 97.2738 125.876C101.786 121.042 123.769 112.19 126.062 124.802C126.43 126.83 127.812 128.502 129.756 129.877C129.455 130.447 129.284 131.095 129.284 131.785C129.284 134.04 131.11 135.866 133.365 135.866C135.091 135.866 136.567 134.795 137.164 133.283C137.917 133.518 138.677 133.734 139.433 133.933C139.398 134.071 139.379 134.215 139.379 134.363C139.379 135.313 140.148 136.081 141.098 136.081C141.929 136.081 142.62 135.492 142.783 134.709C144.294 135.017 145.718 135.257 146.963 135.441C147.09 135.754 143.642 135.975 144.002 135.975C144.252 135.975 144.475 135.868 144.632 135.7C144.735 135.865 144.92 135.975 145.13 135.975C145.314 135.975 145.481 135.89 145.587 135.757C145.975 135.802 146.316 135.84 146.61 135.868C146.647 135.931 146.717 135.975 146.794 135.975C146.857 135.975 146.914 135.947 146.954 135.903C146.954 135.903 146.955 135.903 146.956 135.903C147.035 135.948 146.64 135.884 146.738 135.884C146.767 135.884 146.795 135.883 146.821 135.877L146.826 135.876Z'
          fill='#C9CCD4'
        />
        <path
          d='M129.757 129.875C131.787 131.312 134.427 132.424 137.167 133.282C136.569 134.794 135.093 135.865 133.368 135.865C131.113 135.865 129.286 134.039 129.286 131.784C129.286 131.094 129.457 130.445 129.758 129.875H129.757Z'
          fill='#C9CCD4'
        />
        <path
          d='M139.436 133.932C140.571 134.23 141.701 134.487 142.785 134.709C142.624 135.492 141.932 136.081 141.1 136.081C140.15 136.081 139.382 135.313 139.382 134.363C139.382 134.215 139.399 134.07 139.436 133.933V133.932Z'
          fill='#C9CCD4'
        />
        <path
          d='M85.6725 135.329C88.7578 135.329 91.2588 132.828 91.2588 129.743C91.2588 126.657 88.7578 124.156 85.6725 124.156C82.5873 124.156 80.0862 126.657 80.0862 129.743C80.0862 132.828 82.5873 135.329 85.6725 135.329Z'
          fill='#C9CCD4'
        />
        <path
          d='M86.5761 131.925C87.7817 131.426 88.3542 130.044 87.8548 128.838C87.3555 127.632 85.9733 127.06 84.7677 127.559C83.5621 128.059 82.9895 129.441 83.4889 130.646C83.9883 131.852 85.3705 132.424 86.5761 131.925Z'
          fill='#C9CCD4'
        />
        <path
          d='M133.312 129.297C134.021 126.294 132.161 123.286 129.159 122.577C126.156 121.868 123.147 123.728 122.439 126.731C121.73 129.733 123.589 132.742 126.592 133.451C129.595 134.159 132.604 132.3 133.312 129.297Z'
          fill='#C9CCD4'
        />
        <path
          d='M137.449 131.784C137.449 132.313 137.348 132.818 137.167 133.282C136.569 134.794 135.093 135.865 133.368 135.865C131.113 135.865 129.286 134.039 129.286 131.784C129.286 131.094 129.457 130.445 129.758 129.875C130.444 128.582 131.804 127.701 133.369 127.701C135.624 127.701 137.45 129.528 137.45 131.782L137.449 131.784Z'
          fill='#C9CCD4'
        />
        <path
          d='M146.33 137.576C146.33 137.79 146.249 137.986 146.11 138.136C145.96 138.316 145.735 138.425 145.48 138.425C145.168 138.425 144.897 138.258 144.752 138.004C144.677 137.876 144.631 137.732 144.631 137.576C144.631 137.235 144.833 136.94 145.122 136.807C145.232 136.755 145.353 136.727 145.48 136.727C145.948 136.727 146.33 137.108 146.33 137.576Z'
          fill='#C9CCD4'
        />
        <path
          d='M139.874 134.008C139.874 134.436 139.712 134.829 139.435 135.129C139.134 135.488 138.684 135.707 138.175 135.707C137.551 135.707 137.008 135.372 136.719 134.864C136.569 134.609 136.477 134.32 136.477 134.008C136.477 133.327 136.881 132.737 137.459 132.471C137.678 132.367 137.921 132.31 138.175 132.31C139.111 132.31 139.874 133.072 139.874 134.008Z'
          fill='#C9CCD4'
        />
        <path
          d='M142.592 134.008C142.592 134.436 142.43 134.829 142.153 135.129C141.853 135.488 141.402 135.707 140.894 135.707C140.269 135.707 139.726 135.372 139.437 134.864C139.287 134.609 139.195 134.32 139.195 134.008C139.195 133.327 139.599 132.737 140.177 132.471C140.396 132.367 140.639 132.31 140.894 132.31C141.83 132.31 142.592 133.072 142.592 134.008Z'
          fill='#C9CCD4'
        />
        <path
          d='M144.291 134.348C144.291 134.69 144.162 135.005 143.94 135.245C143.699 135.532 143.339 135.707 142.932 135.707C142.433 135.707 141.998 135.439 141.767 135.032C141.647 134.829 141.573 134.598 141.573 134.348C141.573 133.803 141.897 133.331 142.359 133.119C142.535 133.035 142.729 132.989 142.932 132.989C143.681 132.989 144.291 133.599 144.291 134.348Z'
          fill='#C9CCD4'
        />
        <path
          d='M145.65 134.688C145.65 134.944 145.553 135.18 145.387 135.36C145.206 135.575 144.936 135.707 144.631 135.707C144.257 135.707 143.931 135.506 143.757 135.201C143.667 135.048 143.612 134.875 143.612 134.688C143.612 134.279 143.854 133.925 144.201 133.766C144.333 133.703 144.478 133.668 144.631 133.668C145.193 133.668 145.65 134.126 145.65 134.688Z'
          fill='#C9CCD4'
        />
        <path
          d='M147.689 136.387C147.689 136.729 147.559 137.043 147.337 137.284C147.097 137.57 146.737 137.746 146.33 137.746C145.831 137.746 145.396 137.478 145.165 137.071C145.045 136.868 144.971 136.637 144.971 136.387C144.971 135.841 145.294 135.37 145.756 135.157C145.932 135.074 146.126 135.028 146.33 135.028C147.079 135.028 147.689 135.638 147.689 136.387Z'
          fill='#C9CCD4'
        />
        <path
          d='M145.65 136.387C145.65 136.729 145.521 137.043 145.299 137.284C145.058 137.57 144.698 137.746 144.291 137.746C143.792 137.746 143.357 137.478 143.126 137.071C143.006 136.868 142.932 136.637 142.932 136.387C142.932 135.841 143.256 135.37 143.718 135.157C143.894 135.074 144.088 135.028 144.291 135.028C145.04 135.028 145.65 135.638 145.65 136.387Z'
          fill='#C9CCD4'
        />
        <path
          d='M143.612 135.367C143.612 135.709 143.482 136.024 143.26 136.264C143.02 136.551 142.659 136.726 142.253 136.726C141.753 136.726 141.319 136.458 141.088 136.052C140.967 135.848 140.894 135.617 140.894 135.367C140.894 134.822 141.217 134.35 141.679 134.138C141.855 134.055 142.049 134.008 142.253 134.008C143.002 134.008 143.612 134.618 143.612 135.367Z'
          fill='#C9CCD4'
        />
        <path
          d='M95.4117 129.494C96.4605 128.445 96.4606 126.744 95.4117 125.696C94.3629 124.647 92.6623 124.647 91.6135 125.696C90.5646 126.744 90.5646 128.445 91.6135 129.494C92.6623 130.543 94.3628 130.543 95.4117 129.494Z'
          fill='#C9CCD4'
        />
        <path
          d='M95.4117 129.494C96.4605 128.445 96.4606 126.744 95.4117 125.696C94.3629 124.647 92.6623 124.647 91.6135 125.696C90.5646 126.744 90.5646 128.445 91.6135 129.494C92.6623 130.543 94.3628 130.543 95.4117 129.494Z'
          fill='#C9CCD4'
        />
        <path
          d='M101.859 124.981C102.908 123.932 102.908 122.231 101.859 121.182C100.81 120.134 99.1094 120.134 98.0605 121.182C97.0117 122.231 97.0116 123.932 98.0605 124.981C99.1093 126.03 100.81 126.03 101.859 124.981Z'
          fill='#C9CCD4'
        />
        <path
          d='M101.859 124.981C102.908 123.932 102.908 122.231 101.859 121.182C100.81 120.134 99.1094 120.134 98.0605 121.182C97.0117 122.231 97.0116 123.932 98.0605 124.981C99.1093 126.03 100.81 126.03 101.859 124.981Z'
          fill='#C9CCD4'
        />
        <path
          d='M99.8767 128.308C100.217 126.865 99.3233 125.418 97.8797 125.078C96.4361 124.737 94.9895 125.631 94.6488 127.075C94.3081 128.518 95.2022 129.965 96.6458 130.305C98.0894 130.646 99.5359 129.752 99.8767 128.308Z'
          fill='#C9CCD4'
        />
        <path
          d='M99.8767 128.308C100.217 126.865 99.3233 125.418 97.8797 125.078C96.4361 124.737 94.9895 125.631 94.6488 127.075C94.3081 128.518 95.2022 129.965 96.6458 130.305C98.0894 130.646 99.5359 129.752 99.8767 128.308Z'
          fill='#C9CCD4'
        />
        <path
          d='M70.7399 136.403C71.9263 136.403 72.8879 135.441 72.8879 134.255C72.8879 133.069 71.9263 132.107 70.7399 132.107C69.5536 132.107 68.5919 133.069 68.5919 134.255C68.5919 135.441 69.5536 136.403 70.7399 136.403Z'
          fill='#C9CCD4'
        />
        <path
          d='M70.7399 136.403C71.9263 136.403 72.8879 135.441 72.8879 134.255C72.8879 133.069 71.9263 132.107 70.7399 132.107C69.5536 132.107 68.5919 133.069 68.5919 134.255C68.5919 135.441 69.5536 136.403 70.7399 136.403Z'
          fill='#C9CCD4'
        />
        <path
          d='M82.6255 132.645C82.9772 130.479 81.5058 128.437 79.3391 128.085C77.1724 127.734 75.1308 129.205 74.779 131.372C74.4273 133.538 75.8987 135.58 78.0654 135.932C80.2322 136.283 82.2738 134.812 82.6255 132.645Z'
          fill='#C9CCD4'
        />
        <path
          d='M78.6899 132.751C78.2749 132.751 77.9374 132.413 77.9374 131.998C77.9374 131.583 78.2749 131.246 78.6899 131.246C79.1049 131.246 79.4425 131.583 79.4425 131.998C79.4425 132.413 79.1049 132.751 78.6899 132.751Z'
          fill='#C9CCD4'
        />
        <path
          d='M75.6321 134.804C75.8228 133.995 75.3223 133.186 74.514 132.995C73.7058 132.804 72.896 133.305 72.7053 134.113C72.5145 134.921 73.015 135.731 73.8233 135.922C74.6315 136.112 75.4413 135.612 75.6321 134.804Z'
          fill='#C9CCD4'
        />
        <path
          d='M75.6321 134.804C75.8228 133.995 75.3223 133.186 74.514 132.995C73.7058 132.804 72.896 133.305 72.7053 134.113C72.5145 134.921 73.015 135.731 73.8233 135.922C74.6315 136.112 75.4413 135.612 75.6321 134.804Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.5849 136.939C66.4153 136.939 67.0885 136.266 67.0885 135.435C67.0885 134.605 66.4153 133.932 65.5849 133.932C64.7545 133.932 64.0813 134.605 64.0813 135.435C64.0813 136.266 64.7545 136.939 65.5849 136.939Z'
          fill='#C9CCD4'
        />
        <path
          d='M65.5849 136.939C66.4153 136.939 67.0885 136.266 67.0885 135.435C67.0885 134.605 66.4153 133.932 65.5849 133.932C64.7545 133.932 64.0813 134.605 64.0813 135.435C64.0813 136.266 64.7545 136.939 65.5849 136.939Z'
          fill='#C9CCD4'
        />
        <path
          d='M67.6792 136.941C68.8364 136.941 69.7746 136.003 69.7746 134.845C69.7746 133.688 68.8364 132.75 67.6792 132.75C66.5219 132.75 65.5837 133.688 65.5837 134.845C65.5837 136.003 66.5219 136.941 67.6792 136.941Z'
          fill='#C9CCD4'
        />
        <path
          d='M67.6792 136.941C68.8364 136.941 69.7746 136.003 69.7746 134.845C69.7746 133.688 68.8364 132.75 67.6792 132.75C66.5219 132.75 65.5837 133.688 65.5837 134.845C65.5837 136.003 66.5219 136.941 67.6792 136.941Z'
          fill='#C9CCD4'
        />
        <path
          d='M72.9296 133.488C73.1954 132.362 72.4979 131.233 71.3716 130.968C70.2452 130.702 69.1167 131.399 68.8508 132.526C68.585 133.652 69.2826 134.78 70.4089 135.046C71.5352 135.312 72.6638 134.615 72.9296 133.488Z'
          fill='#C9CCD4'
        />
        <path
          d='M72.9296 133.488C73.1954 132.362 72.4979 131.233 71.3716 130.968C70.2452 130.702 69.1167 131.399 68.8508 132.526C68.585 133.652 69.2826 134.78 70.4089 135.046C71.5352 135.312 72.6638 134.615 72.9296 133.488Z'
          fill='#C9CCD4'
        />
        <path
          d='M102.425 124.893C102.425 125.28 102.111 125.593 101.725 125.593C101.34 125.593 101.026 125.279 101.026 124.893C101.026 124.507 101.34 124.193 101.725 124.193C102.111 124.193 102.425 124.507 102.425 124.893Z'
          fill='white'
        />
        <path
          d='M102.425 124.893C102.425 125.28 102.111 125.593 101.725 125.593C101.34 125.593 101.026 125.279 101.026 124.893C101.026 124.507 101.34 124.193 101.725 124.193C102.111 124.193 102.425 124.507 102.425 124.893Z'
          fill='white'
        />
        <path
          d='M133.434 135.672C133.434 135.672 133.595 136.047 133.589 136.047L76.3387 136.897C76.3387 136.897 95.4215 132.246 98.3629 129.095C101.304 125.943 115.636 120.173 117.131 128.395C117.371 129.718 118.272 130.808 119.539 131.703C119.343 132.075 119.232 132.497 119.232 132.947C119.232 134.417 120.423 135.608 121.893 135.608C123.018 135.608 123.98 134.909 124.37 133.923C124.86 134.077 125.356 134.217 125.85 134.347C125.827 134.436 125.815 134.531 125.815 134.627C125.815 135.247 126.316 135.748 126.936 135.748C127.478 135.748 127.93 135.364 128.033 134.854C129.018 135.056 129.948 135.21 130.759 135.33C130.842 135.533 131.044 135.678 131.277 135.678C131.44 135.678 131.586 135.609 131.688 135.498C131.755 135.606 131.875 135.678 132.012 135.678C132.132 135.678 132.24 135.623 132.31 135.536C132.563 135.566 132.785 135.59 132.977 135.609C133 135.65 133.045 135.678 133.097 135.678C133.138 135.678 133.176 135.661 133.2 135.631C133.2 135.631 133.2 135.631 133.202 135.631C133.253 135.661 133.313 135.678 133.376 135.678C133.395 135.678 133.412 135.678 133.43 135.674L133.434 135.672Z'
          fill='white'
        />
        <path
          d='M119.539 131.702C120.861 132.638 122.584 133.363 124.37 133.923C123.98 134.909 123.018 135.608 121.893 135.608C120.423 135.608 119.232 134.417 119.232 132.947C119.232 132.497 119.343 132.074 119.539 131.703V131.702Z'
          fill='white'
        />
        <path
          d='M125.851 134.347C126.592 134.541 127.327 134.709 128.034 134.854C127.929 135.364 127.477 135.748 126.937 135.748C126.317 135.748 125.816 135.247 125.816 134.627C125.816 134.531 125.827 134.437 125.851 134.347Z'
          fill='white'
        />
        <path
          d='M131.719 135.463C131.931 135.491 132.128 135.514 132.311 135.536C132.241 135.622 132.133 135.678 132.013 135.678C131.875 135.678 131.756 135.606 131.688 135.498C131.588 135.608 131.44 135.678 131.278 135.678C131.044 135.678 130.842 135.535 130.759 135.33C131.081 135.378 131.384 135.419 131.664 135.456C131.681 135.457 131.7 135.46 131.718 135.463H131.719Z'
          fill='white'
        />
        <path
          d='M132.978 135.608C133.058 135.617 133.133 135.624 133.202 135.63C133.177 135.659 133.139 135.677 133.098 135.677C133.047 135.677 133.001 135.649 132.978 135.608Z'
          fill='white'
        />
        <path
          d='M133.434 135.672C133.417 135.675 133.399 135.676 133.38 135.676C133.316 135.676 133.257 135.659 133.206 135.629C133.288 135.637 133.36 135.644 133.424 135.65C133.436 135.65 133.44 135.663 133.436 135.67L133.434 135.672Z'
          fill='white'
        />
        <path
          d='M94.3322 132.441C94.7942 130.484 93.582 128.523 91.6247 128.061C89.6674 127.599 87.7061 128.811 87.2442 130.768C86.7822 132.726 87.9944 134.687 89.9518 135.149C91.9091 135.611 93.8703 134.399 94.3322 132.441Z'
          fill='white'
        />
        <path
          d='M91.8892 132.705C92.4907 132.104 92.4907 131.129 91.8892 130.527C91.2877 129.926 90.3126 129.926 89.7111 130.527C89.1096 131.129 89.1097 132.104 89.7111 132.705C90.3126 133.307 91.2877 133.307 91.8892 132.705Z'
          fill='white'
        />
        <path
          d='M121.929 131.09C122.251 129.105 120.903 127.234 118.918 126.912C116.933 126.59 115.063 127.938 114.74 129.923C114.418 131.908 115.766 133.778 117.751 134.101C119.736 134.423 121.607 133.075 121.929 131.09Z'
          fill='white'
        />
        <path
          d='M124.554 132.947C124.554 133.291 124.488 133.622 124.37 133.924C123.98 134.91 123.018 135.609 121.893 135.609C120.423 135.609 119.232 134.418 119.232 132.948C119.232 132.498 119.343 132.076 119.539 131.705C119.986 130.861 120.873 130.287 121.893 130.287C123.363 130.287 124.554 131.478 124.554 132.948V132.947Z'
          fill='white'
        />
        <path
          d='M128.057 134.628C128.057 134.705 128.05 134.78 128.035 134.854C127.93 135.364 127.479 135.748 126.938 135.748C126.318 135.748 125.817 135.247 125.817 134.628C125.817 134.531 125.829 134.438 125.852 134.347C125.976 133.863 126.415 133.507 126.938 133.507C127.557 133.507 128.059 134.008 128.059 134.628H128.057Z'
          fill='white'
        />
        <path
          d='M128.057 134.628C128.057 134.705 128.05 134.78 128.035 134.854C127.93 135.364 127.479 135.748 126.938 135.748C126.318 135.748 125.817 135.247 125.817 134.628C125.817 134.531 125.829 134.438 125.852 134.347C125.976 133.863 126.415 133.507 126.938 133.507C127.557 133.507 128.059 134.008 128.059 134.628H128.057Z'
          fill='white'
        />
        <path
          d='M131.838 135.117C131.838 135.247 131.793 135.367 131.72 135.462C131.709 135.475 131.701 135.486 131.689 135.497C131.588 135.606 131.44 135.676 131.278 135.676C131.044 135.676 130.843 135.533 130.76 135.329C130.732 135.263 130.717 135.191 130.717 135.115C130.717 134.806 130.968 134.556 131.277 134.556C131.533 134.556 131.749 134.727 131.816 134.96C131.831 135.01 131.838 135.061 131.838 135.115V135.117Z'
          fill='white'
        />
        <path
          d='M131.838 135.117C131.838 135.247 131.793 135.367 131.72 135.462C131.709 135.475 131.701 135.486 131.689 135.497C131.588 135.606 131.44 135.676 131.278 135.676C131.044 135.676 130.843 135.533 130.76 135.329C130.732 135.263 130.717 135.191 130.717 135.115C130.717 134.806 130.968 134.556 131.277 134.556C131.533 134.556 131.749 134.727 131.816 134.96C131.831 135.01 131.838 135.061 131.838 135.115V135.117Z'
          fill='white'
        />
        <path
          d='M133.729 135.327C133.729 135.501 133.6 135.647 133.434 135.672C133.416 135.675 133.399 135.676 133.38 135.676C133.315 135.676 133.257 135.659 133.206 135.63C133.124 135.583 133.064 135.504 133.042 135.411C133.035 135.384 133.032 135.356 133.032 135.327C133.032 135.134 133.188 134.977 133.383 134.977C133.577 134.977 133.733 135.133 133.733 135.327H133.729Z'
          fill='white'
        />
        <path
          d='M133.729 135.327C133.729 135.501 133.6 135.647 133.434 135.672C133.416 135.675 133.399 135.676 133.38 135.676C133.315 135.676 133.257 135.659 133.206 135.63C133.124 135.583 133.064 135.504 133.042 135.411C133.035 135.384 133.032 135.356 133.032 135.327C133.032 135.134 133.188 134.977 133.383 134.977C133.577 134.977 133.733 135.133 133.733 135.327H133.729Z'
          fill='white'
        />
        <path
          d='M132.398 135.292C132.398 135.385 132.365 135.469 132.312 135.535C132.242 135.621 132.134 135.677 132.014 135.677C131.877 135.677 131.757 135.605 131.69 135.497C131.681 135.484 131.673 135.469 131.666 135.455C131.643 135.405 131.63 135.349 131.63 135.291C131.63 135.151 131.706 135.027 131.818 134.961C131.875 134.926 131.942 134.907 132.015 134.907C132.229 134.907 132.401 135.079 132.401 135.292H132.398Z'
          fill='white'
        />
        <path
          d='M132.398 135.292C132.398 135.385 132.365 135.469 132.312 135.535C132.242 135.621 132.134 135.677 132.014 135.677C131.877 135.677 131.757 135.605 131.69 135.497C131.681 135.484 131.673 135.469 131.666 135.455C131.643 135.405 131.63 135.349 131.63 135.291C131.63 135.151 131.706 135.027 131.818 134.961C131.875 134.926 131.942 134.907 132.015 134.907C132.229 134.907 132.401 135.079 132.401 135.292H132.398Z'
          fill='white'
        />
        <path
          d='M147.791 137.628C147.791 137.758 147.746 137.877 147.673 137.972C147.662 137.986 147.654 137.997 147.642 138.007C147.541 138.117 147.394 138.187 147.231 138.187C146.998 138.187 146.796 138.044 146.713 137.839C146.685 137.774 146.67 137.702 146.67 137.626C146.67 137.316 146.922 137.066 147.23 137.066C147.486 137.066 147.702 137.237 147.769 137.471C147.784 137.521 147.791 137.572 147.791 137.626V137.628Z'
          fill='#C9CCD4'
        />
        <path
          d='M147.111 137.967C147.111 138.097 147.066 138.217 146.993 138.312C146.983 138.325 146.974 138.337 146.962 138.347C146.862 138.457 146.714 138.527 146.552 138.527C146.318 138.527 146.116 138.384 146.033 138.179C146.005 138.113 145.991 138.042 145.991 137.966C145.991 137.656 146.242 137.406 146.55 137.406C146.806 137.406 147.022 137.577 147.09 137.811C147.104 137.861 147.111 137.912 147.111 137.966V137.967Z'
          fill='#C9CCD4'
        />
        <path
          d='M148.029 138.766C148.029 138.924 147.974 139.069 147.886 139.184C147.873 139.2 147.863 139.214 147.849 139.227C147.726 139.359 147.547 139.445 147.351 139.445C147.067 139.445 146.823 139.271 146.722 139.023C146.688 138.943 146.67 138.856 146.67 138.764C146.67 138.388 146.975 138.085 147.349 138.085C147.659 138.085 147.921 138.293 148.003 138.576C148.021 138.637 148.029 138.699 148.029 138.764V138.766Z'
          fill='#C9CCD4'
        />
        <path
          d='M148.738 139.736C148.654 139.882 148.475 139.943 148.323 139.883C148.307 139.877 148.292 139.87 148.276 139.861C148.222 139.83 148.181 139.787 148.152 139.738C148.106 139.659 148.094 139.563 148.121 139.474C148.128 139.448 148.139 139.424 148.153 139.399C148.246 139.237 148.454 139.18 148.617 139.273C148.78 139.367 148.836 139.574 148.742 139.738L148.738 139.736Z'
          fill='#C9CCD4'
        />
        <path
          d='M148.95 140.053C148.908 140.126 148.818 140.157 148.742 140.127C148.734 140.124 148.727 140.12 148.719 140.116C148.692 140.1 148.671 140.079 148.657 140.054C148.634 140.015 148.628 139.967 148.641 139.922C148.644 139.91 148.65 139.897 148.657 139.885C148.704 139.804 148.808 139.775 148.889 139.822C148.971 139.869 148.999 139.973 148.952 140.054L148.95 140.053Z'
          fill='#C9CCD4'
        />
        <path
          d='M148.803 139.969C148.761 140.042 148.672 140.072 148.596 140.043C148.588 140.04 148.58 140.036 148.572 140.031C148.545 140.016 148.525 139.994 148.51 139.97C148.487 139.93 148.481 139.883 148.495 139.838C148.498 139.825 148.503 139.813 148.51 139.8C148.557 139.719 148.661 139.691 148.743 139.738C148.824 139.785 148.852 139.888 148.805 139.97L148.803 139.969Z'
          fill='#C9CCD4'
        />
        <path
          d='M142.915 135.372C142.915 135.941 142.708 136.464 142.383 136.87C141.951 137.403 141.284 137.746 140.545 137.746C139.699 137.746 138.96 137.304 138.545 136.636C138.491 136.555 138.446 136.464 138.401 136.374C138.257 136.067 138.176 135.724 138.176 135.363C138.176 134.497 138.645 133.729 139.338 133.323C139.69 133.107 140.104 132.989 140.554 132.989C141.87 132.989 142.933 134.054 142.933 135.372H142.915Z'
          fill='#C9CCD4'
        />
        <path
          d='M145.633 136.051C145.633 136.62 145.426 137.143 145.101 137.55C144.669 138.082 144.002 138.425 143.263 138.425C142.417 138.425 141.678 137.983 141.263 137.315C141.209 137.234 141.164 137.144 141.119 137.053C140.975 136.746 140.894 136.403 140.894 136.042C140.894 135.176 141.363 134.409 142.056 134.002C142.408 133.786 142.822 133.668 143.272 133.668C144.588 133.668 145.651 134.733 145.651 136.051H145.633Z'
          fill='#C9CCD4'
        />
        <path
          d='M139.177 134.692C139.177 135.261 138.97 135.785 138.646 136.191C138.213 136.723 137.547 137.066 136.808 137.066C135.961 137.066 135.222 136.624 134.808 135.956C134.754 135.875 134.709 135.785 134.664 135.694C134.52 135.388 134.439 135.044 134.439 134.683C134.439 133.817 134.907 133.05 135.601 132.644C135.952 132.427 136.367 132.31 136.817 132.31C138.132 132.31 139.195 133.375 139.195 134.692H139.177Z'
          fill='#C9CCD4'
        />
        <path
          d='M148.208 139.483C148.162 139.563 148.091 139.619 148.012 139.65C147.908 139.69 147.787 139.684 147.683 139.624C147.564 139.556 147.496 139.434 147.492 139.307C147.49 139.291 147.491 139.275 147.492 139.258C147.497 139.204 147.513 139.149 147.542 139.098C147.612 138.977 147.74 138.907 147.87 138.906C147.937 138.904 148.005 138.921 148.068 138.958C148.253 139.064 148.317 139.299 148.21 139.484L148.208 139.483Z'
          fill='#C9CCD4'
        />
        <path
          d='M148.445 139.567C148.404 139.638 148.341 139.688 148.272 139.715C148.181 139.75 148.074 139.745 147.982 139.692C147.878 139.632 147.818 139.525 147.814 139.412C147.813 139.398 147.814 139.384 147.815 139.37C147.819 139.321 147.833 139.273 147.859 139.229C147.921 139.121 148.033 139.06 148.148 139.059C148.207 139.057 148.267 139.072 148.322 139.104C148.485 139.198 148.541 139.405 148.447 139.569L148.445 139.567Z'
          fill='#C9CCD4'
        />
        <path
          d='M133.238 135.538C133.238 135.573 133.225 135.605 133.203 135.63C133.178 135.659 133.14 135.678 133.099 135.678C133.048 135.678 133.003 135.65 132.979 135.609C132.966 135.589 132.96 135.564 132.96 135.539C132.96 135.484 132.994 135.435 133.041 135.412C133.058 135.403 133.079 135.399 133.1 135.399C133.178 135.399 133.241 135.462 133.241 135.539L133.238 135.538Z'
          fill='white'
        />
        <path
          d='M133.238 135.538C133.238 135.573 133.225 135.605 133.203 135.63C133.178 135.659 133.14 135.678 133.099 135.678C133.048 135.678 133.003 135.65 132.979 135.609C132.966 135.589 132.96 135.564 132.96 135.539C132.96 135.484 132.994 135.435 133.041 135.412C133.058 135.403 133.079 135.399 133.1 135.399C133.178 135.399 133.241 135.462 133.241 135.539L133.238 135.538Z'
          fill='white'
        />
        <path
          d='M95.9111 131.965C96.8779 131.965 97.6617 131.181 97.6617 130.214C97.6617 129.247 96.8779 128.463 95.9111 128.463C94.9443 128.463 94.1605 129.247 94.1605 130.214C94.1605 131.181 94.9443 131.965 95.9111 131.965Z'
          fill='white'
        />
        <path
          d='M95.9111 131.965C96.8779 131.965 97.6617 131.181 97.6617 130.214C97.6617 129.247 96.8779 128.463 95.9111 128.463C94.9443 128.463 94.1605 129.247 94.1605 130.214C94.1605 131.181 94.9443 131.965 95.9111 131.965Z'
          fill='white'
        />
        <path
          d='M100.113 129.023C101.08 129.023 101.864 128.239 101.864 127.273C101.864 126.306 101.08 125.522 100.113 125.522C99.1463 125.522 98.3625 126.306 98.3625 127.273C98.3625 128.239 99.1463 129.023 100.113 129.023Z'
          fill='white'
        />
        <path
          d='M100.113 129.023C101.08 129.023 101.864 128.239 101.864 127.273C101.864 126.306 101.08 125.522 100.113 125.522C99.1463 125.522 98.3625 126.306 98.3625 127.273C98.3625 128.239 99.1463 129.023 100.113 129.023Z'
          fill='white'
        />
        <path
          d='M100.102 130.575C100.257 129.621 99.609 128.722 98.6547 128.567C97.7003 128.412 96.8011 129.06 96.6462 130.014C96.4913 130.969 97.1394 131.868 98.0937 132.023C99.048 132.178 99.9472 131.53 100.102 130.575Z'
          fill='white'
        />
        <path
          d='M100.102 130.575C100.257 129.621 99.609 128.722 98.6547 128.567C97.7003 128.412 96.8011 129.06 96.6462 130.014C96.4913 130.969 97.1394 131.868 98.0937 132.023C99.048 132.178 99.9472 131.53 100.102 130.575Z'
          fill='white'
        />
        <path
          d='M82.0554 135.549C82.6027 135.001 82.6027 134.114 82.0554 133.567C81.5082 133.019 80.6209 133.019 80.0737 133.567C79.5264 134.114 79.5264 135.001 80.0737 135.549C80.6209 136.096 81.5082 136.096 82.0554 135.549Z'
          fill='white'
        />
        <path
          d='M82.0554 135.549C82.6027 135.001 82.6027 134.114 82.0554 133.567C81.5082 133.019 80.6209 133.019 80.0737 133.567C79.5264 134.114 79.5264 135.001 80.0737 135.549C80.6209 136.096 81.5082 136.096 82.0554 135.549Z'
          fill='white'
        />
        <path
          d='M87.2391 135.479C88.5611 134.932 89.1888 133.416 88.6412 132.094C88.0937 130.772 86.5782 130.145 85.2562 130.692C83.9343 131.24 83.3066 132.755 83.8541 134.077C84.4017 135.399 85.9172 136.027 87.2391 135.479Z'
          fill='white'
        />
        <path
          d='M86.2474 133.577C85.9771 133.577 85.7579 133.356 85.7579 133.087C85.7579 132.818 85.9771 132.598 86.2474 132.598C86.5178 132.598 86.737 132.818 86.737 133.087C86.737 133.356 86.5178 133.577 86.2474 133.577Z'
          fill='white'
        />
        <path
          d='M84.2868 134.697C84.2868 135.239 83.8484 135.678 83.3063 135.678C82.7642 135.678 82.3258 135.239 82.3258 134.697C82.3258 134.155 82.7642 133.717 83.3063 133.717C83.8484 133.717 84.2868 134.155 84.2868 134.697Z'
          fill='white'
        />
        <path
          d='M84.2868 134.697C84.2868 135.239 83.8484 135.678 83.3063 135.678C82.7642 135.678 82.3258 135.239 82.3258 134.697C82.3258 134.155 82.7642 133.717 83.3063 133.717C83.8484 133.717 84.2868 134.155 84.2868 134.697Z'
          fill='white'
        />
        <path
          d='M78.6846 135.327C78.6846 135.869 78.2463 136.308 77.7041 136.308C77.162 136.308 76.7236 135.869 76.7236 135.327C76.7236 134.785 77.162 134.347 77.7041 134.347C78.2463 134.347 78.6846 134.785 78.6846 135.327Z'
          fill='white'
        />
        <path
          d='M78.6846 135.327C78.6846 135.869 78.2463 136.308 77.7041 136.308C77.162 136.308 76.7236 135.869 76.7236 135.327C76.7236 134.785 77.162 134.347 77.7041 134.347C78.2463 134.347 78.6846 134.785 78.6846 135.327Z'
          fill='white'
        />
        <path
          d='M79.0688 136.308C79.8233 136.308 80.435 135.696 80.435 134.941C80.435 134.187 79.8233 133.575 79.0688 133.575C78.3142 133.575 77.7025 134.187 77.7025 134.941C77.7025 135.696 78.3142 136.308 79.0688 136.308Z'
          fill='white'
        />
        <path
          d='M79.0688 136.308C79.8233 136.308 80.435 135.696 80.435 134.941C80.435 134.187 79.8233 133.575 79.0688 133.575C78.3142 133.575 77.7025 134.187 77.7025 134.941C77.7025 135.696 78.3142 136.308 79.0688 136.308Z'
          fill='white'
        />
        <path
          d='M81.1697 135.118C81.9243 135.118 82.536 134.507 82.536 133.752C82.536 132.997 81.9243 132.386 81.1697 132.386C80.4152 132.386 79.8035 132.997 79.8035 133.752C79.8035 134.507 80.4152 135.118 81.1697 135.118Z'
          fill='white'
        />
        <path
          d='M81.1697 135.118C81.9243 135.118 82.536 134.507 82.536 133.752C82.536 132.997 81.9243 132.386 81.1697 132.386C80.4152 132.386 79.8035 132.997 79.8035 133.752C79.8035 134.507 80.4152 135.118 81.1697 135.118Z'
          fill='white'
        />
        <path
          d='M76.5891 128.506C76.5891 128.797 76.3153 129.034 75.9759 129.034C75.6365 129.034 75.3627 128.798 75.3627 128.506C75.3627 128.214 75.6365 127.979 75.9759 127.979C76.3153 127.979 76.5891 128.214 76.5891 128.506Z'
          fill='#C9CCD4'
        />
        <path
          d='M76.5891 128.506C76.5891 128.797 76.3153 129.034 75.9759 129.034C75.6365 129.034 75.3627 128.798 75.3627 128.506C75.3627 128.214 75.6365 127.979 75.9759 127.979C76.3153 127.979 76.5891 128.214 76.5891 128.506Z'
          fill='#C9CCD4'
        />
        <path
          d='M103.712 136.62C103.712 136.62 103.705 136.626 103.7 136.626L54.7192 137.098C54.7192 137.098 70.463 134.041 73.0356 131.67C75.6082 129.298 88.1447 124.954 89.4531 131.142C89.6638 132.137 90.4511 132.957 91.5601 133.632C91.3885 133.911 91.2914 134.23 91.2914 134.569C91.2914 135.675 92.3336 136.572 93.6193 136.572C94.6034 136.572 95.445 136.046 95.7856 135.305C96.2146 135.421 96.6486 135.526 97.0802 135.624C97.06 135.691 97.0499 135.761 97.0499 135.834C97.0499 136.3 97.4889 136.677 98.0302 136.677C98.5046 136.677 98.8983 136.388 98.9904 136.004C99.8522 136.156 100.665 136.272 101.375 136.363C101.448 136.516 101.624 136.625 101.828 136.625C101.971 136.625 102.098 136.572 102.188 136.49C102.247 136.572 102.352 136.625 102.471 136.625C102.576 136.625 102.671 136.584 102.733 136.518C102.953 136.54 103.149 136.559 103.316 136.573C103.336 136.604 103.376 136.626 103.422 136.626C103.458 136.626 103.49 136.613 103.512 136.591C103.512 136.591 103.512 136.591 103.514 136.591C103.558 136.613 103.611 136.626 103.666 136.626C103.683 136.626 103.699 136.626 103.714 136.622L103.712 136.62Z'
          fill='#C9CCD4'
        />
        <path
          d='M91.5591 133.632C92.7161 134.337 94.2226 134.883 95.7846 135.304C95.444 136.046 94.6024 136.571 93.6183 136.571C92.3326 136.571 91.2904 135.674 91.2904 134.568C91.2904 134.229 91.3875 133.911 91.5591 133.631V133.632Z'
          fill='#C9CCD4'
        />
        <path
          d='M97.0795 135.622C97.7267 135.768 98.3702 135.894 98.9897 136.003C98.8976 136.388 98.504 136.677 98.0296 136.677C97.4883 136.677 97.0492 136.3 97.0492 135.834C97.0492 135.761 97.0593 135.691 97.0795 135.624V135.622Z'
          fill='#C9CCD4'
        />
        <path
          d='M103.314 136.57C103.384 136.578 103.45 136.582 103.509 136.588C103.488 136.61 103.455 136.623 103.418 136.623C103.374 136.623 103.334 136.602 103.312 136.57H103.314Z'
          fill='#C9CCD4'
        />
        <path
          d='M103.712 136.621C103.697 136.623 103.68 136.625 103.664 136.625C103.609 136.625 103.557 136.612 103.511 136.59C103.582 136.596 103.646 136.602 103.702 136.604C103.712 136.604 103.716 136.615 103.712 136.621Z'
          fill='#C9CCD4'
        />
        <path
          d='M66.4191 136.308C68.1786 136.308 69.605 135.08 69.605 133.566C69.605 132.053 68.1786 130.825 66.4191 130.825C64.6596 130.825 63.2333 132.053 63.2333 133.566C63.2333 135.08 64.6596 136.308 66.4191 136.308Z'
          fill='#C9CCD4'
        />
        <path
          d='M66.4189 134.727C67.1631 134.727 67.7664 134.207 67.7664 133.566C67.7664 132.926 67.1631 132.406 66.4189 132.406C65.6747 132.406 65.0714 132.926 65.0714 133.566C65.0714 134.207 65.6747 134.727 66.4189 134.727Z'
          fill='#C9CCD4'
        />
        <path
          d='M91.4157 135.754C93.1752 135.754 94.6015 134.526 94.6015 133.012C94.6015 131.498 93.1752 130.271 91.4157 130.271C89.6562 130.271 88.2299 131.498 88.2299 133.012C88.2299 134.526 89.6562 135.754 91.4157 135.754Z'
          fill='#C9CCD4'
        />
        <path
          d='M95.9461 134.567C95.9461 134.827 95.8881 135.074 95.7846 135.302C95.444 136.045 94.6024 136.569 93.6183 136.569C92.3326 136.569 91.2904 135.672 91.2904 134.566C91.2904 134.227 91.3875 133.91 91.5591 133.629C91.9503 132.995 92.725 132.562 93.6183 132.562C94.904 132.562 95.9461 133.46 95.9461 134.566V134.567Z'
          fill='#C9CCD4'
        />
        <path
          d='M99.0086 135.832C99.0086 135.891 99.0023 135.948 98.9884 136.003C98.8963 136.388 98.5026 136.677 98.0282 136.677C97.4869 136.677 97.0479 136.3 97.0479 135.834C97.0479 135.761 97.0579 135.691 97.0781 135.623C97.1866 135.26 97.5702 134.991 98.0282 134.991C98.5695 134.991 99.0086 135.368 99.0086 135.834V135.832Z'
          fill='#C9CCD4'
        />
        <path
          d='M99.0086 135.832C99.0086 135.891 99.0023 135.948 98.9884 136.003C98.8963 136.388 98.5026 136.677 98.0282 136.677C97.4869 136.677 97.0479 136.3 97.0479 135.834C97.0479 135.761 97.0579 135.691 97.0781 135.623C97.1866 135.26 97.5702 134.991 98.0282 134.991C98.5695 134.991 99.0086 135.368 99.0086 135.834V135.832Z'
          fill='#C9CCD4'
        />
        <path
          d='M70.8907 133.831C71.7367 133.831 72.4225 133.241 72.4225 132.513C72.4225 131.785 71.7367 131.195 70.8907 131.195C70.0448 131.195 69.359 131.785 69.359 132.513C69.359 133.241 70.0448 133.831 70.8907 133.831Z'
          fill='#C9CCD4'
        />
        <path
          d='M70.8907 133.831C71.7367 133.831 72.4225 133.241 72.4225 132.513C72.4225 131.785 71.7367 131.195 70.8907 131.195C70.0448 131.195 69.359 131.785 69.359 132.513C69.359 133.241 70.0448 133.831 70.8907 133.831Z'
          fill='#C9CCD4'
        />
        <path
          d='M74.5664 131.617C75.4123 131.617 76.0981 131.027 76.0981 130.299C76.0981 129.571 75.4123 128.981 74.5664 128.981C73.7204 128.981 73.0347 129.571 73.0347 130.299C73.0347 131.027 73.7204 131.617 74.5664 131.617Z'
          fill='#C9CCD4'
        />
        <path
          d='M74.5664 131.617C75.4123 131.617 76.0981 131.027 76.0981 130.299C76.0981 129.571 75.4123 128.981 74.5664 128.981C73.7204 128.981 73.0347 129.571 73.0347 130.299C73.0347 131.027 73.7204 131.617 74.5664 131.617Z'
          fill='#C9CCD4'
        />
        <path
          d='M73.0361 133.884C73.8821 133.884 74.5678 133.294 74.5678 132.566C74.5678 131.838 73.8821 131.248 73.0361 131.248C72.1902 131.248 71.5044 131.838 71.5044 132.566C71.5044 133.294 72.1902 133.884 73.0361 133.884Z'
          fill='#C9CCD4'
        />
        <path
          d='M73.0361 133.884C73.8821 133.884 74.5678 133.294 74.5678 132.566C74.5678 131.838 73.8821 131.248 73.0361 131.248C72.1902 131.248 71.5044 131.838 71.5044 132.566C71.5044 133.294 72.1902 133.884 73.0361 133.884Z'
          fill='#C9CCD4'
        />
        <path
          d='M59.1304 135.78C59.1304 136.362 58.5816 136.834 57.9053 136.834C57.229 136.834 56.6802 136.362 56.6802 135.78C56.6802 135.199 57.229 134.727 57.9053 134.727C58.5816 134.727 59.1304 135.199 59.1304 135.78Z'
          fill='#C9CCD4'
        />
        <path
          d='M59.1304 135.78C59.1304 136.362 58.5816 136.834 57.9053 136.834C57.229 136.834 56.6802 136.362 56.6802 135.78C56.6802 135.199 57.229 134.727 57.9053 134.727C58.5816 134.727 59.1304 135.199 59.1304 135.78Z'
          fill='#C9CCD4'
        />
        <path
          d='M62.4382 136.625C63.6897 136.625 64.7042 135.751 64.7042 134.674C64.7042 133.597 63.6897 132.723 62.4382 132.723C61.1867 132.723 60.1721 133.597 60.1721 134.674C60.1721 135.751 61.1867 136.625 62.4382 136.625Z'
          fill='#C9CCD4'
        />
        <path
          d='M62.4379 135.042C62.202 135.042 62.0089 134.877 62.0089 134.673C62.0089 134.47 62.2007 134.305 62.4379 134.305C62.6751 134.305 62.8669 134.47 62.8669 134.673C62.8669 134.877 62.6751 135.042 62.4379 135.042Z'
          fill='#C9CCD4'
        />
        <path
          d='M60.7234 135.885C60.7234 136.293 60.3398 136.623 59.8654 136.623C59.391 136.623 59.0074 136.293 59.0074 135.885C59.0074 135.478 59.391 135.147 59.8654 135.147C60.3398 135.147 60.7234 135.478 60.7234 135.885Z'
          fill='#C9CCD4'
        />
        <path
          d='M61.7289 136.047C61.7289 136.986 60.8934 137.746 59.8601 137.746C58.8269 137.746 57.9915 136.986 57.9915 136.047C57.9915 135.108 58.8269 134.348 59.8601 134.348C60.8934 134.348 61.7289 135.108 61.7289 136.047Z'
          fill='#C9CCD4'
        />
        <path
          d='M54.7723 138.542C55.2461 138.542 55.6303 138.212 55.6303 137.804C55.6303 137.397 55.2461 137.066 54.7723 137.066C54.2984 137.066 53.9143 137.397 53.9143 137.804C53.9143 138.212 54.2984 138.542 54.7723 138.542Z'
          fill='#C9CCD4'
        />
        <path
          d='M55.7916 137.863C56.2654 137.863 56.6495 137.532 56.6495 137.125C56.6495 136.717 56.2654 136.387 55.7916 136.387C55.3177 136.387 54.9336 136.717 54.9336 137.125C54.9336 137.532 55.3177 137.863 55.7916 137.863Z'
          fill='#C9CCD4'
        />
        <path
          d='M55.6244 139.113C55.6244 139.68 55.0895 140.14 54.4296 140.14C53.7697 140.14 53.2347 139.68 53.2347 139.113C53.2347 138.546 53.7697 138.085 54.4296 138.085C55.0895 138.085 55.6244 138.546 55.6244 139.113Z'
          fill='#C9CCD4'
        />
        <path
          d='M56.9834 138.434C56.9834 139 56.4485 139.461 55.7886 139.461C55.1287 139.461 54.5938 139 54.5938 138.434C54.5938 137.867 55.1287 137.406 55.7886 137.406C56.4485 137.406 56.9834 137.867 56.9834 138.434Z'
          fill='#C9CCD4'
        />
        <path
          d='M58.3426 137.414C58.3426 137.981 57.8076 138.441 57.1477 138.441C56.4879 138.441 55.9529 137.981 55.9529 137.414C55.9529 136.847 56.4879 136.387 57.1477 136.387C57.8076 136.387 58.3426 136.847 58.3426 137.414Z'
          fill='#C9CCD4'
        />
        <path
          d='M59.3619 136.734C59.3619 137.301 58.8269 137.762 58.167 137.762C57.5071 137.762 56.9722 137.301 56.9722 136.734C56.9722 136.167 57.5071 135.707 58.167 135.707C58.8269 135.707 59.3619 136.167 59.3619 136.734Z'
          fill='#C9CCD4'
        />
        <path
          d='M82.8866 129.975C82.8866 130.205 83.1379 130.392 83.4492 130.392C83.7604 130.392 84.0117 130.205 84.0117 129.975C84.0117 129.744 83.7604 129.557 83.4492 129.557C83.1379 129.557 82.8866 129.744 82.8866 129.975Z'
          fill='white'
        />
        <path
          d='M82.8866 129.975C82.8866 130.205 83.1379 130.392 83.4492 130.392C83.7604 130.392 84.0117 130.205 84.0117 129.975C84.0117 129.744 83.7604 129.557 83.4492 129.557C83.1379 129.557 82.8866 129.744 82.8866 129.975Z'
          fill='white'
        />
        <path
          d='M57.9956 136.403C57.9956 136.403 58.0014 136.407 58.0058 136.407L135.627 136.727C135.627 136.727 136.228 135.816 134.268 135.537C132.568 135.296 103.336 136.224 100.971 135.849C94.7142 134.855 87.4484 133.515 86.1464 132.479C83.785 130.6 72.2807 127.159 71.081 132.061C70.8881 132.85 70.1648 133.499 69.1478 134.034C69.3056 134.255 69.3947 134.507 69.3947 134.776C69.3947 135.653 68.4391 136.363 67.2584 136.363C66.3554 136.363 65.5838 135.947 65.2696 135.359C64.8766 135.451 64.4777 135.535 64.0817 135.612C64.1007 135.666 64.1094 135.722 64.1094 135.779C64.1094 136.148 63.7076 136.447 63.2093 136.447C62.7738 136.447 62.4115 136.217 62.3282 135.913C61.5376 136.033 60.7924 136.127 60.1407 136.198C60.0735 136.319 59.9127 136.406 59.7242 136.406C59.5942 136.406 59.4758 136.365 59.3955 136.299C59.3414 136.363 59.245 136.406 59.1339 136.406C59.0375 136.406 58.9513 136.372 58.8943 136.321C58.6912 136.338 58.5129 136.353 58.3595 136.365C58.3405 136.39 58.3039 136.406 58.263 136.406C58.2294 136.406 58.2002 136.395 58.1797 136.378H58.1783C58.1373 136.395 58.0891 136.406 58.038 136.406C58.0234 136.406 58.0088 136.406 57.9941 136.403H57.9956Z'
          fill='white'
        />
        <path
          d='M69.148 134.036C68.0857 134.594 66.7034 135.026 65.2699 135.36C65.5826 135.947 66.3542 136.363 67.2587 136.363C68.4379 136.363 69.395 135.653 69.395 134.777C69.395 134.509 69.3058 134.256 69.148 134.034V134.036Z'
          fill='white'
        />
        <path
          d='M64.0822 135.612C63.4875 135.728 62.8972 135.829 62.3287 135.915C62.4135 136.219 62.7744 136.448 63.2098 136.448C63.7067 136.448 64.11 136.149 64.11 135.78C64.11 135.723 64.0998 135.666 64.0822 135.614V135.612Z'
          fill='white'
        />
        <path
          d='M59.3729 136.277C59.2033 136.293 59.0441 136.308 58.8979 136.321C58.9535 136.372 59.0412 136.406 59.1376 136.406C59.2472 136.406 59.3451 136.363 59.3992 136.299C59.481 136.365 59.5979 136.406 59.7279 136.406C59.915 136.406 60.0772 136.32 60.1444 136.198C59.8858 136.226 59.6432 136.251 59.4182 136.273C59.4035 136.273 59.3889 136.276 59.3743 136.277H59.3729Z'
          fill='white'
        />
        <path
          d='M58.361 136.365C58.2967 136.371 58.2368 136.375 58.1813 136.378C58.2017 136.395 58.2309 136.406 58.2646 136.406C58.3055 136.406 58.342 136.39 58.361 136.365Z'
          fill='white'
        />
        <path
          d='M57.9958 136.403C58.0105 136.404 58.0251 136.406 58.0397 136.406C58.0908 136.406 58.139 136.395 58.18 136.378C58.1142 136.382 58.0557 136.387 58.0061 136.39C57.9973 136.39 57.9944 136.397 57.9958 136.403Z'
          fill='white'
        />
        <path
          d='M92.6462 136.387C94.5227 136.387 96.0438 135.322 96.0438 134.008C96.0438 132.695 94.5227 131.63 92.6462 131.63C90.7697 131.63 89.2485 132.695 89.2485 134.008C89.2485 135.322 90.7697 136.387 92.6462 136.387Z'
          fill='white'
        />
        <path
          d='M92.2182 134.904C92.9009 134.904 93.4544 134.492 93.4544 133.985C93.4544 133.477 92.9009 133.065 92.2182 133.065C91.5354 133.065 90.9819 133.477 90.9819 133.985C90.9819 134.492 91.5354 134.904 92.2182 134.904Z'
          fill='white'
        />
        <path
          d='M70.1261 135.487C71.7401 135.487 73.0486 134.515 73.0486 133.315C73.0486 132.116 71.7401 131.144 70.1261 131.144C68.5121 131.144 67.2036 132.116 67.2036 133.315C67.2036 134.515 68.5121 135.487 70.1261 135.487Z'
          fill='white'
        />
        <path
          d='M65.1226 134.778C65.1226 134.984 65.1751 135.18 65.2701 135.361C65.5828 135.948 66.3544 136.365 67.2589 136.365C68.4381 136.365 69.3952 135.655 69.3952 134.778C69.3952 134.51 69.3061 134.258 69.1483 134.036C68.7903 133.533 68.0786 133.191 67.2589 133.191C66.0782 133.191 65.1226 133.901 65.1226 134.778Z'
          fill='white'
        />
        <path
          d='M62.3121 135.781C62.3121 135.826 62.318 135.871 62.3297 135.915C62.4144 136.219 62.7754 136.448 63.2108 136.448C63.7076 136.448 64.1109 136.149 64.1109 135.781C64.1109 135.724 64.1007 135.667 64.0832 135.614C63.9838 135.326 63.6316 135.113 63.2123 135.113C62.7154 135.113 62.3121 135.412 62.3121 135.781Z'
          fill='white'
        />
        <path
          d='M62.3121 135.781C62.3121 135.826 62.318 135.871 62.3297 135.915C62.4144 136.219 62.7754 136.448 63.2108 136.448C63.7076 136.448 64.1109 136.149 64.1109 135.781C64.1109 135.724 64.1007 135.667 64.0832 135.614C63.9838 135.326 63.6316 135.113 63.2123 135.113C62.7154 135.113 62.3121 135.412 62.3121 135.781Z'
          fill='white'
        />
        <path
          d='M59.2764 136.072C59.2764 136.15 59.3129 136.221 59.3713 136.278C59.3786 136.286 59.3874 136.293 59.3962 136.3C59.478 136.366 59.5949 136.407 59.725 136.407C59.912 136.407 60.0742 136.321 60.1414 136.2C60.1633 136.16 60.175 136.118 60.175 136.072C60.175 135.888 59.9734 135.738 59.725 135.738C59.5204 135.738 59.3465 135.84 59.2924 135.979C59.2807 136.008 59.2749 136.039 59.2749 136.071L59.2764 136.072Z'
          fill='white'
        />
        <path
          d='M59.2764 136.072C59.2764 136.15 59.3129 136.221 59.3713 136.278C59.3786 136.286 59.3874 136.293 59.3962 136.3C59.478 136.366 59.5949 136.407 59.725 136.407C59.912 136.407 60.0742 136.321 60.1414 136.2C60.1633 136.16 60.175 136.118 60.175 136.072C60.175 135.888 59.9734 135.738 59.725 135.738C59.5204 135.738 59.3465 135.84 59.2924 135.979C59.2807 136.008 59.2749 136.039 59.2749 136.071L59.2764 136.072Z'
          fill='white'
        />
        <path
          d='M57.7578 136.197C57.7578 136.301 57.8601 136.387 57.9945 136.403C58.0091 136.404 58.0237 136.406 58.0384 136.406C58.0895 136.406 58.1377 136.395 58.1787 136.378C58.2444 136.349 58.2926 136.302 58.3102 136.246C58.316 136.23 58.3189 136.214 58.3189 136.197C58.3189 136.081 58.1933 135.988 58.0384 135.988C57.8835 135.988 57.7578 136.081 57.7578 136.197Z'
          fill='white'
        />
        <path
          d='M57.7578 136.197C57.7578 136.301 57.8601 136.387 57.9945 136.403C58.0091 136.404 58.0237 136.406 58.0384 136.406C58.0895 136.406 58.1377 136.395 58.1787 136.378C58.2444 136.349 58.2926 136.302 58.3102 136.246C58.316 136.23 58.3189 136.214 58.3189 136.197C58.3189 136.081 58.1933 135.988 58.0384 135.988C57.8835 135.988 57.7578 136.081 57.7578 136.197Z'
          fill='white'
        />
        <path
          d='M58.8263 136.176C58.8263 136.232 58.8526 136.281 58.8964 136.321C58.952 136.372 59.0396 136.406 59.1361 136.406C59.2457 136.406 59.3436 136.363 59.3976 136.299C59.4049 136.292 59.4108 136.283 59.4166 136.274C59.4356 136.245 59.4459 136.211 59.4459 136.176C59.4459 136.093 59.3845 136.018 59.2954 135.979C59.2486 135.958 59.196 135.947 59.1375 135.947C58.9666 135.947 58.8278 136.049 58.8278 136.176H58.8263Z'
          fill='white'
        />
        <path
          d='M58.8263 136.176C58.8263 136.232 58.8526 136.281 58.8964 136.321C58.952 136.372 59.0396 136.406 59.1361 136.406C59.2457 136.406 59.3436 136.363 59.3976 136.299C59.4049 136.292 59.4108 136.283 59.4166 136.274C59.4356 136.245 59.4459 136.211 59.4459 136.176C59.4459 136.093 59.3845 136.018 59.2954 135.979C59.2486 135.958 59.196 135.947 59.1375 135.947C58.9666 135.947 58.8278 136.049 58.8278 136.176H58.8263Z'
          fill='white'
        />
        <path
          d='M58.1521 136.323C58.1521 136.344 58.1623 136.363 58.1813 136.378C58.2018 136.396 58.231 136.406 58.2646 136.406C58.3055 136.406 58.3421 136.39 58.3611 136.365C58.3713 136.352 58.3771 136.339 58.3771 136.323C58.3771 136.289 58.3508 136.26 58.3128 136.247C58.2982 136.242 58.2822 136.239 58.2646 136.239C58.2032 136.239 58.1521 136.277 58.1521 136.323Z'
          fill='white'
        />
        <path
          d='M58.1521 136.323C58.1521 136.344 58.1623 136.363 58.1813 136.378C58.2018 136.396 58.231 136.406 58.2646 136.406C58.3055 136.406 58.3421 136.39 58.3611 136.365C58.3713 136.352 58.3771 136.339 58.3771 136.323C58.3771 136.289 58.3508 136.26 58.3128 136.247C58.2982 136.242 58.2822 136.239 58.2646 136.239C58.2032 136.239 58.1521 136.277 58.1521 136.323Z'
          fill='white'
        />
        <path
          d='M86.709 133.149C86.709 133.726 87.3388 134.194 88.1147 134.194C88.8906 134.194 89.5204 133.726 89.5204 133.149C89.5204 132.572 88.8906 132.104 88.1147 132.104C87.3388 132.104 86.709 132.572 86.709 133.149Z'
          fill='white'
        />
        <path
          d='M86.709 133.149C86.709 133.726 87.3388 134.194 88.1147 134.194C88.8906 134.194 89.5204 133.726 89.5204 133.149C89.5204 132.572 88.8906 132.104 88.1147 132.104C87.3388 132.104 86.709 132.572 86.709 133.149Z'
          fill='white'
        />
        <path
          d='M84.7409 132.44C85.5173 132.44 86.1466 131.972 86.1466 131.395C86.1466 130.818 85.5173 130.35 84.7409 130.35C83.9646 130.35 83.3352 130.818 83.3352 131.395C83.3352 131.972 83.9646 132.44 84.7409 132.44Z'
          fill='white'
        />
        <path
          d='M84.7409 132.44C85.5173 132.44 86.1466 131.972 86.1466 131.395C86.1466 130.818 85.5173 130.35 84.7409 130.35C83.9646 130.35 83.3352 130.818 83.3352 131.395C83.3352 131.972 83.9646 132.44 84.7409 132.44Z'
          fill='white'
        />
        <path
          d='M84.7413 133.191C84.7413 133.768 85.3711 134.236 86.1471 134.236C86.923 134.236 87.5528 133.768 87.5528 133.191C87.5528 132.614 86.923 132.146 86.1471 132.146C85.3711 132.146 84.7413 132.614 84.7413 133.191Z'
          fill='white'
        />
        <path
          d='M84.7413 133.191C84.7413 133.768 85.3711 134.236 86.1471 134.236C86.923 134.236 87.5528 133.768 87.5528 133.191C87.5528 132.614 86.923 132.146 86.1471 132.146C85.3711 132.146 84.7413 132.614 84.7413 133.191Z'
          fill='white'
        />
        <path
          d='M100.031 136.574C100.652 136.574 101.155 136.2 101.155 135.738C101.155 135.277 100.652 134.902 100.031 134.902C99.4105 134.902 98.9073 135.277 98.9073 135.738C98.9073 136.2 99.4105 136.574 100.031 136.574Z'
          fill='white'
        />
        <path
          d='M100.031 136.574C100.652 136.574 101.155 136.2 101.155 135.738C101.155 135.277 100.652 134.902 100.031 134.902C99.4105 134.902 98.9073 135.277 98.9073 135.738C98.9073 136.2 99.4105 136.574 100.031 136.574Z'
          fill='white'
        />
        <path
          d='M95.873 136.405C97.0213 136.405 97.9523 135.714 97.9523 134.861C97.9523 134.008 97.0213 133.316 95.873 133.316C94.7246 133.316 93.7936 134.008 93.7936 134.861C93.7936 135.714 94.7246 136.405 95.873 136.405Z'
          fill='white'
        />
        <path
          d='M95.8728 135.153C96.0891 135.153 96.2659 135.022 96.2659 134.861C96.2659 134.7 96.0891 134.569 95.8728 134.569C95.6566 134.569 95.4797 134.7 95.4797 134.861C95.4797 135.022 95.6566 135.153 95.8728 135.153Z'
          fill='white'
        />
        <path
          d='M98.2323 136.406C98.6673 136.406 99.0199 136.144 99.0199 135.821C99.0199 135.499 98.6673 135.237 98.2323 135.237C97.7973 135.237 97.4447 135.499 97.4447 135.821C97.4447 136.144 97.7973 136.406 98.2323 136.406Z'
          fill='white'
        />
        <path
          d='M98.2523 136.387C98.9091 136.387 99.4415 136.007 99.4415 135.537C99.4415 135.068 98.9091 134.688 98.2523 134.688C97.5956 134.688 97.0631 135.068 97.0631 135.537C97.0631 136.007 97.5956 136.387 98.2523 136.387Z'
          fill='white'
        />
        <path
          d='M99.9481 136.071C100.553 136.071 101.044 135.707 101.044 135.257C101.044 134.808 100.553 134.443 99.9481 134.443C99.3428 134.443 98.8522 134.808 98.8522 135.257C98.8522 135.707 99.3428 136.071 99.9481 136.071Z'
          fill='white'
        />
        <path
          d='M99.9481 136.071C100.553 136.071 101.044 135.707 101.044 135.257C101.044 134.808 100.553 134.443 99.9481 134.443C99.3428 134.443 98.8522 134.808 98.8522 135.257C98.8522 135.707 99.3428 136.071 99.9481 136.071Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_17554_24819'>
          <rect
            width='203.859'
            height='203.859'
            fill='white'
            transform='translate(0.570435 0.820312)'
          />
        </clipPath>
      </defs>
    </svg>

  );
}
