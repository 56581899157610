import React from 'react';

/**
 * @returns {JSX.Element} MerchantIcon svg
 */
export default function MerchantIcon() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.875 10.1245V20.4578C21.875 21.1712 21.2422 21.7495 20.4615 21.7495H4.91346C4.13283 21.7495 3.5 21.1712 3.5 20.4578V10.1245'
        stroke='#F5F6F7'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.0381 2.24951H3.96187C3.63368 2.24946 3.3494 2.46289 3.27773 2.76314L2 8.15576C2 9.24307 2.9402 10.1245 4.1 10.1245C5.2598 10.1245 6.2 9.24307 6.2 8.15576C6.2 9.24307 7.1402 10.1245 8.3 10.1245C9.4598 10.1245 10.4 9.24307 10.4 8.15576C10.4 9.24307 11.3402 10.1245 12.5 10.1245C13.6598 10.1245 14.6 9.24307 14.6 8.15576C14.6 9.24307 15.5402 10.1245 16.7 10.1245C17.8598 10.1245 18.8 9.24307 18.8 8.15576C18.8 9.24307 19.7402 10.1245 20.9 10.1245C22.0598 10.1245 23 9.24307 23 8.15576L21.7213 2.76314C21.6504 2.46284 21.3662 2.24921 21.0381 2.24951Z'
        stroke='#F5F6F7'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.125 16.5572C15.125 15.2827 13.9497 14.2495 12.5 14.2495C11.0503 14.2495 9.875 15.2827 9.875 16.5572V21.7495H15.125V16.5572Z'
        stroke='#F5F6F7'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
