import React from 'react';

/**
 * @returns {JSX.Element} ChevronRight svg
 */
export default function ChevronRightIcon() {
  return (
    <svg
      width='23'
      height='23'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.31445 5.12903L14.9292 11.7437L8.31445 18.3584'
        stroke='white'
        strokeWidth='2.12367'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
