import React from 'react';
/**
 * @returns {JSX.Element} BillIcon Component
 */
export default function BillIcon() {
  return (
    <svg
      width='23'
      height='23'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.90424 8.14844H13.3685'
        stroke='white'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.90424 11.5635H13.3685'
        stroke='white'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.90424 14.979H13.3685'
        stroke='white'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.1499 1.31812H19.516C20.2705 1.31812 20.8821 1.92973 20.8821 2.68419V6.55472C20.8821 7.0577 20.4743 7.46544 19.9714 7.46544H16.7839V2.68419C16.7839 1.92973 17.3955 1.31812 18.1499 1.31812Z'
        stroke='white'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.8335 1.31812H7.22185C5.71293 1.31812 4.48971 2.54134 4.48971 4.05026V17.711C4.48971 20.4431 3.12364 21.2409 1.75757 21.8092H12.6861C14.9495 21.8092 16.7844 19.9744 16.7844 17.711V7.46544'
        stroke='white'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
